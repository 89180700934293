import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import CONFIG from '../Utils/config';
//----------------
import { loginSlice } from '../store/Reducer/login';
import { CommonpageSlice } from './Reducer/commonpages';
import { DashboardSlice } from './Reducer/dashboard';
import { externalSlice } from './Reducer/external';
import { HomeSlice } from './Reducer/Home';
import { orderSlice } from './Reducer/order';
import { OtherSlice } from './Reducer/other';
import { PatientSlice } from './Reducer/patient';
import { ProductSlice } from './Reducer/product';
import { profileSlice } from './Reducer/profile';
import { QuoteSlice } from './Reducer/Quote';
import { ReportSlice } from './Reducer/report';
import { RxoneReportSlice } from './Reducer/rxonereport';
import { SupportTicketSlice } from './Reducer/supportticket';
import { systemSlice } from './Reducer/system';
import { ClinicSlice } from './Reducer/clinic';
import { PublicSlice } from './Reducer/public';
import { nccTSISlice } from '../page/external/nccTSI/nccTSIslice';
//------------------
const main = combineReducers({
    login: loginSlice.reducer,
    system: systemSlice.reducer,
    order: orderSlice.reducer,
    Quote: QuoteSlice.reducer,
    Patient: PatientSlice.reducer,
    Product: ProductSlice.reducer,
    Report: ReportSlice.reducer,
    RxoneReport: RxoneReportSlice.reducer,
    SupportTicket: SupportTicketSlice.reducer,
    profile: profileSlice.reducer,
    Other: OtherSlice.reducer,
    Home: HomeSlice.reducer,
    Dashboard: DashboardSlice.reducer,
    external: externalSlice.reducer,
    Commonpage: CommonpageSlice.reducer,
    Clinic: ClinicSlice.reducer,
    Public: PublicSlice.reducer,
    nccTSI: nccTSISlice.reducer,
})
export default configureStore({
    reducer: {
        ncc: main
    },
    devTools: CONFIG.URL == "http://192.168.29.101:1109"
})