import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../axios';
import CONFIG from '../../Utils/config';
import Service from '../../Utils/service';
//---
const extractErrorMessage = (error) =>
    error?.response?.data?.Message ||
    error?.response?.data ||
    error?.response ||
    error?.message;
const extractErrorMessageRejected = (payload) =>
    payload?.response?.data?.Message?.toString() ||
    payload?.response?.data?.toString() ||
    payload?.response?.toString() ||
    payload?.err?.toString() ||
    payload?.data?.Message?.toString() ||
    payload?.data?.message?.toString() ||
    payload?.data?.toString() ||
    payload?.result?.toString() ||
    payload?.Message?.toString() ||
    payload?.message?.toString() ||
    payload?.error?.toString() ||
    "API Response Invalid.";
//---
export const GetHome = createAsyncThunk(
    'Home/GetHome',
    async (_userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Home`, headers: authHeader });
            // console.log('GetHome result.data >>', result);
            if (result.data.success) {
                return result.data.result;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const HomeAllNotifications = createAsyncThunk(
    'Home/AllNotifications',
    async (_userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'GET', url: `${CONFIG.API_ENDPOINT}Home/AllNotifications`, headers: authHeader });
            // console.log('HomeAllNotifications result.data >>', result);
            if (result.data.success) {
                return result.data.result;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetExternalDashboardCount = createAsyncThunk(
    'Home/GetExternalDashboardCount',
    async (params, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Home/GetExternalDashboardCount`, params: params, data: params, headers: authHeader });
            // console.log('GetExternalDashboardCount result.data >>', result);
            if (result.data.success) {
                return result.data.result;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetExternalNotifications = createAsyncThunk(
    'Home/GetExternalNotifications',
    async (params, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'GET', url: `${CONFIG.API_ENDPOINT}Home/GetExternalNotifications`, params: params, headers: authHeader });
            // console.log('GetExternalNotifications result.data >>', result);
            if (result.data.success) {
                return result.data.result;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetOrderingShop = createAsyncThunk(
    'Home/GetOrderingShop',
    async (_userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'GET', url: `${CONFIG.API_ENDPOINT}OrderingShop`, headers: authHeader });
            // console.log('GetOrderingShop result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetFlavour = createAsyncThunk(
    'Home/GetFlavour',
    async (_userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'GET', url: `${CONFIG.API_ENDPOINT}Flavour`, headers: authHeader });
            // console.log('GetFlavour result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const DashboardSlice = createSlice({
    name: 'Dashboard',
    initialState: {
        Homedata: {},
        OrderingShopdata: [],
        Flavourdata: [],
        homeAllnotificationlist: [],
        ExternalDashboardCountsData: {},
        ExternalNotificationlist: [],
        isFetchingOBJ: {},
        isFetching: false,
        isError: false,
        errorMessage: '',
    },
    reducers: {
        updateState: (state, { payload }) => {
            if (payload.isError !== undefined) { state.isError = payload.isError }
            if (payload.errorMessage !== undefined) { state.errorMessage = payload.errorMessage }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(GetHome.fulfilled, (state, { payload }) => {
            try {
                state.Homedata = payload;
                state.isFetchingOBJ.GetHome = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetHome.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetHome.rejected, (state, { payload }) => {
            try {
                state.Homedata = {};
                state.isFetchingOBJ.GetHome = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetHome.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetHome.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetHome = true;
        })
        //----
        builder.addCase(HomeAllNotifications.fulfilled, (state, { payload }) => {
            try {
                state.homeAllnotificationlist = payload;
                state.isFetchingOBJ.HomeAllNotifications = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[HomeAllNotifications.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(HomeAllNotifications.rejected, (state, { payload }) => {
            try {
                state.isFetchingOBJ.HomeAllNotifications = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[HomeAllNotifications.rejected] try catch error >>', error);
            }
        })
        builder.addCase(HomeAllNotifications.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.HomeAllNotifications = true;
        })
        //----
        builder.addCase(GetExternalDashboardCount.fulfilled, (state, { payload }) => {
            try {
                state.ExternalDashboardCountsData = payload;
                state.isFetchingOBJ.GetExternalDashboardCount = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetExternalDashboardCount.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetExternalDashboardCount.rejected, (state, { payload }) => {
            try {
                state.isFetchingOBJ.GetExternalDashboardCount = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetExternalDashboardCount.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetExternalDashboardCount.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetExternalDashboardCount = true;
        })
        //----
        builder.addCase(GetExternalNotifications.fulfilled, (state, { payload }) => {
            try {
                state.ExternalNotificationlist = payload;
                state.isFetchingOBJ.GetExternalNotifications = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetExternalNotifications.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetExternalNotifications.rejected, (state, { payload }) => {
            try {
                state.isFetchingOBJ.GetExternalNotifications = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetExternalNotifications.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetExternalNotifications.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetExternalNotifications = true;
        })
        //----
        builder.addCase(GetOrderingShop.fulfilled, (state, { payload }) => {
            try {
                state.OrderingShopdata = payload.data;
                state.isFetchingOBJ.GetOrderingShop = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetOrderingShop.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetOrderingShop.rejected, (state, { payload }) => {
            try {
                state.OrderingShopdata = [];
                state.isFetchingOBJ.GetOrderingShop = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetOrderingShop.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetOrderingShop.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetOrderingShop = true;
        })
        //----
        builder.addCase(GetFlavour.fulfilled, (state, { payload }) => {
            try {
                state.Flavourdata = payload.data;
                state.isFetchingOBJ.GetFlavour = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetFlavour.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetFlavour.rejected, (state, { payload }) => {
            try {
                state.Flavourdata = [];
                state.isFetchingOBJ.GetFlavour = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetFlavour.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetFlavour.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetFlavour = true;
        })
    }
});
export const { updateState } = DashboardSlice.actions;
export const DashboardSelector = (state) => state.ncc.Dashboard;