import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../axios';
import CONFIG from '../../Utils/config';
import Service from '../../Utils/service';
//---
const extractErrorMessage = (error) =>
    error?.response?.data?.Message ||
    error?.response?.data ||
    error?.response ||
    error?.message;
const extractErrorMessageRejected = (payload) =>
    payload?.response?.data?.Message?.toString() ||
    payload?.response?.data?.toString() ||
    payload?.response?.toString() ||
    payload?.err?.toString() ||
    payload?.data?.Message?.toString() ||
    payload?.data?.message?.toString() ||
    payload?.data?.toString() ||
    payload?.result?.toString() ||
    payload?.Message?.toString() ||
    payload?.message?.toString() ||
    payload?.error?.toString() ||
    "API Response Invalid.";
//---
export const GetEmailTemplate = createAsyncThunk(
    'EmailTemplate/GetEmailTemplate',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}EmailTemplate`, data: userdata, headers: authHeader });
            // console.log('GetEmailTemplate result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const externalUpdateEmailtemp = createAsyncThunk(
    'EmailTemplate/externalUpdateEmailtemp',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}EmailTemplate`, data: postdata, headers: authHeader });
            // console.log('externalUpdateEmailtemp result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const Ordernotes = createAsyncThunk(
    'Order/Ordernotes',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Order/SNoteOrderID`, params: postdata, data: postdata, headers: authHeader });
            // console.log('Ordernotes result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const updateOrdernotes = createAsyncThunk(
    'Order/updateOrdernotes',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}Order/UpdateNoteOrderID`, data: postdata, headers: authHeader });
            // console.log('updateOrdernotes result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const QR8Prescription = createAsyncThunk(
    'QR8Prescription/QR8Prescription',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}QR8Prescription`, data: postdata, headers: authHeader });
            // console.log('QR8Prescription result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const UploadQR8Prescription = createAsyncThunk(
    'QR8Prescription/UploadQR8Prescription',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}QR8Prescription/UploadQR8Prescription`, params: postdata, data: postdata, headers: authHeader });
            // console.log('UploadQR8Prescription result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
//======================
export const externalSlice = createSlice({
    name: 'external',
    initialState: {
        EmailTemplatedata: [],
        QR8Prescriptiondata: [],
        Externalordernotesdata: null,
        isUploadQR8Prescription: false,
        isExternalemailupdate: false,
        isOrdernoteupdate: false,
        isFetchingOBJ: {},
        isFetching: false,
        isError: false,
        errorMessage: '',
    },
    reducers: {
        updateState: (state, { payload }) => {
            state.isUploadQR8Prescription = payload.isUploadQR8Prescription !== undefined ? payload.isUploadQR8Prescription : state.isUploadQR8Prescription;
            state.isExternalemailupdate = payload.isExternalemailupdate !== undefined ? payload.isExternalemailupdate : state.isExternalemailupdate;
            state.isOrdernoteupdate = payload.isOrdernoteupdate !== undefined ? payload.isOrdernoteupdate : state.isOrdernoteupdate;
            state.isError = payload.isError !== undefined ? payload.isError : state.isError;
            state.errorMessage = payload.errorMessage !== undefined ? payload.errorMessage : state.errorMessage;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(GetEmailTemplate.fulfilled, (state, { payload }) => {
            try {
                state.EmailTemplatedata = payload.data;
                state.isFetching = false;
                state.isFetchingOBJ.GetEmailTemplate = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetEmailTemplate.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetEmailTemplate.rejected, (state, { payload }) => {
            try {
                state.EmailTemplatedata = [];
                state.isFetching = false;
                state.isFetchingOBJ.GetEmailTemplate = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetEmailTemplate.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetEmailTemplate.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetEmailTemplate = true;
        })
        //----
        builder.addCase(externalUpdateEmailtemp.fulfilled, (state, { payload }) => {
            try {
                let exist = [...state.EmailTemplatedata];
                let updated = payload.result;
                const index = state.EmailTemplatedata.findIndex(opt => opt.Id === updated.Id)
                exist[index] = updated;
                state.EmailTemplatedata = exist;
                state.isExternalemailupdate = true;
                state.isFetching = false;
                state.isFetchingOBJ.externalUpdateEmailtemp = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[externalUpdateEmailtemp.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(externalUpdateEmailtemp.rejected, (state, { payload }) => {
            try {
                state.isExternalemailupdate = false;
                state.isFetching = false;
                state.isFetchingOBJ.externalUpdateEmailtemp = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[externalUpdateEmailtemp.rejected] try catch error >>', error);
            }
        })
        builder.addCase(externalUpdateEmailtemp.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.externalUpdateEmailtemp = true;
        })
        //----
        builder.addCase(Ordernotes.fulfilled, (state, { payload }) => {
            try {
                state.Externalordernotesdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.Ordernotes = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[Ordernotes.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(Ordernotes.rejected, (state, { payload }) => {
            try {
                state.Externalordernotesdata = null;
                state.isFetching = false;
                state.isFetchingOBJ.Ordernotes = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[Ordernotes.rejected] try catch error >>', error);
            }
        })
        builder.addCase(Ordernotes.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.Ordernotes = true;
        })
        //----
        builder.addCase(updateOrdernotes.fulfilled, (state, { payload }) => {
            try {
                state.Externalordernotesdata = payload.result;
                state.isOrdernoteupdate = true;
                state.isFetching = false;
                state.isFetchingOBJ.updateOrdernotes = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[updateOrdernotes.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(updateOrdernotes.rejected, (state, { payload }) => {
            try {
                state.Externalordernotesdata = null;
                state.isOrdernoteupdate = false;
                state.isFetching = false;
                state.isFetchingOBJ.updateOrdernotes = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[updateOrdernotes.rejected] try catch error >>', error);
            }
        })
        builder.addCase(updateOrdernotes.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.updateOrdernotes = true;
        })
        //----
        builder.addCase(QR8Prescription.fulfilled, (state, { payload }) => {
            try {
                state.QR8Prescriptiondata = payload;
                state.isFetching = false;
                state.isFetchingOBJ.QR8Prescription = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[QR8Prescription.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(QR8Prescription.rejected, (state, { payload }) => {
            try {
                state.QR8Prescriptiondata = null;
                state.isFetching = false;
                state.isFetchingOBJ.QR8Prescription = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[QR8Prescription.rejected] try catch error >>', error);
            }
        })
        builder.addCase(QR8Prescription.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.QR8Prescription = true;
        })
        //----
        builder.addCase(UploadQR8Prescription.fulfilled, (state,) => {
            try {
                state.isUploadQR8Prescription = true;
                state.isFetching = false;
                state.isFetchingOBJ.UploadQR8Prescription = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[UploadQR8Prescription.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(UploadQR8Prescription.rejected, (state, { payload }) => {
            try {
                state.isUploadQR8Prescription = false;
                state.isFetching = false;
                state.isFetchingOBJ.UploadQR8Prescription = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[UploadQR8Prescription.rejected] try catch error >>', error);
            }
        })
        builder.addCase(UploadQR8Prescription.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.UploadQR8Prescription = true;
        })
    }
});
//======================
export const { updateState } = externalSlice.actions;
export const externalSelector = (state) => state.ncc.external;
