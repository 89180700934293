import axios from 'axios';
axios.interceptors.request.use(function (config) {
    if(!config?.data){
        delete config.headers['Content-Length'];
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});
axios.interceptors.response.use(function (response) {
    if (response?.data?.result == 'TOKEN_EXPIRED') {
        localStorage.removeItem('ncc_Token');
        window.location.href = "/tokenexpireverify?expirepath=" + window.location.pathname;
    }
    return response;
}, function (error) {
    return Promise.reject(error);
});
export default axios;