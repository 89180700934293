import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../axios';
import CONFIG from '../../Utils/config';
import Service from '../../Utils/service';
//---
const extractErrorMessage = (error) =>
    error?.response?.data?.Message ||
    error?.response?.data ||
    error?.response ||
    error?.message;
const extractErrorMessageRejected = (payload) =>
    payload?.response?.data?.Message?.toString() ||
    payload?.response?.data?.toString() ||
    payload?.response?.toString() ||
    payload?.err?.toString() ||
    payload?.data?.Message?.toString() ||
    payload?.data?.message?.toString() ||
    payload?.data?.toString() ||
    payload?.result?.toString() ||
    payload?.Message?.toString() ||
    payload?.message?.toString() ||
    payload?.error?.toString() ||
    "API Response Invalid.";
//---
export const NewPatientCountByMonth = createAsyncThunk(
    'RxOne/NewPatientCountByMonth',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}RxOne/NewPatientCountByMonth`, params: userdata, data: userdata, headers: authHeader });
            // console.log('NewPatientCountByMonth result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const ExportNewPatientCountByMonth = createAsyncThunk(
    'RxOne/ExportNewPatientCountByMonth',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}RxOne/ExportNewPatientCountByMonth`, params: userdata, data: userdata, headers: authHeader });
            // console.log('ExportNewPatientCountByMonth result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const Top100Patientsbysales = createAsyncThunk(
    'RxOne/Top100Patientsbysales',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}RxOne/Top100Patientsbysales`, params: userdata, data: userdata, headers: authHeader });
            // console.log('Top100Patientsbysales result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const ExportTop100Patientsbysales = createAsyncThunk(
    'RxOne/ExportTop100Patientsbysales',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}RxOne/ExportTop100Patientsbysales`, params: userdata, data: userdata, headers: authHeader });
            // console.log('ExportTop100Patientsbysales result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const Pharmacywisetotalsales = createAsyncThunk(
    'RxOne/Pharmacywisetotalsales',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}RxOne/Pharmacywisetotalsales`, params: userdata, data: userdata, headers: authHeader });
            // console.log('Pharmacywisetotalsales result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const ExportPharmacywisetotalsales = createAsyncThunk(
    'RxOne/ExportPharmacywisetotalsales',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}RxOne/ExportPharmacywisetotalsales`, params: userdata, data: userdata, headers: authHeader });
            // console.log('ExportPharmacywisetotalsales result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const PatientSpecificSales = createAsyncThunk(
    'RxOne/PatientSpecificSales',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}RxOne/PatientSpecificSales`, params: userdata, data: userdata, headers: authHeader });
            // console.log('PatientSpecificSales result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const getCurrentSOH = createAsyncThunk(
    'RxOne/getCurrentSOH',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}RxOne/CurrentSOH`, params: userdata, data: userdata, headers: authHeader });
            // console.log('getCurrentSOH result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const MonthlyEachStockID = createAsyncThunk(
    'RxOne/MonthlyEachStockID',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}RxOne/MonthlyEachStockID`, params: userdata, data: userdata, headers: authHeader });
            // console.log('MonthlyEachStockID result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const SOHVSStockUsage = createAsyncThunk(
    'RxOne/SOHVSStockUsage',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}RxOne/SOHVSStockUsage`, params: userdata, data: userdata, headers: authHeader });
            // console.log('SOHVSStockUsage result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const TopDoctorsReport = createAsyncThunk(
    'RxOne/TopDoctorsReport',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}RxOne/TopDoctorsReport`, params: userdata, data: userdata, headers: authHeader });
            // console.log('TopDoctorsReport result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const ExportTopDoctorsReport = createAsyncThunk(
    'RxOne/ExportTopDoctorsReport',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}RxOne/ExportTopDoctorsReport`, params: userdata, data: userdata, headers: authHeader });
            // console.log('ExportTopDoctorsReport result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const DebtorList = createAsyncThunk(
    'RxOne/DebtorList',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}RxOne/DebtorList`, data: userdata, headers: authHeader });
            // console.log('DebtorList result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const DebtorListnew = createAsyncThunk(
    'RxOne/NewDebtorList',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}RxOne/NewDebtorList`, data: userdata, headers: authHeader });
            // console.log('DebtorListnew result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const DebtorViewStatement = createAsyncThunk(
    'RxOne/ViewStatement',
    async (params, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}RxOne/ViewStatement`, params: params, data: params, headers: authHeader });
            // console.log('DebtorViewStatement result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const ExportDebtorList = createAsyncThunk(
    'RxOne/ExportDebtorList',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}RxOne/ExportDebtorList`, params: userdata, data: userdata, headers: authHeader });
            // console.log('ExportDebtorList result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const AgedSalesToday = createAsyncThunk(
    'RxOne/AgedSalesToday',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}RxOne/AgedSalesToday`, params: userdata, data: userdata, headers: authHeader });
            // console.log('AgedSalesToday result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const CompoundingRXHistory = createAsyncThunk(
    'RxOne/CompoundingRXHistory',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}RxOne/CompoundingRXHistory`, params: userdata, data: userdata, headers: authHeader });
            // console.log('CompoundingRXHistory result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetScriptPhotos = createAsyncThunk(
    'RxOne/GetScriptPhotos',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}RxOne/GetScriptPhotos`, params: userdata, data: userdata, headers: authHeader });
            // console.log('GetScriptPhotos result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const UploadScriptPhoto = createAsyncThunk(
    'RxOne/UploadScriptPhoto',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader('multipart/form-data');
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}RxOne/UploadScriptPhoto`, data: userdata, headers: authHeader });
            // console.log('UploadScriptPhoto result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const UpdateRxOneScriptStatus = createAsyncThunk(
    'RxOne/UpdateRxOneScriptStatus',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}RxOne/UpdateRxOneScriptStatus`, data: userdata, headers: authHeader });
            // console.log('UpdateRxOneScriptStatus result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const SendEmail = createAsyncThunk(
    'RxOne/SendEmail',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}RxOne/SendEmail`, params: userdata, data: userdata, headers: authHeader });
            // console.log('SendEmail result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const Reminder = createAsyncThunk(
    'RxOne/Reminder',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}RxOne/Reminder`, params: userdata, data: userdata, headers: authHeader });
            // console.log('Reminder result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const DailySales = createAsyncThunk(
    'RxOne/DailySales',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}RxOne/DailySales`, params: userdata, data: userdata, headers: authHeader });
            // console.log('DailySales result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const DailySalesDetail = createAsyncThunk(
    'RxOne/DailySalesDetail',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}RxOne/DailySalesDetail`, params: userdata, data: userdata, headers: authHeader });
            // console.log('DailySalesDetail result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const RXONEPatient = createAsyncThunk(
    'RxOne/RXONEPatient',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}RxOne/RXONEPatient`, params: userdata, data: userdata, headers: authHeader });
            // console.log('RXONEPatient result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const RxoneReportSlice = createSlice({
    name: 'RxoneReport',
    initialState: {
        NewPatientCountByMonthdata: {},
        Top100Patientsbysalesdata: [],
        Pharmacywisetotalsalesdata: [],
        PatientSpecificSalesdata: [],
        CurrentSOHdata: [],
        MonthlyEachStockIDdata: [],
        SOHVSStockUsagedata: [],
        TopDoctorsReportdata: [],
        DebtorListdata: [],
        DebtorListnewdata: [],
        DebtorViewStatementData: {},
        AgedSalesTodaydata: [],
        CompoundingRXHistorydata: [],
        UploadScriptPhotodata: {},
        ExportNewPatientCountByMonthdata: null,
        ExportTop100Patientsbysalesdata: null,
        ExportPharmacywisetotalsalesdata: null,
        ExportTopDoctorsReportdata: null,
        ExportDebtorListdata: null,
        DailySalesdata: [],
        DailySalesDetaildata: [],
        RXONEPatientdata: [],
        isSendEmail: false,
        isSendReminder: false,
        isUploadScriptPhoto: false,
        isRxorderstatuUpdate: false,
        isFetchingOBJ: {},
        isFetching: false,
        isError: false,
        errorMessage: '',
    },
    reducers: {
        updateState: (state, { payload }) => {
            state.ExportNewPatientCountByMonthdata = payload.ExportNewPatientCountByMonthdata !== undefined ? payload.ExportNewPatientCountByMonthdata : state.ExportNewPatientCountByMonthdata;
            state.ExportTop100Patientsbysalesdata = payload.ExportTop100Patientsbysalesdata !== undefined ? payload.ExportTop100Patientsbysalesdata : state.ExportTop100Patientsbysalesdata;
            state.ExportPharmacywisetotalsalesdata = payload.ExportPharmacywisetotalsalesdata !== undefined ? payload.ExportPharmacywisetotalsalesdata : state.ExportPharmacywisetotalsalesdata;
            state.ExportTopDoctorsReportdata = payload.ExportTopDoctorsReportdata !== undefined ? payload.ExportTopDoctorsReportdata : state.ExportTopDoctorsReportdata;
            state.ExportDebtorListdata = payload.ExportDebtorListdata !== undefined ? payload.ExportDebtorListdata : state.ExportDebtorListdata;
            state.isUploadScriptPhoto = payload.isUploadScriptPhoto !== undefined ? payload.isUploadScriptPhoto : state.isUploadScriptPhoto;
            state.isRxorderstatuUpdate = payload.isRxorderstatuUpdate !== undefined ? payload.isRxorderstatuUpdate : state.isRxorderstatuUpdate;
            state.isSendEmail = payload.isSendEmail !== undefined ? payload.isSendEmail : state.isSendEmail;
            state.isSendReminder = payload.isSendReminder !== undefined ? payload.isSendReminder : state.isSendReminder;
            state.DailySalesDetaildata = payload.DailySalesDetaildata !== undefined ? payload.DailySalesDetaildata : state.DailySalesDetaildata;
            state.isFetching = payload.isFetching !== undefined ? payload.isFetching : state.isFetching;
            state.isError = payload.isError !== undefined ? payload.isError : state.isError;
            state.errorMessage = payload.errorMessage !== undefined ? payload.errorMessage : state.errorMessage;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(NewPatientCountByMonth.fulfilled, (state, { payload }) => {
            try {
                state.NewPatientCountByMonthdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.NewPatientCountByMonth = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[NewPatientCountByMonth.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(NewPatientCountByMonth.rejected, (state, { payload }) => {
            try {
                state.NewPatientCountByMonthdata = {};
                state.isFetching = false;
                state.isFetchingOBJ.NewPatientCountByMonth = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[NewPatientCountByMonth.rejected] try catch error >>', error);
            }
        })
        builder.addCase(NewPatientCountByMonth.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.NewPatientCountByMonth = true;
        })
        //----
        builder.addCase(ExportNewPatientCountByMonth.fulfilled, (state, { payload }) => {
            try {
                state.ExportNewPatientCountByMonthdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.ExportNewPatientCountByMonth = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[ExportNewPatientCountByMonth.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(ExportNewPatientCountByMonth.rejected, (state, { payload }) => {
            try {
                state.ExportNewPatientCountByMonthdata = null;
                state.isFetching = false;
                state.isFetchingOBJ.ExportNewPatientCountByMonth = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[ExportNewPatientCountByMonth.rejected] try catch error >>', error);
            }
        })
        builder.addCase(ExportNewPatientCountByMonth.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.ExportNewPatientCountByMonth = true;
        })
        //----
        builder.addCase(Top100Patientsbysales.fulfilled, (state, { payload }) => {
            try {
                state.Top100Patientsbysalesdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.Top100Patientsbysales = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[Top100Patientsbysales.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(Top100Patientsbysales.rejected, (state, { payload }) => {
            try {
                state.Top100Patientsbysalesdata = [];
                state.isFetching = false;
                state.isFetchingOBJ.Top100Patientsbysales = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[Top100Patientsbysales.rejected] try catch error >>', error);
            }
        })
        builder.addCase(Top100Patientsbysales.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.Top100Patientsbysales = true;
        })
        //----
        builder.addCase(ExportTop100Patientsbysales.fulfilled, (state, { payload }) => {
            try {
                state.ExportTop100Patientsbysalesdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.ExportTop100Patientsbysales = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[ExportTop100Patientsbysales.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(ExportTop100Patientsbysales.rejected, (state, { payload }) => {
            try {
                state.ExportTop100Patientsbysalesdata = null;
                state.isFetching = false;
                state.isFetchingOBJ.ExportTop100Patientsbysales = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[ExportTop100Patientsbysales.rejected] try catch error >>', error);
            }
        })
        builder.addCase(ExportTop100Patientsbysales.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.ExportTop100Patientsbysales = true;
        })
        //----
        builder.addCase(Pharmacywisetotalsales.fulfilled, (state, { payload }) => {
            try {
                state.Pharmacywisetotalsalesdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.Pharmacywisetotalsales = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[Pharmacywisetotalsales.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(Pharmacywisetotalsales.rejected, (state, { payload }) => {
            try {
                state.Pharmacywisetotalsalesdata = [];
                state.isFetching = false;
                state.isFetchingOBJ.Pharmacywisetotalsales = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[Pharmacywisetotalsales.rejected] try catch error >>', error);
            }
        })
        builder.addCase(Pharmacywisetotalsales.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.Pharmacywisetotalsales = true;
        })
        //----
        builder.addCase(ExportPharmacywisetotalsales.fulfilled, (state, { payload }) => {
            try {
                state.ExportPharmacywisetotalsalesdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.ExportPharmacywisetotalsales = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[ExportPharmacywisetotalsales.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(ExportPharmacywisetotalsales.rejected, (state, { payload }) => {
            try {
                state.ExportPharmacywisetotalsalesdata = null;
                state.isFetching = false;
                state.isFetchingOBJ.ExportPharmacywisetotalsales = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[ExportPharmacywisetotalsales.rejected] try catch error >>', error);
            }
        })
        builder.addCase(ExportPharmacywisetotalsales.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.ExportPharmacywisetotalsales = true;
        })
        //----
        builder.addCase(PatientSpecificSales.fulfilled, (state, { payload }) => {
            try {
                state.PatientSpecificSalesdata = payload.result;
                state.isPatientsaleFetching = false;
                state.isFetchingOBJ.PatientSpecificSales = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[PatientSpecificSales.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(PatientSpecificSales.rejected, (state, { payload }) => {
            try {
                state.PatientSpecificSalesdata = [];
                state.isPatientsaleFetching = false;
                state.isFetchingOBJ.PatientSpecificSales = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[PatientSpecificSales.rejected] try catch error >>', error);
            }
        })
        builder.addCase(PatientSpecificSales.pending, (state) => {
            state.isPatientsaleFetching = true;
            state.isFetchingOBJ.PatientSpecificSales = true;
        })
        //----
        builder.addCase(getCurrentSOH.fulfilled, (state, { payload }) => {
            try {
                state.CurrentSOHdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.getCurrentSOH = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[getCurrentSOH.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(getCurrentSOH.rejected, (state, { payload }) => {
            try {
                state.CurrentSOHdata = [];
                state.isFetching = false;
                state.isFetchingOBJ.getCurrentSOH = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[getCurrentSOH.rejected] try catch error >>', error);
            }
        })
        builder.addCase(getCurrentSOH.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.getCurrentSOH = true;
        })
        //----
        builder.addCase(MonthlyEachStockID.fulfilled, (state, { payload }) => {
            try {
                state.MonthlyEachStockIDdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.MonthlyEachStockID = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[MonthlyEachStockID.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(MonthlyEachStockID.rejected, (state, { payload }) => {
            try {
                state.MonthlyEachStockIDdata = [];
                state.isFetching = false;
                state.isFetchingOBJ.MonthlyEachStockID = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[MonthlyEachStockID.rejected] try catch error >>', error);
            }
        })
        builder.addCase(MonthlyEachStockID.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.MonthlyEachStockID = true;
        })
        //----
        builder.addCase(SOHVSStockUsage.fulfilled, (state, { payload }) => {
            try {
                state.SOHVSStockUsagedata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.SOHVSStockUsage = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[SOHVSStockUsage.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(SOHVSStockUsage.rejected, (state, { payload }) => {
            try {
                state.SOHVSStockUsagedata = [];
                state.isFetching = false;
                state.isFetchingOBJ.SOHVSStockUsage = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[SOHVSStockUsage.rejected] try catch error >>', error);
            }
        })
        builder.addCase(SOHVSStockUsage.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.SOHVSStockUsage = true;
        })
        //----
        builder.addCase(TopDoctorsReport.fulfilled, (state, { payload }) => {
            try {
                state.TopDoctorsReportdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.TopDoctorsReport = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[TopDoctorsReport.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(TopDoctorsReport.rejected, (state, { payload }) => {
            try {
                state.TopDoctorsReportdata = [];
                state.isFetching = false;
                state.isFetchingOBJ.TopDoctorsReport = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[TopDoctorsReport.rejected] try catch error >>', error);
            }
        })
        builder.addCase(TopDoctorsReport.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.TopDoctorsReport = true;
        })
        //----
        builder.addCase(ExportTopDoctorsReport.fulfilled, (state, { payload }) => {
            try {
                state.ExportTopDoctorsReportdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.ExportTopDoctorsReport = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[ExportTopDoctorsReport.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(ExportTopDoctorsReport.rejected, (state, { payload }) => {
            try {
                state.ExportTopDoctorsReportdata = null;
                state.isFetching = false;
                state.isFetchingOBJ.ExportTopDoctorsReport = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[ExportTopDoctorsReport.rejected] try catch error >>', error);
            }
        })
        builder.addCase(ExportTopDoctorsReport.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.ExportTopDoctorsReport = true;
        })
        //----
        builder.addCase(DebtorList.fulfilled, (state, { payload }) => {
            try {
                state.DebtorListdata = payload;
                state.isFetching = false;
                state.isFetchingOBJ.DebtorList = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[DebtorList.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(DebtorList.rejected, (state, { payload }) => {
            try {
                state.DebtorListdata = null;
                state.isFetching = false;
                state.isFetchingOBJ.DebtorList = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[DebtorList.rejected] try catch error >>', error);
            }
        })
        builder.addCase(DebtorList.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.DebtorList = true;
        })
        //----
        builder.addCase(DebtorListnew.fulfilled, (state, { payload }) => {
            try {
                state.DebtorListnewdata = payload;
                state.isFetching = false;
                state.isFetchingOBJ.DebtorListnew = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[DebtorListnew.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(DebtorListnew.rejected, (state, { payload }) => {
            try {
                state.DebtorListnewdata = null;
                state.isFetching = false;
                state.isFetchingOBJ.DebtorListnew = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[DebtorListnew.rejected] try catch error >>', error);
            }
        })
        builder.addCase(DebtorListnew.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.DebtorListnew = true;
        })
        //----
        builder.addCase(DebtorViewStatement.fulfilled, (state, { payload }) => {
            try {
                state.DebtorViewStatementData = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.DebtorViewStatement = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[DebtorViewStatement.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(DebtorViewStatement.rejected, (state, { payload }) => {
            try {
                state.DebtorViewStatementData = {};
                state.isFetching = false;
                state.isFetchingOBJ.DebtorViewStatement = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[DebtorViewStatement.rejected] try catch error >>', error);
            }
        })
        builder.addCase(DebtorViewStatement.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.DebtorViewStatement = true;
        })
        //----
        builder.addCase(ExportDebtorList.fulfilled, (state, { payload }) => {
            try {
                state.ExportDebtorListdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.ExportDebtorList = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[ExportDebtorList.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(ExportDebtorList.rejected, (state, { payload }) => {
            try {
                state.ExportDebtorListdata = null;
                state.isFetching = false;
                state.isFetchingOBJ.ExportDebtorList = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[ExportDebtorList.rejected] try catch error >>', error);
            }
        })
        builder.addCase(ExportDebtorList.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.ExportDebtorList = true;
        })
        //----
        builder.addCase(AgedSalesToday.fulfilled, (state, { payload }) => {
            try {
                state.AgedSalesTodaydata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.AgedSalesToday = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[AgedSalesToday.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(AgedSalesToday.rejected, (state, { payload }) => {
            try {
                state.AgedSalesTodaydata = [];
                state.isFetching = false;
                state.isFetchingOBJ.AgedSalesToday = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[AgedSalesToday.rejected] try catch error >>', error);
            }
        })
        builder.addCase(AgedSalesToday.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.AgedSalesToday = true;
        })
        //----
        builder.addCase(CompoundingRXHistory.fulfilled, (state, { payload }) => {
            try {
                state.CompoundingRXHistorydata = payload.result;
                state.isFetchingOBJ.CompoundingRXHistory = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[CompoundingRXHistory.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(CompoundingRXHistory.rejected, (state, { payload }) => {
            try {
                state.CompoundingRXHistorydata = null;
                state.isFetchingOBJ.CompoundingRXHistory = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[CompoundingRXHistory.rejected] try catch error >>', error);
            }
        })
        builder.addCase(CompoundingRXHistory.pending, (state) => {
            state.isFetchingOBJ.CompoundingRXHistory = true;
        })
        //----
        builder.addCase(GetScriptPhotos.fulfilled, (state, { payload }) => {
            try {
                state.UploadScriptPhotodata = payload;
                state.isFetching = false;
                state.isFetchingOBJ.GetScriptPhotos = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetScriptPhotos.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetScriptPhotos.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.GetScriptPhotos = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetScriptPhotos.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetScriptPhotos.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetScriptPhotos = true;
        })
        //----
        builder.addCase(UploadScriptPhoto.fulfilled, (state, { payload }) => {
            try {
                let updated = payload.result;
                let existData = [...state.UploadScriptPhotodata.result];
                let newData = [...existData, updated];
                state.UploadScriptPhotodata.result = newData;
                state.isUploadScriptPhoto = true;
                state.isFetching = false;
                state.isFetchingOBJ.UploadScriptPhoto = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[UploadScriptPhoto.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(UploadScriptPhoto.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.UploadScriptPhoto = false;
                state.isError = true;
                state.isUploadScriptPhoto = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[UploadScriptPhoto.rejected] try catch error >>', error);
            }
        })
        builder.addCase(UploadScriptPhoto.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.UploadScriptPhoto = true;
        })
        //----
        builder.addCase(UpdateRxOneScriptStatus.fulfilled, (state) => {
            try {
                state.isRxorderstatuUpdate = true;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateRxOneScriptStatus = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[UpdateRxOneScriptStatus.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(UpdateRxOneScriptStatus.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.UpdateRxOneScriptStatus = false;
                state.isError = true;
                state.isRxorderstatuUpdate = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[UpdateRxOneScriptStatus.rejected] try catch error >>', error);
            }
        })
        builder.addCase(UpdateRxOneScriptStatus.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.UpdateRxOneScriptStatus = true;
        })
        //----
        builder.addCase(SendEmail.fulfilled, (state) => {
            try {
                state.isSendEmail = true;
                state.isFetching = false;
                state.isFetchingOBJ.SendEmail = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[SendEmail.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(SendEmail.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.SendEmail = false;
                state.isError = true;
                state.isSendEmail = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[SendEmail.rejected] try catch error >>', error);
            }
        })
        builder.addCase(SendEmail.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.SendEmail = true;
        })
        //----
        builder.addCase(Reminder.fulfilled, (state) => {
            try {
                state.isSendReminder = true;
                state.isFetching = false;
                state.isFetchingOBJ.Reminder = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[Reminder.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(Reminder.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.Reminder = false;
                state.isError = true;
                state.isSendReminder = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[Reminder.rejected] try catch error >>', error);
            }
        })
        builder.addCase(Reminder.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.Reminder = true;
        })
        //----
        builder.addCase(DailySales.fulfilled, (state, { payload }) => {
            try {
                state.DailySalesdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.DailySales = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[DailySales.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(DailySales.rejected, (state, { payload }) => {
            try {
                state.DailySalesdata = [];
                state.isFetching = false;
                state.isFetchingOBJ.DailySales = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[DailySales.rejected] try catch error >>', error);
            }
        })
        builder.addCase(DailySales.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.DailySales = true;
        })
        //----
        builder.addCase(DailySalesDetail.fulfilled, (state, { payload }) => {
            try {
                state.DailySalesDetaildata = payload.result;
                state.isFetchingOBJ.DailySalesDetail = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[DailySalesDetail.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(DailySalesDetail.rejected, (state, { payload }) => {
            try {
                state.DailySalesDetaildata = [];
                state.isFetchingOBJ.DailySalesDetail = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[DailySalesDetail.rejected] try catch error >>', error);
            }
        })
        builder.addCase(DailySalesDetail.pending, (state) => {
            state.isFetchingOBJ.DailySalesDetail = true;
        })
        //----
        builder.addCase(RXONEPatient.fulfilled, (state, { payload }) => {
            try {
                state.RXONEPatientdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.RXONEPatient = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[RXONEPatient.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(RXONEPatient.rejected, (state, { payload }) => {
            try {
                state.RXONEPatientdata = [];
                state.isFetching = false;
                state.isFetchingOBJ.RXONEPatient = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[RXONEPatient.rejected] try catch error >>', error);
            }
        })
        builder.addCase(RXONEPatient.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.RXONEPatient = true;
        })
    }
});
export const { updateState } = RxoneReportSlice.actions;
export const RxoneReportSelector = (state) => state.ncc.RxoneReport;