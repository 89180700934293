import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../axios';
import CONFIG from '../../Utils/config';
//---
const extractErrorMessage = (error) =>
    error?.response?.data?.Message ||
    error?.response?.data ||
    error?.response ||
    error?.message;
const extractErrorMessageRejected = (payload) =>
    payload?.response?.data?.Message?.toString() ||
    payload?.response?.data?.toString() ||
    payload?.response?.toString() ||
    payload?.err?.toString() ||
    payload?.data?.Message?.toString() ||
    payload?.data?.message?.toString() ||
    payload?.data?.toString() ||
    payload?.result?.toString() ||
    payload?.Message?.toString() ||
    payload?.message?.toString() ||
    payload?.error?.toString() ||
    "API Response Invalid.";
//---
export const GetpublicSOrderID = createAsyncThunk(
    'Public/GetpublicSOrderID',
    async (userdata, thunkAPI) => {
        try {
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}public/SOrderID`, params: userdata, data: userdata });
            // console.log('GetpublicSOrderID result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetpublicTransferToPayment = createAsyncThunk(
    'Public/GetpublicTransferToPayment',
    async (userdata, thunkAPI) => {
        try {
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}public/TransferToPayment`, data: userdata });
            // console.log('GetpublicTransferToPayment result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const PaymentBroadcast = createAsyncThunk(
    'MessageBroadcast/PaymentBroadcast',
    async (userdata, thunkAPI) => {
        try {
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}MessageBroadcast/PaymentBroadcast`, params: userdata, data: userdata });
            // console.log('PaymentBroadcast result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const PublicSlice = createSlice({
    name: 'Public',
    initialState: {
        PublicOrderdata: {},
        isPaymentBroadcast: false,
        ispublicPayment: false,
        isFetchingOBJ: {},
        isFetching: false,
        isError: false,
        errorMessage: '',
    },
    reducers: {
        updateState: (state, { payload }) => {
            state.isPaymentBroadcast = payload.isPaymentBroadcast !== undefined ? payload.isPaymentBroadcast : state.isPaymentBroadcast;
            state.ispublicPayment = payload.ispublicPayment !== undefined ? payload.ispublicPayment : state.ispublicPayment;
            state.isError = payload.isError !== undefined ? payload.isError : state.isError;
            state.errorMessage = payload.errorMessage !== undefined ? payload.errorMessage : state.errorMessage;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(GetpublicSOrderID.fulfilled, (state, { payload }) => {
            try {
                state.PublicOrderdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.GetpublicSOrderID = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetpublicSOrderID.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetpublicSOrderID.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.GetpublicSOrderID = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetpublicSOrderID.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetpublicSOrderID.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetpublicSOrderID = true;
        })
        //----
        builder.addCase(GetpublicTransferToPayment.fulfilled, (state) => {
            try {
                state.ispublicPayment = true;
                state.isFetching = false;
                state.isFetchingOBJ.GetpublicTransferToPayment = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetpublicTransferToPayment.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetpublicTransferToPayment.rejected, (state, { payload }) => {
            try {
                state.ispublicPayment = false;
                state.isFetching = false;
                state.isFetchingOBJ.GetpublicTransferToPayment = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetpublicTransferToPayment.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetpublicTransferToPayment.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetpublicTransferToPayment = true;
        })
        //----
        builder.addCase(PaymentBroadcast.fulfilled, (state) => {
            try {
                state.isPaymentBroadcast = true;
                state.isFetching = false;
                state.isFetchingOBJ.PaymentBroadcast = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[PaymentBroadcast.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(PaymentBroadcast.rejected, (state, { payload }) => {
            try {
                state.isPaymentBroadcast = false;
                state.isFetching = false;
                state.isFetchingOBJ.PaymentBroadcast = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[PaymentBroadcast.rejected] try catch error >>', error);
            }
        })
        builder.addCase(PaymentBroadcast.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.PaymentBroadcast = true;
        })
    }
});
export const { updateState } = PublicSlice.actions;
export const PublicSelector = (state) => state.ncc.Public;