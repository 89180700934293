import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../axios';
import CONFIG from '../../Utils/config';
import Service from '../../Utils/service';
//---
const extractErrorMessage = (error) =>
    error?.response?.data?.Message ||
    error?.response?.data ||
    error?.response ||
    error?.message;
const extractErrorMessageRejected = (payload) =>
    payload?.response?.data?.Message?.toString() ||
    payload?.response?.data?.toString() ||
    payload?.response?.toString() ||
    payload?.err?.toString() ||
    payload?.data?.Message?.toString() ||
    payload?.data?.message?.toString() ||
    payload?.data?.toString() ||
    payload?.result?.toString() ||
    payload?.Message?.toString() ||
    payload?.message?.toString() ||
    payload?.error?.toString() ||
    "API Response Invalid.";
//---
export const GetSupportTicket = createAsyncThunk(
    'SupportTicket/GetSupportTicket',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}SupportTicket`, data: userdata, headers: authHeader });
            // console.log('GetSupportTicket result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const AddSupportticket = createAsyncThunk(
    'supportticket/supportticketcreate',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader('multipart/form-data');
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}SupportTicket/create`, data: postdata, headers: authHeader });
            // console.log('AddSupportticket result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const SubmitSupportticket = createAsyncThunk(
    'supportticket/SubmitSupportticket',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader('multipart/form-data');
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}SupportTicket`, data: postdata, headers: authHeader });
            // console.log('SubmitSupportticket result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const DeleteSupportticket = createAsyncThunk(
    'Supportticket/delete',
    async (param, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'DELETE', url: `${CONFIG.API_ENDPOINT}SupportTicket`, params: param, headers: authHeader });
            // console.log('DeleteSupportticket result.data >>', result.data);
            if (result.data.success) {
                result.postdata = param;
                return result.postdata;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetSupportTicketById = createAsyncThunk(
    'SupportTicket/GetSupportTicketById',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}SupportTicket/SupportTicketById`, params: userdata, data: userdata, headers: authHeader });
            // console.log('GetSupportTicketById result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const SupportTicketSlice = createSlice({
    name: 'SupportTicket',
    initialState: {
        SupportTicketdata: {},
        SupportTicketByIddata: {},
        isTicketadd: false,
        isTicketupdate: false,
        isTicketdelete: false,
        isFetchingOBJ: {},
        isFetching: false,
        isError: false,
        errorMessage: '',
    },
    reducers: {
        updateState: (state, { payload }) => {
            state.isTicketdelete = payload.isTicketdelete !== undefined ? payload.isTicketdelete : state.isTicketdelete;
            state.isTicketadd = payload.isTicketadd !== undefined ? payload.isTicketadd : state.isTicketadd;
            state.isTicketupdate = payload.isTicketupdate !== undefined ? payload.isTicketupdate : state.isTicketupdate;
            state.isError = payload.isError !== undefined ? payload.isError : state.isError;
            state.isError = payload.isError !== undefined ? payload.isError : state.isError;
            state.errorMessage = payload.errorMessage !== undefined ? payload.errorMessage : state.errorMessage;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(GetSupportTicket.fulfilled, (state, { payload }) => {
            try {
                state.SupportTicketdata = payload;
                state.isFetching = false;
                state.isFetchingOBJ.GetSupportTicket = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetSupportTicket.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetSupportTicket.rejected, (state, { payload }) => {
            try {
                state.SupportTicketdata = {};
                state.isFetching = false;
                state.isFetchingOBJ.GetSupportTicket = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetSupportTicket.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetSupportTicket.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetSupportTicket = true;
        })
        //----
        builder.addCase(AddSupportticket.fulfilled, (state, { payload }) => {
            try {
                let updated = payload.result;
                let existData = [...state.SupportTicketdata.data, updated];
                state.SupportTicketdata.recordsTotal += 1;
                state.SupportTicketdata.recordsFiltered += 1;
                state.SupportTicketdata.data = existData;
                state.isTicketadd = true;
                state.isFetching = false;
                state.isFetchingOBJ.AddSupportticket = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[AddSupportticket.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(AddSupportticket.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.AddSupportticket = false;
                state.isError = true;
                state.isTicketadd = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[AddSupportticket.rejected] try catch error >>', error);
            }
        })
        builder.addCase(AddSupportticket.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.AddSupportticket = true;
        })
        //----
        builder.addCase(SubmitSupportticket.fulfilled, (state) => {
            try {
                state.isTicketupdate = true;
                state.isFetching = false;
                state.isFetchingOBJ.SubmitSupportticket = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[SubmitSupportticket.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(SubmitSupportticket.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.SubmitSupportticket = false;
                state.isError = true;
                state.isTicketupdate = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[SubmitSupportticket.rejected] try catch error >>', error);
            }
        })
        builder.addCase(SubmitSupportticket.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.SubmitSupportticket = true;
        })
        //----
        builder.addCase(DeleteSupportticket.fulfilled, (state, { payload }) => {
            try {
                let exist = { ...state.SupportTicketdata };
                let newExist = exist.data?.filter((e) => {
                    if (e.TicketID !== payload.ID) {
                        return e;
                    }
                    return false;
                });
                exist.data = newExist;
                state.SupportTicketdata = exist;
                state.isFetching = false;
                state.isFetchingOBJ.DeleteSupportticket = false;
                state.isError = false;
                state.isTicketdelete = true;
            } catch (error) {
                console.error('[DeleteSupportticket.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(DeleteSupportticket.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.DeleteSupportticket = false;
                state.isError = true;
                state.isTicketdelete = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[DeleteSupportticket.rejected] try catch error >>', error);
            }
        })
        builder.addCase(DeleteSupportticket.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.DeleteSupportticket = true;
        })
        //----
        builder.addCase(GetSupportTicketById.fulfilled, (state, { payload }) => {
            try {
                state.SupportTicketByIddata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.GetSupportTicketById = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetSupportTicketById.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetSupportTicketById.rejected, (state, { payload }) => {
            try {
                state.SupportTicketByIddata = {};
                state.isFetching = false;
                state.isFetchingOBJ.GetSupportTicketById = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetSupportTicketById.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetSupportTicketById.pending, (state) => {
            state.SupportTicketByIddata = {};
            state.isFetching = true;
            state.isFetchingOBJ.GetSupportTicketById = true;
        })
    }
});
export const { updateState } = SupportTicketSlice.actions;
export const SupportTicketSelector = (state) => state.ncc.SupportTicket;