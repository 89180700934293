import { BrowserRouter, useRoutes } from "react-router-dom";
import loadable from '@loadable/component';
//------------ Layout
const LoginregisterLayout = loadable(() => import('../layout/LoginregisterLayout'));
const NCCLayout = loadable(() => import('../layout/NCCLayout'));
//------------- Internal Pages 
const Dashboard = loadable(() => import('../page/Internal/dashboard'));
const Home = loadable(() => import('../page/Internal/home'));
const InternalOrder = loadable(() => import('../page/Internal/internalorder'));
const Patients = loadable(() => import('../page/Internal/patients'));
const Order = loadable(() => import('../page/Internal/Order'));
const Quotes = loadable(() => import('../page/Internal/Quotes'));
const CompoundingQuoteView = loadable(() => import('../page/Internal/CompoundingQuoteView'));
const CompoundingQuoteCreate = loadable(() => import('../page/Internal/CompoundingQuoteCreate'));
const ProductList = loadable(() => import('../page/Internal/ProductList'));
const SupportTickets = loadable(() => import('../page/Internal/SupportTickets'));
const SupportTicketNew = loadable(() => import('../page/Internal/SupportTickets/supportticketnew'));
const SupportTicketOpen = loadable(() => import('../page/Internal/SupportTickets/supportticketopen'));
//------------ Internal System  Pages
const System = loadable(() => import('../page/Internal/system'));
const AllFile = loadable(() => import('../page/Internal/system/allfilesmngt'));
const Announcements = loadable(() => import('../page/Internal/system/announcemngt'));
const BrandManagement = loadable(() => import('../page/Internal/system/brandmngt'));
const Externalpopup = loadable(() => import('../page/Internal/system/extPopup'));
const Patientpopup = loadable(() => import('../page/Internal/system/pxPopup'));
const Storemanagement = loadable(() => import('../page/Internal/system/storemngt'));
const Emailtemplate = loadable(() => import('../page/Internal/system/emailtemplates'));
const GeneralSettings = loadable(() => import('../page/Internal/system/generalsettings'));
const Ingredient = loadable(() => import('../page/Internal/system/ingredientsmngt'));
const Medicineinfo = loadable(() => import('../page/Internal/system/medicinemngt'));
const PatientAnnouncements = loadable(() => import('../page/Internal/system/patientannouncement'));
const Patientlist = loadable(() => import('../page/Internal/system/patientlistmngt'));
const Pricelist = loadable(() => import('../page/Internal/system/pricelist'));
const NutritionalList = loadable(() => import('../page/Internal/system/NutritionalList'));
const Qaqctemplate = loadable(() => import('../page/Internal/system/qaqctemplates'));
const Smstemplate = loadable(() => import('../page/Internal/system/smstemplate'));
const SupportEmailtemp = loadable(() => import('../page/Internal/system/supportemailtemplates'));
const Teammembers = loadable(() => import('../page/Internal/system/TeamMembers'));
const Trainingvideo = loadable(() => import('../page/Internal/system/trainingvideo'));
const Troucheflavour = loadable(() => import('../page/Internal/system/TroucheFlavours'));
const OptionForItem = loadable(() => import('../page/Internal/system/optionForItem'));
//------------------- Internal Report Pages
const Report = loadable(() => import('../page/Internal/reports'));
const ProductInquiry = loadable(() => import('../page/Internal/reports/productinquiry'));
const Transactions = loadable(() => import('../page/Internal/reports/transactions'));
const QAReport = loadable(() => import('../page/Internal/reports/qareport'));
const OrderStatusReport = loadable(() => import('../page/Internal/reports/orderstatusreport'));
const RxoneReport = loadable(() => import('../page/Internal/reports/RxOne'));
const CurrentSOH = loadable(() => import('../page/Internal/reports/RxOne/rxonecurrentsoh'));
const RxoneMOnthlyUsage = loadable(() => import('../page/Internal/reports/RxOne/rxonemonthlyusage'));
const RxoneSohComparision = loadable(() => import('../page/Internal/reports/RxOne/rxonesohcomparision'));
//-------------------Internal Other Pages
const Other = loadable(() => import('../page/Internal/other/index'));
const Debortlist = loadable(() => import('../page/Internal/other/RxOne'));
const DebortlistNew = loadable(() => import('../page/Internal/other/RxOne/Debortlistnew'));
const DailyOrders = loadable(() => import('../page/Internal/other/RxOne/dailyorders'));
const AgeSales = loadable(() => import('../page/Internal/other/RxOne/agedsalesreport'));
const CancelAutoRepeat = loadable(() => import('../page/Internal/other/cancelautorepeat'));
const PrintedLabels = loadable(() => import('../page/Internal/other/printedlabels'));
const ReprintAddress = loadable(() => import('../page/Internal/other/ReprintAddress'));
const ReprintLabel = loadable(() => import('../page/Internal/other/Reprintlabel'));
const ReprintPOS = loadable(() => import('../page/Internal/other/Reprintpos'));
const OrderType = loadable(() => import('../page/Internal/system/ordertype'));
const Orderprint = loadable(() => import('../page/Internal/orderprint'));
const OrderprintAll = loadable(() => import('../page/Internal/orderprintAll'));
const Receiptprint = loadable(() => import('../page/Internal/receiptprint'));
const Doctorreport = loadable(() => import('../page/Internal/reports/RxOne/rxonetopdoctors'));
const Compoundingrxhistory = loadable(() => import('../page/Internal/other/RxOne/compoundingrxhistory'));
const OrderbyStatus = loadable(() => import('../page/Internal/reports/orderbystatus'));
const Orderbytype = loadable(() => import('../page/Internal/reports/orderbytype'));
const Pharmacybyorderno = loadable(() => import('../page/Internal/reports/pharmacybyorderno'));
const OrderbyMonth = loadable(() => import('../page/Internal/reports/orderbymonth'));
const StoremonthlySales = loadable(() => import('../page/Internal/reports/storemonthlysales'));
const Storeweeksale = loadable(() => import('../page/Internal/reports/storeweeksale'));
const Outstandingorder = loadable(() => import('../page/Internal/reports/outstandingorder'));
const DashboardAnnouncements = loadable(() => import('../page/Internal/announcemngt'));
const Clinicproducts = loadable(() => import('../page/Internal/system/clinicproducts'));
const QaqcBatch = loadable(() => import('../page/Internal/system/qaqcbatch'));
const ViewqaqcbyOrder = loadable(() => import('../page/Internal/viewqaqcbyorder'));
const Surveyresults = loadable(() => import('../page/Internal/other/surveyresults'));
const RxonePatients = loadable(() => import('../page/Internal/other/RxOne/patients'));
const OrderPrintlabel = loadable(() => import('../page/Internal/orderprintlabel'));
const InternalNileReport = loadable(() => import('../page/Internal/reports/nilereport'));
const InternalNiledoubleReport = loadable(() => import('../page/Internal/reports/niledoublereport'));
const InternalTemplate = loadable(() => import('../page/Internal/system/internaltemplate'));
const ResetPassword = loadable(() => import('../page/resetpassword'));
//-------------- External Pages
const Externaldashboard = loadable(() => import('../page/external/dashboard'));
const Externalannouncement = loadable(() => import('../page/external/announcement'));
const Externalsupportticket = loadable(() => import('../page/external/supportticket'));
const Externalemailtemplate = loadable(() => import('../page/external/emailtemplate'));
const Externalsicknessreport = loadable(() => import('../page/external/sicknessreport'));
const Externalorder = loadable(() => import('../page/external/externalorder'));
const Externalorderhistory = loadable(() => import('../page/external/orderhistory'));
const Externalpatientlist = loadable(() => import('../page/external/patientlist'));
const Externalproductlist = loadable(() => import('../page/external/productlist'));
const Externalmedicineinformation = loadable(() => import('../page/external/medicineinformation'));
const Externaltrainingvideo = loadable(() => import('../page/external/trainingvideo'));
const Externalqr8prescriptionext = loadable(() => import('../page/external/qr8prescriptionext'));
const Externalrxqr8prescription = loadable(() => import('../page/external/rxqr8prescription'));
const Externalsupportticketnew = loadable(() => import('../page/external/supportticket/externalsupportticketnew'));
const Externalsupportticketopen = loadable(() => import('../page/external/supportticket/externalsupportticketopen'));
const Externalsicknessdoublereport = loadable(() => import('../page/external/sicknessdoublereport'));
const ExternalBmprescription = loadable(() => import('../page/external/bmprescription'));
const ExternalJeremyprescription = loadable(() => import('../page/external/jeremyprescription'));
const ExternalScottprescription = loadable(() => import('../page/external/scottprescription'));
const NCCTsi = loadable(() => import('../page/external/nccTSI/'));
//======================= Patient ==============================
const PatientLogin = loadable(() => import('../page/patientlogin'));
const Patientsignup = loadable(() => import('../page/patientsignup'));
const Patientdashboard = loadable(() => import('../page/patient/dashboard'));
const Patientannouncement = loadable(() => import('../page/patient/announcement'));
const Patientorder = loadable(() => import('../page/patient/patientorder'));
const PatientOrders = loadable(() => import('../page/patient/orders'));
const PatientProductlist = loadable(() => import('../page/patient/productlist'));
const Patientmedicineinfo = loadable(() => import('../page/patient/medicineinfo'));
//=================== Vet Clinic =====================
const Vetclinicannouncement = loadable(() => import('../page/vetclinic/announcement'));
const VetclinicUploadpriscription = loadable(() => import('../page/vetclinic/uploadpriscription'));
const VetclinicCreatepriscription = loadable(() => import('../page/vetclinic/createprescription'));
const VetclinicOrderhistory = loadable(() => import('../page/vetclinic/orderhistory'));
const VetclinicPatientlist = loadable(() => import('../page/vetclinic/patientlist'));
const VetclinicProductlist = loadable(() => import('../page/vetclinic/productlist'));
const VetclinicMedicineinfo = loadable(() => import('../page/vetclinic/medicineinfo'));
const VetclinicTrainingvideo = loadable(() => import('../page/vetclinic/trainingvideo'));
const VetclinicSupportticket = loadable(() => import('../page/vetclinic/supportticket'));
const VetclinicEmailtemplate = loadable(() => import('../page/vetclinic/emailtemplate'));
const VetclinicSupportTicketNew = loadable(() => import('../page/vetclinic/supportticket/vetclinicsupportticketnew'));
const VetclinicSupportTicketOpen = loadable(() => import('../page/vetclinic/supportticket/vetclinicsupportticketopen'));
const Vetclinicreprint = loadable(() => import('../page/vetclinicreprint'));
//------------- clinic 
const Clinicannouncement = loadable(() => import('../page/clinic/announcement'));
const Clinictrainingvideo = loadable(() => import('../page/clinic/trainingvideo'));
const ClinicMedicineinfo = loadable(() => import('../page/clinic/medicineinfo'));
const ClinicProductlist = loadable(() => import('../page/clinic/productlist'));
const ClinicSupportticket = loadable(() => import('../page/clinic/supportticket'));
const ClinicSupportTicketNew = loadable(() => import('../page/clinic/supportticket/supportticketnew'));
const ClinicSupportTicketOpen = loadable(() => import('../page/clinic/supportticket/supportticketopen'));
const ClinicEmailtemplate = loadable(() => import('../page/clinic/emailtemplate'));
const ClinicUploadpriscription = loadable(() => import('../page/clinic/clinicorder'));
const ClinicPatientlist = loadable(() => import('../page/clinic/patientlist'));
const OrderHiistory = loadable(() => import('../page/clinic/orderhistory'));
const Createclinicorder = loadable(() => import('../page/clinic/createclinicorder'));
//-------------- common pages
const Quote = loadable(() => import('../page/quote'));
const Compoundingorder = loadable(() => import('../page/compoundingorder'));
const Zendeskorder = loadable(() => import('../page/zendeskorder'));
const MedicineInfocommon = loadable(() => import('../page/medicineinfocommon'));
const Productlistcommon = loadable(() => import('../page/productlistcommon'));
const Practitionerreg = loadable(() => import('../page/practitionerreg'));
const BulkQaqc = loadable(() => import('../page/bulkqaqc'));
const Riskassessment = loadable(() => import('../page/riskassessment'));
const NewPartner = loadable(() => import('../page/newpartnersignup'));
const Login = loadable(() => import('../page/login'));
const Profile = loadable(() => import('../page/profile'));
const PrintPdf = loadable(() => import('../page/Printpdf'));
const Optout = loadable(() => import('../page/optout'));
const Tokenexpireverify = loadable(() => import('../page/tokenverifyexpire'));
const Scriptprint = loadable(() => import('../page/scriptprint'));
const Invoiceprint = loadable(() => import('../page/invoiceprint'));
const MakePayment = loadable(() => import('../page/Makepayment'));
//------------  not found page
const Notfound = loadable(() => import('../page/notfound'));
//======================
export default function Router() {
  const Routerset = () => {
    let routes = useRoutes([
      //======= not login
      {
        path: '/',
        element: <LoginregisterLayout />,
        children: [
          { index: true, element: <Login /> },
          { path: '/login', element: <Login /> },
          { path: '/patientlogin', element: <PatientLogin /> },
          { path: '/PatientRegistration', element: <Patientsignup /> },
        ]
      },
      { path: '/resetpassword', element: <ResetPassword /> },
      {
        path: '/internal',
        element: <NCCLayout type='internal' />,
        children: [
          { path: '/internal/dashboard', element: <Dashboard /> },
          //----
          { path: '/internal/home', element: <Home /> },
          { path: '/internal/home/announcement', element: <DashboardAnnouncements /> },
          { path: '/internal/home/internalorder', element: <InternalOrder /> },
          { path: '/internal/Patients', element: <Patients /> },
          //---
          { path: '/internal/Order', element: <Order /> },
          { path: '/internal/Order/:orderId', element: <Order /> },
          { path: '/internal/Order/internalorder', element: <InternalOrder /> },
          //------- /internal/Report 
          { path: '/internal/Report', element: <Report /> },
          { path: '/internal/Report/patientinquiry', element: <ProductInquiry /> },
          { path: '/internal/Report/transactions', element: <Transactions /> },
          { path: '/internal/Report/QAReport', element: <QAReport /> },
          { path: '/internal/Report/orderstatusreport', element: <OrderStatusReport /> },
          { path: '/internal/Report/orderbystatus/:year', element: <OrderbyStatus /> },
          { path: '/internal/Report/orderbytype/:year', element: <Orderbytype /> },
          { path: '/internal/Report/orderbyorderno/:year', element: <Pharmacybyorderno /> },
          { path: '/internal/Report/orderbymonth/:year', element: <OrderbyMonth /> },
          { path: '/internal/Report/storemonthlysales', element: <StoremonthlySales /> },
          { path: '/internal/Report/storeweeksales', element: <Storeweeksale /> },
          { path: '/internal/Report/outstandingorder', element: <Outstandingorder /> },
          { path: '/internal/Report/nilereport', element: <InternalNileReport /> },
          { path: '/internal/Report/niledoublereport', element: <InternalNiledoubleReport /> },
          { path: '/internal/Report/RxOne', element: <RxoneReport /> },
          { path: '/internal/Report/RxOne/rxonereport/:year', element: <RxoneReport /> },
          { path: '/internal/Report/RxOne/rxonecurrentsoh', element: <CurrentSOH /> },
          { path: '/internal/Report/RxOne/rxonemonthlyusage', element: <RxoneMOnthlyUsage /> },
          { path: '/internal/Report/RxOne/rxonesohcomparision', element: <RxoneSohComparision /> },
          { path: '/internal/Report/RxOne/rxonetopdoctors', element: <Doctorreport /> },
          //-----
          { path: '/internal/Quotes', element: <Quotes /> },
          {
            path: '/internal/powder-quote',
            children: [
              { index: true, element: <CompoundingQuoteView /> },
              { path: '/internal/powder-quote/create', element: <CompoundingQuoteCreate /> },
            ]
          },
          //-----
          { path: '/internal/ProductList', element: <ProductList /> },
          //-----
          { path: '/internal/SupportTickets', element: <SupportTickets /> },
          { path: '/internal/SupportTickets/supportticketnew', element: <SupportTicketNew /> },
          { path: '/internal/SupportTickets/supportticketopen', element: <SupportTicketOpen /> },
          //----- /internal/System 
          { path: '/internal/System', element: <System /> },
          { path: '/internal/System/AllFile', element: <AllFile /> },
          { path: '/internal/System/Announcements', element: <Announcements /> },
          { path: '/internal/System/BrandManagement', element: <BrandManagement /> },
          { path: '/internal/System/Externalpopup', element: <Externalpopup /> },
          { path: '/internal/System/Patientpopup', element: <Patientpopup /> },
          { path: '/internal/System/Storemanagement', element: <Storemanagement /> },
          { path: '/internal/System/Emailtemplate', element: <Emailtemplate /> },
          { path: '/internal/System/GeneralSettings', element: <GeneralSettings /> },
          { path: '/internal/System/Ingredient', element: <Ingredient /> },
          { path: '/internal/System/Medicineinfo', element: <Medicineinfo /> },
          { path: '/internal/System/PatientAnnouncements', element: <PatientAnnouncements /> },
          { path: '/internal/System/Patientlist', element: <Patientlist /> },
          { path: '/internal/System/Pricelist', element: <Pricelist /> },
          { path: '/internal/System/Nutritionallist', element: <NutritionalList /> },
          { path: '/internal/System/Qaqcbatch', element: <QaqcBatch /> },
          { path: '/internal/System/Qaqctemplate', element: <Qaqctemplate /> },
          { path: '/internal/System/Smstemplate', element: <Smstemplate /> },
          { path: '/internal/System/SupportEmailtemp', element: <SupportEmailtemp /> },
          { path: '/internal/System/InternalTemplate', element: <InternalTemplate /> },
          { path: '/internal/System/Trainingvideo', element: <Trainingvideo /> },
          { path: '/internal/System/Troucheflavour', element: <Troucheflavour /> },
          { path: '/internal/System/Teammember', element: <Teammembers /> },
          { path: '/internal/System/ordertype', element: <OrderType /> },
          { path: '/internal/System/OptionForItem', element: <OptionForItem /> },
          { path: '/internal/System/clinicproducts', element: <Clinicproducts /> },
          //------- /internal/other 
          { path: '/internal/other', element: <Other /> },
          { path: '/internal/other/printedlabels', element: <PrintedLabels /> },
          { path: '/internal/other/cancelautorepeat', element: <CancelAutoRepeat /> },
          { path: '/internal/other/surveryresults', element: <Surveyresults /> },
          { path: '/internal/other/RxOne/debtorlist', element: <Debortlist /> },
          { path: '/internal/other/RxOne/newdebtorlist', element: <DebortlistNew /> },
          { path: '/internal/other/RxOne/dailyorder', element: <DailyOrders /> },
          { path: '/internal/other/RxOne/agedsalesreport', element: <AgeSales /> },
          { path: '/internal/other/RxOne/rxonecompoundhistory', element: <Compoundingrxhistory /> },
          { path: '/internal/other/RxOne/rxonepatients', element: <RxonePatients /> },
          //-------
          { path: '/internal/profile', element: <Profile /> },
        ]
      },
      {
        path: '/external',
        element: <NCCLayout type='external' />,
        children: [
          { path: '/externaldashboard', element: <Externaldashboard /> },
          { path: '/external/announcement', element: <Externalannouncement /> },
          { path: '/external/emailtemplates', element: <Externalemailtemplate /> },
          { path: '/external/nilereport', element: <Externalsicknessreport /> },
          { path: '/external/niledoublereport', element: <Externalsicknessdoublereport /> },
          { path: '/external/externalorder', element: <Externalorder /> },
          { path: '/external/order', element: <Externalorderhistory /> },
          { path: '/external/order/:orderId', element: <Externalorderhistory /> },
          { path: '/external/clients', element: <Externalpatientlist /> },
          { path: '/external/productlist', element: <Externalproductlist /> },
          { path: '/external/medicineinfo', element: <Externalmedicineinformation /> },
          { path: '/external/training', element: <Externaltrainingvideo /> },
          { path: '/external/qr8prescriptionext', element: <Externalqr8prescriptionext /> },
          { path: '/external/rxqr8prescription', element: <Externalrxqr8prescription /> },
          { path: '/external/bmprescription', element: <ExternalBmprescription /> },
          { path: '/external/jeremyprescription', element: <ExternalJeremyprescription /> },
          { path: '/external/scottprescription', element: <ExternalScottprescription /> },
          { path: '/external/supportticket', element: <Externalsupportticket /> },
          { path: '/external/supportticket/supportticketnew', element: <Externalsupportticketnew /> },
          { path: '/external/supportticket/supportticketopen', element: <Externalsupportticketopen /> },
          { path: '/external/ncctsi', element: <NCCTsi /> },
        ]
      },
      {
        path: '/patient',
        element: <NCCLayout type='patient' />,
        children: [
          { path: '/patient/dashboard', element: <Patientdashboard /> },
          { path: '/patient/announcement', element: <Patientannouncement /> },
          { path: '/patient/patientorder', element: <Patientorder /> },
          { path: '/patient/orders', element: <PatientOrders /> },
          { path: '/patient/productlist', element: <PatientProductlist /> },
          { path: '/patient/medicineinfo', element: <Patientmedicineinfo /> },
        ]
      },
      {
        path: '/vetclinic',
        element: <NCCLayout type='vetclinic' />,
        children: [
          { index: true, element: <Vetclinicannouncement /> },
          { path: '/vetclinic/announcement', element: <Vetclinicannouncement /> },
          { path: '/vetclinic/emailtemplate', element: <VetclinicEmailtemplate /> },
          { path: '/vetclinic/createprescription', element: <VetclinicCreatepriscription /> },
          { path: '/vetclinic/uploadprescription', element: <VetclinicUploadpriscription /> },
          { path: '/vetclinic/orderhistory', element: <VetclinicOrderhistory /> },
          { path: '/vetclinic/orderhistory/:orderId', element: <VetclinicOrderhistory /> },
          { path: '/vetclinic/patientlist', element: <VetclinicPatientlist /> },
          { path: '/vetclinic/productlist', element: <VetclinicProductlist /> },
          { path: '/vetclinic/medicineinfo', element: <VetclinicMedicineinfo /> },
          { path: '/vetclinic/trainingvideo', element: <VetclinicTrainingvideo /> },
          { path: '/vetclinic/supportticket', element: <VetclinicSupportticket /> },
          { path: '/vetclinic/supportticket/supportticketnew', element: <VetclinicSupportTicketNew /> },
          { path: '/vetclinic/supportticket/supportticketopen', element: <VetclinicSupportTicketOpen /> },
        ]
      },
      {
        path: '/clinic',
        element: <NCCLayout type='clinic' />,
        children: [
          { index: true, element: <Clinicannouncement /> },
          { path: '/clinic/announcement', element: <Clinicannouncement /> },
          { path: '/clinic/trainingvideo', element: <Clinictrainingvideo /> },
          { path: '/clinic/medicineinfo', element: <ClinicMedicineinfo /> },
          { path: '/clinic/productlist', element: <ClinicProductlist /> },
          { path: '/clinic/emailtemplate', element: <ClinicEmailtemplate /> },
          { path: '/clinic/createorder', element: <ClinicUploadpriscription /> },
          { path: '/clinic/patientlist', element: <ClinicPatientlist /> },
          { path: '/clinic/orderhistory', element: <OrderHiistory /> },
          { path: '/clinic/orderhistory/:orderId', element: <OrderHiistory /> },
          { path: '/clinic/createclinicorder', element: <Createclinicorder /> },
          { path: '/clinic/supportticket', element: <ClinicSupportticket /> },
          { path: '/clinic/supportticket/supportticketnew', element: <ClinicSupportTicketNew /> },
          { path: '/clinic/supportticket/supportticketopen', element: <ClinicSupportTicketOpen /> },
        ]
      },
      { path: "common/reprintaddress", element: <ReprintAddress /> },
      { path: "common/reprintlabel", element: <ReprintLabel /> },
      { path: "common/reprintpos", element: <ReprintPOS /> },
      { path: "common/orderprintAll", element: <OrderprintAll /> },
      { path: "common/orderprint", element: <Orderprint /> },
      { path: "common/orderprintlabel/:orderid/:cby", element: <OrderPrintlabel /> },
      { path: "common/receiptprint", element: <Receiptprint /> },
      { path: "common/scriptprint", element: <Scriptprint /> },
      { path: "common/invoiceprint", element: <Invoiceprint /> },
      //--
      { path: "common/bulkqaqc", element: <BulkQaqc /> },
      { path: "common/viewqaqcbyorder/:id", element: <ViewqaqcbyOrder /> },
      { path: "common/riskassessment/:orderid", element: <Riskassessment /> },
      //--
      { path: "common/newpartnersignup", element: <NewPartner /> },
      { path: "common/compoundingorder", element: <Compoundingorder /> },
      { path: "common/quote", element: <Quote /> },
      { path: "common/medicineinfocommon", element: <MedicineInfocommon /> },
      { path: "common/productlistcommon", element: <Productlistcommon /> },
      { path: "common/practitionerreg", element: <Practitionerreg /> },
      { path: "common/zendeskorder", element: <Zendeskorder /> },
      //-----
      { path: '/printpdf', element: <PrintPdf /> },
      { path: '/optout', element: <Optout /> },
      { path: '/tokenexpireverify', element: <Tokenexpireverify /> },
      { path: "vetclinic/reprint/:orderid", element: <Vetclinicreprint /> },
      { path: "/MakePayment", element: <MakePayment /> },
      //--------- not found page-------------
      { path: "*", element: <Notfound /> }
    ]);
    return routes;
  };
  return (
    <BrowserRouter future={{ v7_relativeSplatPath: true, v7_startTransition: true, }}>
      <Routerset />
    </BrowserRouter>
  );
}