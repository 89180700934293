import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../axios';
import CONFIG from '../../Utils/config';
import Service from '../../Utils/service';
//---
const extractErrorMessage = (error) =>
    error?.response?.data?.Message ||
    error?.response?.data ||
    error?.response ||
    error?.message;
const extractErrorMessageRejected = (payload) =>
    payload?.response?.data?.Message?.toString() ||
    payload?.response?.data?.toString() ||
    payload?.response?.toString() ||
    payload?.err?.toString() ||
    payload?.data?.Message?.toString() ||
    payload?.data?.message?.toString() ||
    payload?.data?.toString() ||
    payload?.result?.toString() ||
    payload?.Message?.toString() ||
    payload?.message?.toString() ||
    payload?.error?.toString() ||
    "API Response Invalid.";
//---
export const GetProductInquiry = createAsyncThunk(
    'ProductInquiry/GetProductInquiry',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}ProductInquiry`, data: userdata, headers: authHeader });
            // console.log('GetProductInquiry result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const AddProductInquiry = createAsyncThunk(
    'ProductInquiry/add',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}ProductInquiry/create`, data: postdata, headers: authHeader });
            // console.log('AddProductInquiry result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const DeleteProductInquiry = createAsyncThunk(
    'ProductInquiry/delete',
    async (param, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'DELETE', url: `${CONFIG.API_ENDPOINT}ProductInquiry`, params: param, headers: authHeader });
            // console.log('DeleteProductInquiry result.data >>', result.data);
            if (result.data.success) {
                result.postdata = param;
                return result.postdata;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const ProductSlice = createSlice({
    name: 'Product',
    initialState: {
        ProductInquirydata: [],
        isinquiryAdded: false,
        isinquiryDelete: false,
        isFetchingOBJ: {},
        isFetching: false,
        isError: false,
        errorMessage: '',
    },
    reducers: {
        updateState: (state, { payload }) => {
            state.isinquiryAdded = payload.isinquiryAdded !== undefined ? payload.isinquiryAdded : state.isinquiryAdded;
            state.isinquiryDelete = payload.isinquiryDelete !== undefined ? payload.isinquiryDelete : state.isinquiryDelete;
            state.isError = payload.isError !== undefined ? payload.isError : state.isError;
            state.errorMessage = payload.errorMessage !== undefined ? payload.errorMessage : state.errorMessage;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(GetProductInquiry.fulfilled, (state, { payload }) => {
            try {
                state.ProductInquirydata = payload;
                state.isFetching = false;
                state.isFetchingOBJ.GetProductInquiry = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetProductInquiry.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetProductInquiry.rejected, (state, { payload }) => {
            try {
                state.ProductInquirydata = [];
                state.isFetching = false;
                state.isFetchingOBJ.GetProductInquiry = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetProductInquiry.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetProductInquiry.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetProductInquiry = true;
        })
        //----
        builder.addCase(AddProductInquiry.fulfilled, (state, { payload }) => {
            try {
                let updated = payload.result;
                let existData = [...state.ProductInquirydata.data];
                let newData = [...existData, updated];
                state.ProductInquirydata.recordsTotal += 1;
                state.ProductInquirydata.recordsFiltered += 1;
                state.ProductInquirydata.data = newData;
                state.isinquiryAdded = true;
                state.isFetching = false;
                state.isFetchingOBJ.AddProductInquiry = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[AddProductInquiry.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(AddProductInquiry.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.AddProductInquiry = false;
                state.isError = true;
                state.isinquiryAdded = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[AddProductInquiry.rejected] try catch error >>', error);
            }
        })
        builder.addCase(AddProductInquiry.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.AddProductInquiry = true;
        })
        //----
        builder.addCase(DeleteProductInquiry.fulfilled, (state, { payload }) => {
            try {
                let exist = { ...state.ProductInquirydata };
                let newExist = exist.data?.filter((e) => {
                    if (e.ID !== payload.ID) {
                        return e;
                    }
                    return false;
                });
                exist.data = newExist;
                state.ProductInquirydata = exist;
                state.isFetching = false;
                state.isFetchingOBJ.DeleteProductInquiry = false;
                state.isError = false;
                state.isinquiryDelete = true;
            } catch (error) {
                console.error('[DeleteProductInquiry.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(DeleteProductInquiry.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.DeleteProductInquiry = false;
                state.isError = true;
                state.isinquiryDelete = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[DeleteProductInquiry.rejected] try catch error >>', error);
            }
        })
        builder.addCase(DeleteProductInquiry.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.DeleteProductInquiry = true;
        })
    }
});
export const { updateState } = ProductSlice.actions;
export const ProductSelector = (state) => state.ncc.Product;