import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../axios';
import CONFIG from '../../Utils/config';
import Service from '../../Utils/service';
//---
const extractErrorMessage = (error) =>
    error?.response?.data?.Message ||
    error?.response?.data ||
    error?.response ||
    error?.message;
const extractErrorMessageRejected = (payload) =>
    payload?.response?.data?.Message?.toString() ||
    payload?.response?.data?.toString() ||
    payload?.response?.toString() ||
    payload?.err?.toString() ||
    payload?.data?.Message?.toString() ||
    payload?.data?.message?.toString() ||
    payload?.data?.toString() ||
    payload?.result?.toString() ||
    payload?.Message?.toString() ||
    payload?.message?.toString() ||
    payload?.error?.toString() ||
    "API Response Invalid.";
//---
export const GetClient = createAsyncThunk(
    'Client/GetClient',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'GET', url: `${CONFIG.API_ENDPOINT}Client`, params: userdata, headers: authHeader });
            // console.log('GetClient result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetClientbyid = createAsyncThunk(
    'InternalOrder/GetClientbyid',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}InternalOrder/SearchClientById`, params: userdata, data: userdata, headers: authHeader });
            // console.log('GetClientbyid result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const AddInternalorderpaylater = createAsyncThunk(
    'InternalOrder/AddInternalorderpaylater',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader('multipart/form-data');
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}InternalOrder/PayLaterCreate`, data: userdata, headers: authHeader });
            // console.log('AddInternalorderpaylater result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const AddInternalorderpxcreate = createAsyncThunk(
    'InternalOrder/AddInternalorderpxcreate',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader('multipart/form-data');
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}InternalOrder/PxCreate`, data: userdata, headers: authHeader });
            // console.log('AddInternalorderpxcreate result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const AddInternalorderpaynow = createAsyncThunk(
    'InternalOrder/AddInternalorderpaynow',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader('multipart/form-data');
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}InternalOrder/InternalOrderPayNow`, data: userdata, headers: authHeader });
            // console.log('AddInternalorderpaynow result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const AddsubmitInternalOrder = createAsyncThunk(
    'InternalOrder/AddsubmitInternalOrder',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader('multipart/form-data');
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}InternalOrder/InternalOrder`, data: userdata, headers: authHeader });
            // console.log('AddsubmitInternalOrder result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const TransaferToPaymentScreen = createAsyncThunk(
    'Order/TransaferToPaymentScreen',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Order/TransaferToPaymentScreen`, params: userdata, data: userdata, headers: authHeader });
            // console.log('TransaferToPaymentScreen result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const TransaferToPayment = createAsyncThunk(
    'Order/TransaferToPayment',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Order/TransaferToPayment`, data: userdata, headers: authHeader });
            // console.log('TransaferToPayment result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const TransaferToSaveCardPayment = createAsyncThunk(
    'Order/TransaferToSaveCardPayment',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Order/TransaferToSaveCardPayment`, data: userdata, headers: authHeader });
            // console.log('TransaferToSaveCardPayment result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const CreatePrescription = createAsyncThunk(
    'InternalOrder/CreatePrescription',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}InternalOrder/CreatePrescription`, data: userdata, headers: authHeader });
            // console.log('CreatePrescription result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const InternalShippingOptions = createAsyncThunk(
    'InternalOrder/InternalShippingOptions',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}InternalOrder/ShippingOptions`, data: userdata, headers: authHeader });
            // console.log('InternalShippingOptions result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const HomeSlice = createSlice({
    name: 'Home',
    initialState: {
        Clientdata: [],
        SearchClientdata: {},
        Internalorderpaylaterdata: [],
        Internalorderpxcreatedata: [],
        Internalorderpaynowdata: null,
        TransaferToPaymentScreendata: [],
        orderpaymentdata: [],
        submitInternalOrderdata: [],
        CreatePrescriptiondata: [],
        InternalShippingOptionsdata: [],
        ispaymentsuccess: false,
        isInternalorderadded: false,
        isInternalorderpxcreateadded: false,
        isInternalorderpaynowadded: false,
        issubmitInternalOrderadded: false,
        iscardpaymentsuccess: false,
        isCreatePrescription: false,
        isFetchingOBJ: {},
        isFetching: false,
        isError: false,
        errorMessage: '',
    },
    reducers: {
        updateState: (state, { payload }) => {
            state.Internalorderpaynowdata = payload.Internalorderpaynowdata !== undefined ? payload.Internalorderpaynowdata : state.Internalorderpaynowdata;
            state.InternalShippingOptionsdata = payload.InternalShippingOptionsdata !== undefined ? payload.InternalShippingOptionsdata : state.InternalShippingOptionsdata;
            state.SearchClientdata = payload.SearchClientdata !== undefined ? payload.SearchClientdata : state.SearchClientdata;
            state.isInternalorderadded = payload.isInternalorderadded !== undefined ? payload.isInternalorderadded : state.isInternalorderadded;
            state.ispaymentsuccess = payload.ispaymentsuccess !== undefined ? payload.ispaymentsuccess : state.ispaymentsuccess;
            state.iscardpaymentsuccess = payload.iscardpaymentsuccess !== undefined ? payload.iscardpaymentsuccess : state.iscardpaymentsuccess;
            state.isInternalorderpxcreateadded = payload.isInternalorderpxcreateadded !== undefined ? payload.isInternalorderpxcreateadded : state.isInternalorderpxcreateadded;
            state.issubmitInternalOrderadded = payload.issubmitInternalOrderadded !== undefined ? payload.issubmitInternalOrderadded : state.issubmitInternalOrderadded;
            state.isInternalorderpaynowadded = payload.isInternalorderpaynowadded !== undefined ? payload.isInternalorderpaynowadded : state.isInternalorderpaynowadded;
            state.isCreatePrescription = payload.isCreatePrescription !== undefined ? payload.isCreatePrescription : state.isCreatePrescription;
            state.isError = payload.isError !== undefined ? payload.isError : state.isError;
            state.errorMessage = payload.errorMessage !== undefined ? payload.errorMessage : state.errorMessage;
        },
        clearState: (state) => {
            state.Internalorderpaynowdata = null;
            state.SearchClientdata = {};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(GetClient.fulfilled, (state, { payload }) => {
            try {
                state.Clientdata = payload.data;
                state.isFetching = false;
                state.isFetchingOBJ.GetClient = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetClient.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetClient.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.GetClient = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetClient.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetClient.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetClient = true;
        })
        //----
        builder.addCase(GetClientbyid.fulfilled, (state, { payload }) => {
            try {
                state.SearchClientdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.GetClientbyid = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetClientbyid.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetClientbyid.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.GetClientbyid = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetClientbyid.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetClientbyid.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetClientbyid = true;
        })
        //----
        builder.addCase(AddInternalorderpaylater.fulfilled, (state, { payload }) => {
            try {
                state.Internalorderpaylaterdata = payload;
                state.isInternalorderadded = true;
                state.isFetching = false;
                state.isFetchingOBJ.AddInternalorderpaylater = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[AddInternalorderpaylater.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(AddInternalorderpaylater.rejected, (state, { payload }) => {
            try {
                state.Internalorderpaylaterdata = [];
                state.isInternalorderadded = false;
                state.isFetching = false;
                state.isFetchingOBJ.AddInternalorderpaylater = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[AddInternalorderpaylater.rejected] try catch error >>', error);
            }
        })
        builder.addCase(AddInternalorderpaylater.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.AddInternalorderpaylater = true;
        })
        //----
        builder.addCase(AddInternalorderpxcreate.fulfilled, (state, { payload }) => {
            try {
                state.Internalorderpxcreatedata = payload;
                state.isInternalorderpxcreateadded = true;
                state.isFetching = false;
                state.isFetchingOBJ.AddInternalorderpxcreate = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[AddInternalorderpxcreate.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(AddInternalorderpxcreate.rejected, (state, { payload }) => {
            try {
                state.Internalorderpxcreatedata = [];
                state.isInternalorderpxcreateadded = false;
                state.isFetching = false;
                state.isFetchingOBJ.AddInternalorderpxcreate = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[AddInternalorderpxcreate.rejected] try catch error >>', error);
            }
        })
        builder.addCase(AddInternalorderpxcreate.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.AddInternalorderpxcreate = true;
        })
        //----
        builder.addCase(AddInternalorderpaynow.fulfilled, (state, { payload }) => {
            try {
                state.Internalorderpaynowdata = payload.result;
                state.isInternalorderpaynowadded = true;
                state.isFetching = false;
                state.isFetchingOBJ.AddInternalorderpaynow = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[AddInternalorderpaynow.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(AddInternalorderpaynow.rejected, (state, { payload }) => {
            try {
                state.Internalorderpaynowdata = null;
                state.isInternalorderpaynowadded = false;
                state.isFetching = false;
                state.isFetchingOBJ.AddInternalorderpaynow = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[AddInternalorderpaynow.rejected] try catch error >>', error);
            }
        })
        builder.addCase(AddInternalorderpaynow.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.AddInternalorderpaynow = true;
        })
        //----
        builder.addCase(AddsubmitInternalOrder.fulfilled, (state, { payload }) => {
            try {
                state.submitInternalOrderdata = payload;
                state.issubmitInternalOrderadded = true;
                state.isFetching = false;
                state.isFetchingOBJ.AddsubmitInternalOrder = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[AddsubmitInternalOrder.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(AddsubmitInternalOrder.rejected, (state, { payload }) => {
            try {
                state.issubmitInternalOrderadded = false;
                state.isFetching = false;
                state.isFetchingOBJ.AddsubmitInternalOrder = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[AddsubmitInternalOrder.rejected] try catch error >>', error);
            }
        })
        builder.addCase(AddsubmitInternalOrder.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.AddsubmitInternalOrder = true;
        })
        //----
        builder.addCase(TransaferToPaymentScreen.fulfilled, (state, { payload }) => {
            try {
                state.TransaferToPaymentScreendata = payload;
                state.isFetching = false;
                state.isFetchingOBJ.TransaferToPaymentScreen = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[TransaferToPaymentScreen.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(TransaferToPaymentScreen.rejected, (state, { payload }) => {
            try {
                state.TransaferToPaymentScreendata = [];
                state.isFetching = false;
                state.isFetchingOBJ.TransaferToPaymentScreen = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[TransaferToPaymentScreen.rejected] try catch error >>', error);
            }
        })
        builder.addCase(TransaferToPaymentScreen.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.TransaferToPaymentScreen = true;
        })
        //----
        builder.addCase(TransaferToPayment.fulfilled, (state, { payload }) => {
            try {
                state.orderpaymentdata = payload;
                state.ispaymentsuccess = true;
                state.isFetching = false;
                state.isFetchingOBJ.TransaferToPayment = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[TransaferToPayment.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(TransaferToPayment.rejected, (state, { payload }) => {
            try {
                state.orderpaymentdata = [];
                state.ispaymentsuccess = false;
                state.isFetching = false;
                state.isFetchingOBJ.TransaferToPayment = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[TransaferToPayment.rejected] try catch error >>', error);
            }
        })
        builder.addCase(TransaferToPayment.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.TransaferToPayment = true;
        })
        //----
        builder.addCase(TransaferToSaveCardPayment.fulfilled, (state, { payload }) => {
            try {
                state.orderpaymentdata = payload;
                state.iscardpaymentsuccess = true;
                state.isFetchingOBJ.TransaferToSaveCardPayment = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[TransaferToSaveCardPayment.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(TransaferToSaveCardPayment.rejected, (state, { payload }) => {
            try {
                state.orderpaymentdata = [];
                state.iscardpaymentsuccess = false;
                state.isFetchingOBJ.TransaferToSaveCardPayment = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[TransaferToSaveCardPayment.rejected] try catch error >>', error);
            }
        })
        builder.addCase(TransaferToSaveCardPayment.pending, (state) => {
            state.isFetchingOBJ.TransaferToSaveCardPayment = true;
        })
        //----
        builder.addCase(CreatePrescription.fulfilled, (state, { payload }) => {
            try {
                state.CreatePrescriptiondata = payload;
                state.isCreatePrescription = true;
                state.isFetchingOBJ.CreatePrescription = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[CreatePrescription.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(CreatePrescription.rejected, (state, { payload }) => {
            try {
                state.CreatePrescriptiondata = [];
                state.isCreatePrescription = false;
                state.isFetchingOBJ.CreatePrescription = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[CreatePrescription.rejected] try catch error >>', error);
            }
        })
        builder.addCase(CreatePrescription.pending, (state) => {
            state.isFetchingOBJ.CreatePrescription = true;
        })
        //----
        builder.addCase(InternalShippingOptions.fulfilled, (state, { payload }) => {
            try {
                state.InternalShippingOptionsdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.InternalShippingOptions = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[InternalShippingOptions.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(InternalShippingOptions.rejected, (state, { payload }) => {
            try {
                state.InternalShippingOptionsdata = [];
                state.isFetching = false;
                state.isFetchingOBJ.InternalShippingOptions = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[InternalShippingOptions.rejected] try catch error >>', error);
            }
        })
        builder.addCase(InternalShippingOptions.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.InternalShippingOptions = true;
        })
    }
});
export const { clearState, updateState } = HomeSlice.actions;
export const HomeSelector = (state) => state.ncc.Home;