import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../axios';
import CONFIG from '../../Utils/config';
import Service from '../../Utils/service';
import _ from 'lodash';
//---
const extractErrorMessage = (error) =>
    error?.response?.data?.Message ||
    error?.response?.data ||
    error?.response ||
    error?.message;
const extractErrorMessageRejected = (payload) =>
    payload?.response?.data?.Message?.toString() ||
    payload?.response?.data?.toString() ||
    payload?.response?.toString() ||
    payload?.err?.toString() ||
    payload?.data?.Message?.toString() ||
    payload?.data?.message?.toString() ||
    payload?.data?.toString() ||
    payload?.result?.toString() ||
    payload?.Message?.toString() ||
    payload?.message?.toString() ||
    payload?.error?.toString() ||
    "API Response Invalid.";
//---
export const GetPatient = createAsyncThunk(
    'Patient/GetPatient',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Patient`, data: userdata, headers: authHeader });
            // console.log('GetPatient result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const Updatepatient = createAsyncThunk(
    'patient/Updatepatient',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}Patient`, data: postdata, headers: authHeader });
            // console.log('Updatepatient result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const Patientorderhistory = createAsyncThunk(
    'patient/Patientorderhistory',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Patient/PatientOrderHistory`, params: postdata, data: postdata, headers: authHeader });
            // console.log('Patientorderhistory result.data >>', result.data);
            if (result.data.success) {
                return result.data.result;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const Patientclientnote = createAsyncThunk(
    'patient/Patientclientnote',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Patient/PatientClientNotes`, params: postdata, data: postdata, headers: authHeader });
            // console.log('Patientclientnote result.data >>', result.data);
            if (result.data.success) {
                return result.data.result;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const CreatePatientClientNotes = createAsyncThunk(
    'patient/CreatePatientClientNotes',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Patient/CreatePatientClientNotes`, data: postdata, headers: authHeader });
            // console.log('CreatePatientClientNotes result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const SendResetPasswordLink = createAsyncThunk(
    'patient/SendResetPasswordLink',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Patient/SendResetPasswordLink`, params: postdata, data: postdata, headers: authHeader });
            // console.log('SendResetPasswordLink result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const PatientForgotPasswordEmail = createAsyncThunk(
    'patient/ForgotPasswordEmail',
    async (userdata, thunkAPI) => {
        try {
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Profile/ForgotPasswordEmail`, params: userdata, data: userdata, headers: CONFIG.API_HEADER.headers });
            // console.log('ForgotPasswordEmail result.data >>', result);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const UpdateEmailPatient = createAsyncThunk(
    'patient/UpdateEmailPatient',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}Patient/UpdateEmailPatient`, params: postdata, data: postdata, headers: authHeader });
            // console.log('UpdateEmailPatient result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const EnableDisableForMobile = createAsyncThunk(
    'patient/EnableDisableForMobile',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Patient/EnableDisableForMobile`, params: postdata, data: postdata, headers: authHeader });
            // console.log('EnableDisableForMobile result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const PatientRepeat = createAsyncThunk(
    'patient/PatientRepeat',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Patient/PatientRepeat`, params: postdata, data: postdata, headers: authHeader });
            // console.log('PatientRepeat result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const CreatePatinetOrder = createAsyncThunk(
    'InternalOrder/CreatePatinetOrder',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader('multipart/form-data');
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}InternalOrder/CreatePatientOrder`, data: postdata, headers: authHeader });
            // console.log('CreatePatinetOrder result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetCardDetailList = createAsyncThunk(
    'Patient/GetCardDetailList',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader('multipart/form-data');
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Patient/GetCardDetailList`, params: postdata, data: postdata, headers: authHeader });
            // console.log('GetCardDetailList result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const AddSavePatientCard = createAsyncThunk(
    'Order/AddSavePatientCard',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            const { client_id, ...Carddata } = postdata;
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}Order/UpdateSaveCardForClient`, params: { client_id: client_id }, data: Carddata, headers: authHeader });
            // console.log('AddSavePatientCard result.data >>', result); 
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const UpdateSaveCard = createAsyncThunk(
    'Order/UpdateSaveCard',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}Order/UpdateSaveCard`, data: postdata, headers: authHeader });
            // console.log('UpdateSaveCard result.data >>', result);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const DeleteSavedCard = createAsyncThunk(
    'patient/DeleteSavedCard',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'DELETE', url: `${CONFIG.API_ENDPOINT}Patient/DeleteSavedCard`, params: postdata, headers: authHeader });
            // console.log('DeleteSavedCard result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const MergePatient = createAsyncThunk(
    'Patient/MergePatient',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Patient/MergePatient`, params: postdata, data: postdata, headers: authHeader });
            // console.log('MergePatient result.data >>', result.data);
            if (result.data.success) {
                return postdata;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const MergePatientBulk = createAsyncThunk(
    'Patient/MergePatientBulk',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Patient/BulkMergePatient`, params: postdata.params, data: postdata.body, headers: authHeader });
            // console.log('MergePatientBulk result.data >>', result.data);
            if (result.data.success) {
                return postdata;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const PatientSlice = createSlice({
    name: 'Patient',
    initialState: {
        Patientdata: {},
        SearchPatientdata: [],
        patienthistorydata: [],
        patientclientnotedata: [],
        Resetpassworddatadata: [],
        PatientRepeatdata: [],
        CreatePatinetOrderdata: [],
        CardDetailListdata: [],
        isPatientUpdated: false,
        isClientnotesadded: false,
        isresetlinksend: false,
        isCreatePatinetOrder: false,
        isresetemail: false,
        isPatientcardAdd: false,
        isPatientcardupdated: false,
        isPatientcarddeleted: false,
        isPatientmerge: false,
        isPatientmergeBulk: false,
        isrepeatError: false,
        isFetchingOBJ: {},
        isFetching: false,
        isError: false,
        errorMessage: '',
        hasmore: false
    },
    reducers: {
        updateState: (state, { payload }) => {
            state.SearchPatientdata = payload.SearchPatientdata !== undefined ? payload.SearchPatientdata : state.SearchPatientdata;
            state.Patientdata = payload.Patientdata !== undefined ? payload.Patientdata : state.Patientdata;
            state.PatientRepeatdata = payload.PatientRepeatdata !== undefined ? payload.PatientRepeatdata : state.PatientRepeatdata;
            state.isPatientUpdated = payload.isPatientUpdated !== undefined ? payload.isPatientUpdated : state.isPatientUpdated;
            state.isClientnotesadded = payload.isClientnotesadded !== undefined ? payload.isClientnotesadded : state.isClientnotesadded;
            state.isCreatePatinetOrder = payload.isCreatePatinetOrder !== undefined ? payload.isCreatePatinetOrder : state.isCreatePatinetOrder;
            state.isresetlinksend = payload.isresetlinksend !== undefined ? payload.isresetlinksend : state.isresetlinksend;
            state.isresetemail = payload.isresetemail !== undefined ? payload.isresetemail : state.isresetemail;
            state.isPatientcardAdd = payload.isPatientcardAdd !== undefined ? payload.isPatientcardAdd : state.isPatientcardAdd;
            state.isPatientcardupdated = payload.isPatientcardupdated !== undefined ? payload.isPatientcardupdated : state.isPatientcardupdated;
            state.isPatientcarddeleted = payload.isPatientcarddeleted !== undefined ? payload.isPatientcarddeleted : state.isPatientcarddeleted;
            state.isPatientmerge = payload.isPatientmerge !== undefined ? payload.isPatientmerge : state.isPatientmerge;
            state.isPatientmergeBulk = payload.isPatientmergeBulk !== undefined ? payload.isPatientmergeBulk : state.isPatientmergeBulk;
            state.isError = payload.isError !== undefined ? payload.isError : state.isError;
            state.isrepeatError = payload.isrepeatError !== undefined ? payload.isrepeatError : state.isrepeatError;
            state.errorMessage = payload.errorMessage !== undefined ? payload.errorMessage : state.errorMessage;
            state.hasmore = payload.hasmore !== undefined ? payload.hasmore : state.hasmore;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(GetPatient.fulfilled, (state, { payload }) => {
            try {
                if (state.hasmore) {
                    let updated = payload.data;
                    const newData = _.unionBy(state.Patientdata.data, updated, 'client_id');
                    state.Patientdata.data = newData;
                } else {
                    state.Patientdata = payload;
                }
                state.isFetchingOBJ.GetPatient = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetPatient.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetPatient.rejected, (state, { payload }) => {
            try {
                state.Patientdata = [];
                state.isFetchingOBJ.GetPatient = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetPatient.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetPatient.pending, (state) => {
            if (state.hasmore) {
                state.isFetchingOBJ.GetPatient = false;
            } else {
                state.isFetchingOBJ.GetPatient = true;
            }
        })
        //----
        builder.addCase(Updatepatient.fulfilled, (state, { payload }) => {
            try {
                let exist = { ...state.Patientdata };
                let updated = payload.result;
                const index = state.Patientdata.data.findIndex(opt => opt.client_id === updated.client_id)
                exist.data[index] = updated;
                state.Patientdata = exist;
                state.isPatientUpdated = true;
                state.isFetching = false;
                state.isFetchingOBJ.Updatepatient = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[Updatepatient.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(Updatepatient.rejected, (state, { payload }) => {
            try {
                state.isPatientUpdated = false;
                state.isFetching = false;
                state.isFetchingOBJ.Updatepatient = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[Updatepatient.rejected] try catch error >>', error);
            }
        })
        builder.addCase(Updatepatient.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.Updatepatient = true;
        })
        //----
        builder.addCase(Patientorderhistory.fulfilled, (state, { payload }) => {
            try {
                state.patienthistorydata = payload;
                state.isFetching = false;
                state.isFetchingOBJ.Patientorderhistory = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[Patientorderhistory.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(Patientorderhistory.rejected, (state, { payload }) => {
            try {
                state.patienthistorydata = [];
                state.isFetching = false;
                state.isFetchingOBJ.Patientorderhistory = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[Patientorderhistory.rejected] try catch error >>', error);
            }
        })
        builder.addCase(Patientorderhistory.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.Patientorderhistory = true;
        })
        //----
        builder.addCase(Patientclientnote.fulfilled, (state, { payload }) => {
            try {
                state.patientclientnotedata = payload;
                state.isFetching = false;
                state.isFetchingOBJ.Patientclientnote = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[Patientclientnote.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(Patientclientnote.rejected, (state, { payload }) => {
            try {
                state.patientclientnotedata = [];
                state.isFetching = false;
                state.isFetchingOBJ.Patientclientnote = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[Patientclientnote.rejected] try catch error >>', error);
            }
        })
        builder.addCase(Patientclientnote.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.Patientclientnote = true;
        })
        //----
        builder.addCase(CreatePatientClientNotes.fulfilled, (state, { payload }) => {
            try {
                let updated = payload.result;
                let exist = [...state.patientclientnotedata]
                let newData = [...exist, updated];
                state.patientclientnotedata = newData;
                state.isClientnotesadded = true;
                state.isFetching = false;
                state.isFetchingOBJ.CreatePatientClientNotes = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[CreatePatientClientNotes.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(CreatePatientClientNotes.rejected, (state, { payload }) => {
            try {
                state.patientclientnotedata = [];
                state.isClientnotesadded = false;
                state.isFetching = false;
                state.isFetchingOBJ.CreatePatientClientNotes = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[CreatePatientClientNotes.rejected] try catch error >>', error);
            }
        })
        builder.addCase(CreatePatientClientNotes.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.CreatePatientClientNotes = true;
        })
        //----
        builder.addCase(SendResetPasswordLink.fulfilled, (state, { payload }) => {
            try {
                state.Resetpassworddatadata = payload.result;
                state.isresetlinksend = true;
                state.isFetching = false;
                state.isFetchingOBJ.SendResetPasswordLink = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[SendResetPasswordLink.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(SendResetPasswordLink.rejected, (state, { payload }) => {
            try {
                state.Resetpassworddatadata = {};
                state.isresetlinksend = false;
                state.isFetching = false;
                state.isFetchingOBJ.SendResetPasswordLink = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[SendResetPasswordLink.rejected] try catch error >>', error);
            }
        })
        builder.addCase(SendResetPasswordLink.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.SendResetPasswordLink = true;
        })
        //----
        builder.addCase(PatientForgotPasswordEmail.fulfilled, (state, { payload }) => {
            try {
                state.Resetpassworddatadata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.PatientForgotPasswordEmail = false;
                state.isError = false;
                state.isresetlinksend = true;
                state.errorMessage = '';
            } catch (error) {
                console.error('[PatientForgotPasswordEmail.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(PatientForgotPasswordEmail.rejected, (state, { payload }) => {
            try {
                state.Resetpassworddatadata = {};
                state.isFetching = false;
                state.isFetchingOBJ.PatientForgotPasswordEmail = false;
                state.isError = true;
                state.isresetlinksend = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[PatientForgotPasswordEmail.rejected] try catch error >>', error);
            }
        })
        builder.addCase(PatientForgotPasswordEmail.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.PatientForgotPasswordEmail = true;
        })
        //----
        builder.addCase(UpdateEmailPatient.fulfilled, (state, { payload }) => {
            try {
                state.Resetpassworddatadata = payload.result;
                state.isresetemail = true;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateEmailPatient = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[UpdateEmailPatient.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(UpdateEmailPatient.rejected, (state, { payload }) => {
            try {
                state.Resetpassworddatadata = {};
                state.isresetemail = false;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateEmailPatient = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[UpdateEmailPatient.rejected] try catch error >>', error);
            }
        })
        builder.addCase(UpdateEmailPatient.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.UpdateEmailPatient = true;
        })
        //----
        builder.addCase(EnableDisableForMobile.fulfilled, (state) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.EnableDisableForMobile = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[EnableDisableForMobile.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(EnableDisableForMobile.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.EnableDisableForMobile = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[EnableDisableForMobile.rejected] try catch error >>', error);
            }
        })
        builder.addCase(EnableDisableForMobile.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.EnableDisableForMobile = true;
        })
        //----
        builder.addCase(PatientRepeat.fulfilled, (state, { payload }) => {
            try {
                state.PatientRepeatdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.PatientRepeat = false;
                state.isrepeatError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[PatientRepeat.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(PatientRepeat.rejected, (state, { payload }) => {
            try {
                state.PatientRepeatdata = [];
                state.isFetching = false;
                state.isFetchingOBJ.PatientRepeat = false;
                state.isrepeatError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[PatientRepeat.rejected] try catch error >>', error);
            }
        })
        builder.addCase(PatientRepeat.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.PatientRepeat = true;
        })
        //----
        builder.addCase(CreatePatinetOrder.fulfilled, (state, { payload }) => {
            try {
                state.CardDetailListdata = payload.result;
                state.isCreatePatinetOrder = true;
                state.isFetching = false;
                state.isFetchingOBJ.CreatePatinetOrder = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[CreatePatinetOrder.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(CreatePatinetOrder.rejected, (state, { payload }) => {
            try {
                state.CardDetailListdata = {};
                state.isCreatePatinetOrder = false;
                state.isFetching = false;
                state.isFetchingOBJ.CreatePatinetOrder = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[CreatePatinetOrder.rejected] try catch error >>', error);
            }
        })
        builder.addCase(CreatePatinetOrder.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.CreatePatinetOrder = true;
        })
        //----
        builder.addCase(GetCardDetailList.fulfilled, (state, { payload }) => {
            try {
                state.CreatePatinetOrderdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.GetCardDetailList = false;
                state.isCreatePatinetOrder = true;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetCardDetailList.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetCardDetailList.rejected, (state, { payload }) => {
            try {
                state.CreatePatinetOrderdata = {};
                state.isCreatePatinetOrder = false;
                state.isFetching = false;
                state.isFetchingOBJ.GetCardDetailList = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetCardDetailList.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetCardDetailList.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetCardDetailList = true;
        })
        //----
        builder.addCase(AddSavePatientCard.fulfilled, (state) => {
            try {
                state.isPatientcardAdd = true;
                state.isFetching = false;
                state.isFetchingOBJ.AddSavePatientCard = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[AddSavePatientCard.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(AddSavePatientCard.rejected, (state, { payload }) => {
            try {
                state.isPatientcardAdd = false;
                state.isFetching = false;
                state.isFetchingOBJ.AddSavePatientCard = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[AddSavePatientCard.rejected] try catch error >>', error);
            }
        })
        builder.addCase(AddSavePatientCard.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.AddSavePatientCard = true;
        })
        //----
        builder.addCase(UpdateSaveCard.fulfilled, (state) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.UpdateSaveCard = false;
                state.isPatientcardupdated = true;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[UpdateSaveCard.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(UpdateSaveCard.rejected, (state, { payload }) => {
            try {
                state.isPatientcardupdated = false;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateSaveCard = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[UpdateSaveCard.rejected] try catch error >>', error);
            }
        })
        builder.addCase(UpdateSaveCard.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.UpdateSaveCard = true;
        })
        //----
        builder.addCase(DeleteSavedCard.fulfilled, (state) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.DeleteSavedCard = false;
                state.isPatientcarddeleted = true;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[DeleteSavedCard.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(DeleteSavedCard.rejected, (state, { payload }) => {
            try {
                state.isPatientcarddeleted = false;
                state.isFetching = false;
                state.isFetchingOBJ.DeleteSavedCard = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[DeleteSavedCard.rejected] try catch error >>', error);
            }
        })
        builder.addCase(DeleteSavedCard.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.DeleteSavedCard = true;
        })
        //----
        builder.addCase(MergePatient.fulfilled, (state, { payload }) => {
            try {
                let exist = { ...state.Patientdata };
                let newExist = exist.data?.filter((e) => {
                    if (e.client_id !== payload.OldClientID) {
                        return e;
                    }
                    return false;
                });
                exist.data = newExist;
                state.Patientdata = exist;
                state.isPatientmerge = true;
                state.isFetching = false;
                state.isFetchingOBJ.MergePatient = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[MergePatient.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(MergePatient.rejected, (state, { payload }) => {
            try {
                state.isPatientmerge = false;
                state.isFetching = false;
                state.isFetchingOBJ.MergePatient = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[MergePatient.rejected] try catch error >>', error);
            }
        })
        builder.addCase(MergePatient.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.MergePatient = true;
        })
        //----
        builder.addCase(MergePatientBulk.fulfilled, (state, { payload }) => {
            try {
                let exist = { ...state.Patientdata };
                let newExist = exist.data?.filter((e) => !payload.body?.includes(e.client_id));
                exist.data = newExist;
                state.Patientdata = exist;
                state.isPatientmergeBulk = true;
                state.isFetching = false;
                state.isFetchingOBJ.MergePatientBulk = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[MergePatientBulk.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(MergePatientBulk.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.MergePatientBulk = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[MergePatientBulk.rejected] try catch error >>', error);
            }
        })
        builder.addCase(MergePatientBulk.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.MergePatientBulk = true;
        })
    }
});
export const { updateState } = PatientSlice.actions;
export const PatientSelector = (state) => state.ncc.Patient;