import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../axios';
import CONFIG from '../../Utils/config';
import Service from '../../Utils/service';
//---
const extractErrorMessage = (error) =>
    error?.response?.data?.Message ||
    error?.response?.data ||
    error?.response ||
    error?.message;
const extractErrorMessageRejected = (payload) =>
    payload?.response?.data?.Message?.toString() ||
    payload?.response?.data?.toString() ||
    payload?.response?.toString() ||
    payload?.err?.toString() ||
    payload?.data?.Message?.toString() ||
    payload?.data?.message?.toString() ||
    payload?.data?.toString() ||
    payload?.result?.toString() ||
    payload?.Message?.toString() ||
    payload?.message?.toString() ||
    payload?.error?.toString() ||
    "API Response Invalid.";
//---
export const GetZendeskClient = createAsyncThunk(
    'other/GetZendeskClient',
    async (userdata, thunkAPI) => {
        try {
            let result = await axios({ method: 'GET', url: `${CONFIG.API_ENDPOINT}Zendesk/ZendeskClient`, params: userdata });
            // console.log('GetZendeskClient result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetZendeskClientbyid = createAsyncThunk(
    'other/GetZendeskClientbyid',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Zendesk/SearchClientById`, params: userdata, data: userdata, headers: authHeader });
            // console.log('GetZendeskClientbyid result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const ZendeskShippingOptions = createAsyncThunk(
    'other/ZendeskShippingOptions',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Zendesk/ShippingOptions`, data: userdata, headers: authHeader });
            // console.log('ZendeskShippingOptions result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetAutorepeat = createAsyncThunk(
    'InternalOrder/GetAutorepeat',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}InternalOrder/AutoRepeatOrder`, data: userdata, headers: authHeader });
            // console.log('GetAutorepeat result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const AutoRepeatOrderCancel = createAsyncThunk(
    'InternalOrder/AutoRepeatOrderCancel',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'DELETE', url: `${CONFIG.API_ENDPOINT}InternalOrder/AutoRepeatOrderCancel`, params: postdata, headers: authHeader });
            // console.log('AutoRepeatOrderCancel result.data >>', result.data);
            if (result.data.success) {
                result.data = postdata;
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetPrintedlabel = createAsyncThunk(
    'PrintedLabel/GetPrintedlabel',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}PrintedLabel`, data: userdata, headers: authHeader });
            // console.log('GetPrintedlabel result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetReprintedPOS = createAsyncThunk(
    'PrintedLabel/GetReprintedPOS',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}PrintedLabel/ReprintedPOS`, params: userdata, data: userdata, headers: authHeader });
            // console.log('GetReprintedPOS result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetReprintedAddress = createAsyncThunk(
    'PrintedLabel/GetReprintedAddress',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}PrintedLabel/ReprintedAddress`, params: userdata, data: userdata, headers: authHeader });
            // console.log('GetReprintedAddress result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetReprintedLabel = createAsyncThunk(
    'PrintedLabel/GetReprintedLabel',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}PrintedLabel/ReprintedLabel`, params: userdata, data: userdata, headers: authHeader });
            // console.log('GetReprintedLabel result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const AddBulkqaqc = createAsyncThunk(
    'QAQCchecklist/AddBulkqaqc',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}QAQCchecklist/create`, data: userdata, headers: authHeader });
            // console.log('AddBulkqaqc result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const createAutoRepeat = createAsyncThunk(
    'InternalOrder/createAutoRepeat',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}InternalOrder/createAutoRepeat`, data: userdata, headers: authHeader });
            // console.log('createAutoRepeat result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const ordercreateAutoRepeat = createAsyncThunk(
    'Order/ordercreateAutoRepeat',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Order/createAutoRepeat`, data: userdata, headers: authHeader });
            // console.log('ordercreateAutoRepeat result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const Owner = createAsyncThunk(
    'Owner/Owner',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'GET', url: `${CONFIG.API_ENDPOINT}Owner`, params: userdata, headers: authHeader });
            // console.log('Owner result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const CreateZendeskOther = createAsyncThunk(
    'other/CreateZendeskOther',
    async (userdata, thunkAPI) => {
        try {
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Zendesk/create`, data: userdata });
            // console.log('CreateZendeskOther result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const CreateZendesk = createAsyncThunk(
    'Zendesk/CreateZendesk',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Zendesk/create`, data: userdata, headers: authHeader });
            // console.log('CreateZendesk result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const AddPrintedPOS = createAsyncThunk(
    'PrintedLabel/AddPrintedPOS',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}PrintedLabel/AddPrintedPOS`, data: userdata, headers: authHeader });
            // console.log('AddPrintedPOS result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const AddPrintedLabel = createAsyncThunk(
    'PrintedLabel/AddPrintedLabel',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}PrintedLabel/AddPrintedLabel`, data: userdata, headers: authHeader });
            // console.log('AddPrintedLabel result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const AddPrintedAddress = createAsyncThunk(
    'PrintedLabel/AddPrintedAddress',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}PrintedLabel/AddPrintedAddress`, data: userdata, headers: authHeader });
            // console.log('AddPrintedAddress result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const OtherSlice = createSlice({
    name: 'Other',
    initialState: {
        ZendeskClientdata: [],
        ZendeskSearchClientdata: {},
        zendeskShippingOptionsdata: [],
        Autorepeatdata: [],
        Printedlabeldata: [],
        ReprintedAddressdata: [],
        ReprintedPOSdata: [],
        ReprintedLabeldata: [],
        Bulkqaqcdata: [],
        Ownerdata: [],
        Printlabeldata: {},
        isRepeatcancel: false,
        isRepeatadd: false,
        isBulkqaqcadd: false,
        isZendeskcreate: false,
        isFetchingOBJ: {},
        isFetching: false,
        isError: false,
        errorMessage: '',
    },
    reducers: {
        updateState: (state, { payload }) => {
            state.ZendeskSearchClientdata = payload.ZendeskSearchClientdata !== undefined ? payload.ZendeskSearchClientdata : state.ZendeskSearchClientdata;
            state.zendeskShippingOptionsdata = payload.zendeskShippingOptionsdata !== undefined ? payload.zendeskShippingOptionsdata : state.zendeskShippingOptionsdata;
            state.isBulkqaqcadd = payload.isBulkqaqcadd !== undefined ? payload.isBulkqaqcadd : state.isBulkqaqcadd;
            state.isRepeatadd = payload.isRepeatadd !== undefined ? payload.isRepeatadd : state.isRepeatadd;
            state.isRepeatcancel = payload.isRepeatcancel !== undefined ? payload.isRepeatcancel : state.isRepeatcancel;
            state.isZendeskcreate = payload.isZendeskcreate !== undefined ? payload.isZendeskcreate : state.isZendeskcreate;
            state.isError = payload.isError !== undefined ? payload.isError : state.isError;
            state.errorMessage = payload.errorMessage !== undefined ? payload.errorMessage : state.errorMessage;
        },
        clearState: (state) => {
            state.Printlabeldata = {};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(GetZendeskClient.fulfilled, (state, { payload }) => {
            try {
                state.ZendeskClientdata = payload.data;
                state.isFetching = false;
                state.isFetchingOBJ.GetZendeskClient = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetZendeskClient.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetZendeskClient.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.GetZendeskClient = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetZendeskClient.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetZendeskClient.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetZendeskClient = true;
        })
        //----
        builder.addCase(GetZendeskClientbyid.fulfilled, (state, { payload }) => {
            try {
                state.ZendeskSearchClientdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.GetZendeskClientbyid = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetZendeskClientbyid.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetZendeskClientbyid.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.GetZendeskClientbyid = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetZendeskClientbyid.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetZendeskClientbyid.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetZendeskClientbyid = true;
        })
        //---
        builder.addCase(ZendeskShippingOptions.fulfilled, (state, { payload }) => {
            try {
                state.zendeskShippingOptionsdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.ZendeskShippingOptions = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[ZendeskShippingOptions.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(ZendeskShippingOptions.rejected, (state, { payload }) => {
            try {
                state.zendeskShippingOptionsdata = [];
                state.isFetching = false;
                state.isFetchingOBJ.ZendeskShippingOptions = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[ZendeskShippingOptions.rejected] try catch error >>', error);
            }
        })
        builder.addCase(ZendeskShippingOptions.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.ZendeskShippingOptions = true;
        })
        //---
        builder.addCase(GetAutorepeat.fulfilled, (state, { payload }) => {
            try {
                state.Autorepeatdata = payload;
                state.isFetching = false;
                state.isFetchingOBJ.GetAutorepeat = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetAutorepeat.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetAutorepeat.rejected, (state, { payload }) => {
            try {
                state.Autorepeatdata = [];
                state.isFetching = false;
                state.isFetchingOBJ.GetAutorepeat = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetAutorepeat.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetAutorepeat.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetAutorepeat = true;
        })
        //----
        builder.addCase(AutoRepeatOrderCancel.fulfilled, (state, { payload }) => {
            try {
                let exist = { ...state.Autorepeatdata };
                let newExist = exist.data?.filter((e) => {
                    if (e.orderid !== payload.ID) {
                        return e;
                    }
                    return false;
                });
                exist.data = newExist;
                state.Autorepeatdata = exist;
                state.isRepeatcancel = true;
                state.isFetching = false;
                state.isFetchingOBJ.AutoRepeatOrderCancel = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[AutoRepeatOrderCancel.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(AutoRepeatOrderCancel.rejected, (state, { payload }) => {
            try {
                state.isRepeatcancel = false;
                state.isFetching = false;
                state.isFetchingOBJ.AutoRepeatOrderCancel = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[AutoRepeatOrderCancel.rejected] try catch error >>', error);
            }
        })
        builder.addCase(AutoRepeatOrderCancel.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.AutoRepeatOrderCancel = true;
        })
        //----
        builder.addCase(GetPrintedlabel.fulfilled, (state, { payload }) => {
            try {
                state.Printedlabeldata = payload;
                state.isFetching = false;
                state.isFetchingOBJ.GetPrintedlabel = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetPrintedlabel.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetPrintedlabel.rejected, (state, { payload }) => {
            try {
                state.Printedlabeldata = [];
                state.isFetching = false;
                state.isFetchingOBJ.GetPrintedlabel = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetPrintedlabel.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetPrintedlabel.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetPrintedlabel = true;
        })
        //----
        builder.addCase(GetReprintedAddress.fulfilled, (state, { payload }) => {
            try {
                state.ReprintedAddressdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.GetReprintedAddress = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetReprintedAddress.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetReprintedAddress.rejected, (state, { payload }) => {
            try {
                state.ReprintedAddressdata = [];
                state.isFetching = false;
                state.isFetchingOBJ.GetReprintedAddress = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetReprintedAddress.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetReprintedAddress.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetReprintedAddress = true;
        })
        //----
        builder.addCase(GetReprintedPOS.fulfilled, (state, { payload }) => {
            try {
                state.ReprintedPOSdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.GetReprintedPOS = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetReprintedPOS.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetReprintedPOS.rejected, (state, { payload }) => {
            try {
                state.ReprintedPOSdata = [];
                state.isFetching = false;
                state.isFetchingOBJ.GetReprintedPOS = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetReprintedPOS.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetReprintedPOS.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetReprintedPOS = true;
        })
        //----
        builder.addCase(GetReprintedLabel.fulfilled, (state, { payload }) => {
            try {
                state.ReprintedLabeldata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.GetReprintedLabel = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetReprintedLabel.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetReprintedLabel.rejected, (state, { payload }) => {
            try {
                state.ReprintedLabeldata = [];
                state.isFetching = false;
                state.isFetchingOBJ.GetReprintedLabel = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetReprintedLabel.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetReprintedLabel.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetReprintedLabel = true;
        })
        //----
        builder.addCase(AddBulkqaqc.fulfilled, (state, { payload }) => {
            try {
                state.Bulkqaqcdata = payload.result;
                state.isBulkqaqcadd = true;
                state.isFetching = false;
                state.isFetchingOBJ.AddBulkqaqc = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[AddBulkqaqc.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(AddBulkqaqc.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.AddBulkqaqc = false;
                state.isError = true;
                state.isBulkqaqcadd = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[AddBulkqaqc.rejected] try catch error >>', error);
            }
        })
        builder.addCase(AddBulkqaqc.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.AddBulkqaqc = true;
        })
        //----
        builder.addCase(createAutoRepeat.fulfilled, (state, { payload }) => {
            try {
                let updated = payload.result;
                let existData = [...state.Autorepeatdata.data];
                let newData = [updated, ...existData];
                state.Autorepeatdata.data = newData;
                state.isRepeatadd = true;
                state.isFetching = false;
                state.isFetchingOBJ.createAutoRepeat = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[createAutoRepeat.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(createAutoRepeat.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.createAutoRepeat = false;
                state.isError = true;
                state.isRepeatadd = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[createAutoRepeat.rejected] try catch error >>', error);
            }
        })
        builder.addCase(createAutoRepeat.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.createAutoRepeat = true;
        })
        //----
        builder.addCase(ordercreateAutoRepeat.fulfilled, (state, { payload }) => {
            try {
                let updated = payload.result;
                let existData = [...state.Autorepeatdata.data];
                let newData = [...existData, updated];
                state.Autorepeatdata.data = newData;
                state.isRepeatadd = true;
                state.isFetching = false;
                state.isFetchingOBJ.ordercreateAutoRepeat = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[ordercreateAutoRepeat.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(ordercreateAutoRepeat.rejected, (state) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.ordercreateAutoRepeat = false;
                state.isError = true;
                state.isRepeatadd = false;
                state.errorMessage = "Please insert your orderid";
            } catch (error) {
                console.error('[ordercreateAutoRepeat.rejected] try catch error >>', error);
            }
        })
        builder.addCase(ordercreateAutoRepeat.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.ordercreateAutoRepeat = true;
        })
        //----
        builder.addCase(Owner.fulfilled, (state, { payload }) => {
            try {
                state.Ownerdata = payload.data;
                state.isFetching = false;
                state.isFetchingOBJ.Owner = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[Owner.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(Owner.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.Owner = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[Owner.rejected] try catch error >>', error);
            }
        })
        builder.addCase(Owner.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.Owner = true;
        })
        //----
        builder.addCase(CreateZendesk.fulfilled, (state) => {
            try {
                state.isZendeskcreate = true;
                state.isFetching = false;
                state.isFetchingOBJ.CreateZendesk = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[CreateZendesk.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(CreateZendesk.rejected, (state, { payload }) => {
            try {
                state.isZendeskcreate = false;
                state.isFetching = false;
                state.isFetchingOBJ.CreateZendesk = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[CreateZendesk.rejected] try catch error >>', error);
            }
        })
        builder.addCase(CreateZendesk.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.CreateZendesk = true;
        })
        //----
        builder.addCase(CreateZendeskOther.fulfilled, (state) => {
            try {
                state.isZendeskcreate = true;
                state.isFetching = false;
                state.isFetchingOBJ.CreateZendeskOther = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[CreateZendeskOther.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(CreateZendeskOther.rejected, (state, { payload }) => {
            try {
                state.isZendeskcreate = false;
                state.isFetching = false;
                state.isFetchingOBJ.CreateZendeskOther = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[CreateZendeskOther.rejected] try catch error >>', error);
            }
        })
        builder.addCase(CreateZendeskOther.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.CreateZendeskOther = true;
        })
        //----
        builder.addCase(AddPrintedPOS.fulfilled, (state, { payload }) => {
            try {
                state.Printlabeldata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.AddPrintedPOS = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[AddPrintedPOS.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(AddPrintedPOS.rejected, (state, { payload }) => {
            try {
                state.Printlabeldata = {};
                state.isFetching = false;
                state.isFetchingOBJ.AddPrintedPOS = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[AddPrintedPOS.rejected] try catch error >>', error);
            }
        })
        builder.addCase(AddPrintedPOS.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.AddPrintedPOS = true;
        })
        //----
        builder.addCase(AddPrintedLabel.fulfilled, (state, { payload }) => {
            try {
                state.Printlabeldata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.AddPrintedLabel = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[AddPrintedLabel.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(AddPrintedLabel.rejected, (state, { payload }) => {
            try {
                state.Printlabeldata = {};
                state.isFetching = false;
                state.isFetchingOBJ.AddPrintedLabel = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[AddPrintedLabel.rejected] try catch error >>', error);
            }
        })
        builder.addCase(AddPrintedLabel.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.AddPrintedLabel = true;
        })
        //----
        builder.addCase(AddPrintedAddress.fulfilled, (state, { payload }) => {
            try {
                state.Printlabeldata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.AddPrintedAddress = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[AddPrintedAddress.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(AddPrintedAddress.rejected, (state, { payload }) => {
            try {
                state.Printlabeldata = {};
                state.isFetching = false;
                state.isFetchingOBJ.AddPrintedAddress = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[AddPrintedAddress.rejected] try catch error >>', error);
            }
        })
        builder.addCase(AddPrintedAddress.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.AddPrintedAddress = true;
        })
    }
});
export const { clearState, updateState } = OtherSlice.actions;
export const OtherSelector = (state) => state.ncc.Other;