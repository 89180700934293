import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../axios';
import CONFIG from '../../Utils/config';
import Service from '../../Utils/service';
//---
const extractErrorMessage = (error) =>
    error?.response?.data?.Message ||
    error?.response?.data ||
    error?.response ||
    error?.message;
const extractErrorMessageRejected = (payload) =>
    payload?.response?.data?.Message?.toString() ||
    payload?.response?.data?.toString() ||
    payload?.response?.toString() ||
    payload?.err?.toString() ||
    payload?.data?.Message?.toString() ||
    payload?.data?.message?.toString() ||
    payload?.data?.toString() ||
    payload?.result?.toString() ||
    payload?.Message?.toString() ||
    payload?.message?.toString() ||
    payload?.error?.toString() ||
    "API Response Invalid.";
//---
export const GetQuote = createAsyncThunk(
    'Quote/GetQuote',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Quote`, data: userdata, headers: authHeader });
            // console.log('GetQuote result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetQuoteId = createAsyncThunk(
    'Quote/GetQuoteId',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Quote/QuoteId`, params: userdata, data: userdata, headers: authHeader });
            // console.log('GetQuoteId result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const CreateQuoteOrder = createAsyncThunk(
    'Quote/CreateQuoteOrder',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader('multipart/form-data');
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Quote/createNewOrder`, data: userdata, headers: authHeader });
            // console.log('CreateQuoteOrder result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const SearchMedication = createAsyncThunk(
    'Quote/SearchMedication',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Quote/SearchMedication`, params: userdata, data: userdata, headers: authHeader });
            // console.log('SearchMedication result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const SearchInternalMedication = createAsyncThunk(
    'Quote/SearchInternalMedication',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Quote/SearchInternalMedication`, params: userdata, data: userdata, headers: authHeader });
            // console.log('SearchInternalMedication result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetMedicationDetails = createAsyncThunk(
    'Quote/GetMedicationDetails',
    async (params, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Quote/GetMedicationDetails`, params: params, data: params, headers: authHeader });
            // console.log('GetMedicationDetails result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const SelectQuoteById = createAsyncThunk(
    'Quote/SelectQuoteById',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Quote/SelectQuoteById`, params: userdata, data: userdata, headers: authHeader });
            // console.log('SelectQuoteById result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetCompoundingCreateList = createAsyncThunk(
    'CompoudingQuote/GetCompoundingCreateList',
    async (_userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'GET', url: `${CONFIG.API_ENDPOINT}CompoudingQuote/GetCompoundingCreateList`, headers: authHeader });
            // console.log('GetCompoundingCreateList result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetCompoundingSavedList = createAsyncThunk(
    'CompoudingQuote/GetCompoundingSavedList',
    async (params, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'GET', url: `${CONFIG.API_ENDPOINT}CompoudingQuote/GetCompoundingSavedList`, params: params, headers: authHeader });
            // console.log('GetCompoundingSavedList result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetCompoundingSavedListPx = createAsyncThunk(
    'CompoudingQuote/GetCompoundingSavedListPx',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'GET', url: `${CONFIG.API_ENDPOINT}CompoudingQuote/GetCompoundingSavedListPx`, params: postdata, data: postdata, headers: authHeader });
            // console.log('GetCompoundingSavedListPx result.data >>', result.data);
            if (result.data.success) {
                return result.data.result;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const CreateCompoundingList = createAsyncThunk(
    'CompoudingQuote/CreateCompoundingList',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}CompoudingQuote/CreateCompoundingList`, data: postdata, headers: authHeader });
            // console.log('CreateCompoundingList result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetCompoundingList = createAsyncThunk(
    'CompoudingQuote/GetCompoundingList',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}CompoudingQuote/GetCompoundingList`, data: postdata, headers: authHeader });
            // console.log('GetCompoundingList result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const UpdateCompounding = createAsyncThunk(
    'CompoudingQuote/UpdateCompounding',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}CompoudingQuote/UpdateCompounding`, data: postdata, headers: authHeader });
            // console.log('UpdateCompounding result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const ImportRxOneNutritionalQuote = createAsyncThunk(
    'CompoudingQuote/ImportRxOneNutritionalQuote',
    async (_postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'GET', url: `${CONFIG.API_ENDPOINT}CompoudingQuote/ImportRxOneNutritionalQuote`, headers: authHeader });
            // console.log('ImportRxOneNutritionalQuote result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const DeleteCompoundingList = createAsyncThunk(
    'CompoudingQuote/DeleteCompoundingList',
    async (params, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'DELETE', url: `${CONFIG.API_ENDPOINT}CompoudingQuote/DeleteCompoundingList`, params: params, headers: authHeader });
            // console.log('DeleteCompoundingList result.data >>', result.data);
            if (result.data.success) {
                return params;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const QuoteSlice = createSlice({
    name: 'Quote',
    initialState: {
        Quotedata: [],
        Quoteproductdata: [],
        Quoteorderdata: [],
        SearchMedicationdata: [],
        SearchInternalMedicationdata: [],
        patientCompoundingQuoteListData: [],
        SelectQuotedata: {},
        CompoundingCreateList: {},
        CompoundingSavedList: [],
        CompoundingData: {},
        MedicationDetails: {},
        //---
        isCreateCompoundinglist: false,
        isDeleteCompoundingList: false,
        isUpdateCompounding: false,
        isImportrxoneNutritionalquote: false,
        isCreateOrder: false,
        //---
        isFetchingOBJ: {},
        isFetching: false,
        isError: false,
        errorMessage: '',
    },
    reducers: {
        updateState: (state, { payload }) => {
            state.isError = payload.isError !== undefined ? payload.isError : state.isError;
            state.errorMessage = payload.errorMessage !== undefined ? payload.errorMessage : state.errorMessage;
            //---
            state.isCreateOrder = payload.isCreateOrder !== undefined ? payload.isCreateOrder : state.isCreateOrder;
            state.SelectQuotedata = payload.SelectQuotedata !== undefined ? payload.SelectQuotedata : state.SelectQuotedata;
            state.isCreateCompoundinglist = payload.isCreateCompoundinglist !== undefined ? payload.isCreateCompoundinglist : state.isCreateCompoundinglist;
            state.isDeleteCompoundingList = payload.isDeleteCompoundingList !== undefined ? payload.isDeleteCompoundingList : state.isDeleteCompoundingList;
            state.isUpdateCompounding = payload.isUpdateCompounding !== undefined ? payload.isUpdateCompounding : state.isUpdateCompounding;
            state.isImportrxoneNutritionalquote = payload.isImportrxoneNutritionalquote !== undefined ? payload.isImportrxoneNutritionalquote : state.isImportrxoneNutritionalquote;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(GetQuote.fulfilled, (state, { payload }) => {
            try {
                state.Quotedata = payload;
                state.isFetchingOBJ.GetQuote = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetQuote.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetQuote.rejected, (state, { payload }) => {
            try {
                state.Quotedata = [];
                state.isFetchingOBJ.GetQuote = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetQuote.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetQuote.pending, (state) => {
            state.isFetchingOBJ.GetQuote = true;
        })
        //----
        builder.addCase(GetQuoteId.fulfilled, (state, { payload }) => {
            try {
                state.Quoteproductdata = payload;
                state.isFetching = false;
                state.isFetchingOBJ.GetQuoteId = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetQuoteId.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetQuoteId.rejected, (state, { payload }) => {
            try {
                state.Quoteproductdata = [];
                state.isFetching = false;
                state.isFetchingOBJ.GetQuoteId = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetQuoteId.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetQuoteId.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetQuoteId = true;
        })
        //----
        builder.addCase(CreateQuoteOrder.fulfilled, (state, { payload }) => {
            try {
                state.Quoteorderdata = payload;
                state.isCreateOrder = true;
                state.isFetching = false;
                state.isFetchingOBJ.CreateQuoteOrder = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[CreateQuoteOrder.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(CreateQuoteOrder.rejected, (state, { payload }) => {
            try {
                state.Quoteorderdata = [];
                state.isCreateOrder = false;
                state.isFetching = false;
                state.isFetchingOBJ.CreateQuoteOrder = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[CreateQuoteOrder.rejected] try catch error >>', error);
            }
        })
        builder.addCase(CreateQuoteOrder.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.CreateQuoteOrder = true;
        })
        //----
        builder.addCase(SearchMedication.fulfilled, (state, { payload }) => {
            try {
                state.SearchMedicationdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.SearchMedication = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[SearchMedication.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(SearchMedication.rejected, (state, { payload }) => {
            try {
                state.SearchMedicationdata = [];
                state.isFetching = false;
                state.isFetchingOBJ.SearchMedication = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[SearchMedication.rejected] try catch error >>', error);
            }
        })
        builder.addCase(SearchMedication.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.SearchMedication = true;
        })
        //----
        builder.addCase(SearchInternalMedication.fulfilled, (state, { payload }) => {
            try {
                state.SearchInternalMedicationdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.SearchInternalMedication = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[SearchInternalMedication.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(SearchInternalMedication.rejected, (state, { payload }) => {
            try {
                state.SearchInternalMedicationdata = [];
                state.isFetching = false;
                state.isFetchingOBJ.SearchInternalMedication = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[SearchInternalMedication.rejected] try catch error >>', error);
            }
        })
        builder.addCase(SearchInternalMedication.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.SearchInternalMedication = true;
        })
        //----
        builder.addCase(GetMedicationDetails.fulfilled, (state, { payload }) => {
            try {
                state.MedicationDetails = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.GetMedicationDetails = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetMedicationDetails.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetMedicationDetails.rejected, (state, { payload }) => {
            try {
                state.MedicationDetails = {};
                state.isFetching = false;
                state.isFetchingOBJ.GetMedicationDetails = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetMedicationDetails.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetMedicationDetails.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetMedicationDetails = true;
        })
        //----
        builder.addCase(SelectQuoteById.fulfilled, (state, { payload }) => {
            try {
                state.SelectQuotedata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.SelectQuoteById = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[SelectQuoteById.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(SelectQuoteById.rejected, (state, { payload }) => {
            try {
                state.SelectQuotedata = {};
                state.isFetching = false;
                state.isFetchingOBJ.SelectQuoteById = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[SelectQuoteById.rejected] try catch error >>', error);
            }
        })
        builder.addCase(SelectQuoteById.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.SelectQuoteById = true;
        })
        //----
        builder.addCase(GetCompoundingCreateList.fulfilled, (state, { payload }) => {
            try {
                state.CompoundingCreateList = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.GetCompoundingCreateList = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetCompoundingCreateList.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetCompoundingCreateList.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.GetCompoundingCreateList = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetCompoundingCreateList.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetCompoundingCreateList.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetCompoundingCreateList = true;
        })
        //----
        builder.addCase(GetCompoundingSavedList.fulfilled, (state, { payload }) => {
            try {
                state.CompoundingSavedList = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.GetCompoundingSavedList = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetCompoundingSavedList.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetCompoundingSavedList.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.GetCompoundingSavedList = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetCompoundingSavedList.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetCompoundingSavedList.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetCompoundingSavedList = true;
        })
        //----
        builder.addCase(GetCompoundingSavedListPx.fulfilled, (state, { payload }) => {
            try {
                state.patientCompoundingQuoteListData = payload;
                state.isFetching = false;
                state.isFetchingOBJ.GetCompoundingSavedListPx = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetCompoundingSavedListPx.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetCompoundingSavedListPx.rejected, (state, { payload }) => {
            try {
                state.patientCompoundingQuoteListData = [];
                state.isFetching = false;
                state.isFetchingOBJ.GetCompoundingSavedListPx = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetCompoundingSavedListPx.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetCompoundingSavedListPx.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetCompoundingSavedListPx = true;
        })
        //----
        builder.addCase(CreateCompoundingList.fulfilled, (state, { payload }) => {
            try {
                state.isCreateCompoundinglist = true;
                state.isFetching = false;
                state.isFetchingOBJ.CreateCompoundingList = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[CreateCompoundingList.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(CreateCompoundingList.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.CreateCompoundingList = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[CreateCompoundingList.rejected] try catch error >>', error);
            }
        })
        builder.addCase(CreateCompoundingList.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.CreateCompoundingList = true;
        })
        //----
        builder.addCase(GetCompoundingList.fulfilled, (state, { payload }) => {
            try {
                state.CompoundingData = payload;
                state.isFetching = false;
                state.isFetchingOBJ.GetCompoundingList = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetCompoundingList.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetCompoundingList.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.GetCompoundingList = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetCompoundingList.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetCompoundingList.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetCompoundingList = true;
        })
        //----
        builder.addCase(UpdateCompounding.fulfilled, (state, { payload }) => {
            try {
                state.isUpdateCompounding = true;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateCompounding = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[UpdateCompounding.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(UpdateCompounding.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.UpdateCompounding = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[UpdateCompounding.rejected] try catch error >>', error);
            }
        })
        builder.addCase(UpdateCompounding.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.UpdateCompounding = true;
        })
        //----
        builder.addCase(ImportRxOneNutritionalQuote.fulfilled, (state, { payload }) => {
            try {
                state.isImportrxoneNutritionalquote = true;
                state.isFetching = false;
                state.isFetchingOBJ.ImportRxOneNutritionalQuote = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[ImportRxOneNutritionalQuote.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(ImportRxOneNutritionalQuote.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.ImportRxOneNutritionalQuote = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[ImportRxOneNutritionalQuote.rejected] try catch error >>', error);
            }
        })
        builder.addCase(ImportRxOneNutritionalQuote.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.ImportRxOneNutritionalQuote = true;
        })
        //----
        builder.addCase(DeleteCompoundingList.fulfilled, (state, { payload }) => {
            try {
                state.CompoundingSavedList = state.CompoundingSavedList?.filter((item) => item.ID != payload.quoteID);
                state.isDeleteCompoundingList = true;
                state.isFetching = false;
                state.isFetchingOBJ.DeleteCompoundingList = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[DeleteCompoundingList.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(DeleteCompoundingList.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.DeleteCompoundingList = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[DeleteCompoundingList.rejected] try catch error >>', error);
            }
        })
        builder.addCase(DeleteCompoundingList.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.DeleteCompoundingList = true;
        })
    }
});
export const { updateState } = QuoteSlice.actions;
export const QuoteSelector = (state) => state.ncc.Quote;