import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../axios';
import CONFIG from '../../Utils/config';
import Service from '../../Utils/service';
//---
const extractErrorMessage = (error) =>
    error?.response?.data?.Message ||
    error?.response?.data ||
    error?.response ||
    error?.message;
const extractErrorMessageRejected = (payload) =>
    payload?.response?.data?.Message?.toString() ||
    payload?.response?.data?.toString() ||
    payload?.response?.toString() ||
    payload?.err?.toString() ||
    payload?.data?.Message?.toString() ||
    payload?.data?.message?.toString() ||
    payload?.data?.toString() ||
    payload?.result?.toString() ||
    payload?.Message?.toString() ||
    payload?.message?.toString() ||
    payload?.error?.toString() ||
    "API Response Invalid.";
//---
export const GetProfile = createAsyncThunk(
    'Profile/GetProfile',
    async (_userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Profile/GetProfile`, headers: authHeader });
            // console.log('GetProfile result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                await localStorage.removeItem('ncc_Token');
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const UpdateProfile = createAsyncThunk(
    'Profile/UpdateProfile',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}Profile/UpdateProfile`, data: userdata, headers: authHeader });
            // console.log('UpdateProfile result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const UpdatePassword = createAsyncThunk(
    'Profile/UpdatePassword',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}Profile/UpdatePassword`, data: userdata, headers: authHeader });
            // console.log('UpdatePassword result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
//======================
export const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        profiledata: {},
        isProfileupdate: false,
        isProfilepasswordupdate: false,
        isFetchingOBJ: {},
        isFetching: false,
        isError: false,
        errorMessage: '',
    },
    reducers: {
        updateState: (state, { payload }) => {
            state.isProfileupdate = payload.isProfileupdate !== undefined ? payload.isProfileupdate : state.isProfileupdate;
            state.isProfilepasswordupdate = payload.isProfilepasswordupdate !== undefined ? payload.isProfilepasswordupdate : state.isProfilepasswordupdate;
            state.isError = payload.isError !== undefined ? payload.isError : state.isError;
            state.errorMessage = payload.errorMessage !== undefined ? payload.errorMessage : state.errorMessage;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(GetProfile.fulfilled, (state, { payload }) => {
            try {
                state.profiledata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.GetProfile = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetProfile.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetProfile.rejected, (state, { payload }) => {
            try {
                state.profiledata = {};
                state.isFetching = false;
                state.isFetchingOBJ.GetProfile = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetProfile.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetProfile.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetProfile = true;
        })
        //----
        builder.addCase(UpdateProfile.fulfilled, (state, { payload }) => {
            try {
                state.isProfileupdate = true;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateProfile = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[UpdateProfile.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(UpdateProfile.rejected, (state, { payload }) => {
            try {
                state.profiledata = [];
                state.isProfileupdate = false;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateProfile = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[UpdateProfile.rejected] try catch error >>', error);
            }
        })
        builder.addCase(UpdateProfile.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.UpdateProfile = true;
        })
        //----
        builder.addCase(UpdatePassword.fulfilled, (state, { payload }) => {
            try {
                state.profiledata = payload.result;
                state.isProfilepasswordupdate = true;
                state.isFetching = false;
                state.isFetchingOBJ.UpdatePassword = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[UpdatePassword.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(UpdatePassword.rejected, (state, { payload }) => {
            try {
                state.profiledata = [];
                state.isProfilepasswordupdate = false;
                state.isFetching = false;
                state.isFetchingOBJ.UpdatePassword = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[UpdatePassword.rejected] try catch error >>', error);
            }
        })
        builder.addCase(UpdatePassword.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.UpdatePassword = true;
        })
    }
});
//======================
export const { updateState } = profileSlice.actions;
export const profileSelector = (state) => state.ncc.profile;
