import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
//---
axios.interceptors.request.use(function (config) {
    // console.log('interceptors.request config::',config);
    const access_token = sessionStorage.getItem('access_token');
    if (config.url !== "/auth" && !!access_token) {
        config.headers['Authori-zation'] = access_token
    }
    return config;
}, function (error) {
    // console.error('interceptors.request error::',error);
    return Promise.reject(error);
});
axios.interceptors.response.use(function (response) {
    // console.log('interceptors.request response::',response);
    return response;
}, function (error) {
    if(error?.response){
        console.error(`interceptors.response status:${error.response.status},statusText:${error.response.statusText}:: detail error.response::`, error.response);
    } else {
        console.error('interceptors.response error.message::', error.message);
    }
    return Promise.reject(error);
});
// ----
/* https://cors-anywhere.herokuapp.com/corsdemo  first this link open browser allow button=[Request temporary access to the demo server] click and access after working */
const corsanywhereURL = "https://cors-anywhere.herokuapp.com/";
const tailoredURL = "https://api.tailoredscript.com.au";
const API_BASE_URL = corsanywhereURL + tailoredURL;
// const API_BASE_URL = tailoredURL;
//-----
const extractErrorMessage = (error) =>
    error?.response?.data?.Message ||
    error?.response?.data ||
    error?.response ||
    error?.message;
const extractErrorMessageRejected = (payload) =>
    payload?.response?.data?.Message?.toString() ||
    payload?.response?.data?.toString() ||
    payload?.response?.toString() ||
    payload?.err?.toString() ||
    payload?.data?.Message?.toString() ||
    payload?.data?.message?.toString() ||
    payload?.data?.toString() ||
    payload?.result?.toString() ||
    payload?.Message?.toString() ||
    payload?.message?.toString() ||
    payload?.error?.toString() ||
    "API Response Invalid.";
    //-----
export const Authentication = createAsyncThunk(
    'nccTSI/Authentication',
    async (data, thunkAPI) => {
        try {
            let Response = await axios({ method: "POST", baseURL: API_BASE_URL, url: `/auth`, data: data });
            // console.log('Authentication Response.data >>', Response.data);
            if (Response.status == 200) {
                if (Response?.data?.data?.access_token) {
                    sessionStorage.setItem('access_token', Response.data.data.access_token)
                }
                return Response.data.data;
            }
            else {
                sessionStorage.removeItem('access_token');
                return thunkAPI.rejectWithValue({ error: Response.data.msg });
            }
        } catch (error) {
            sessionStorage.removeItem('access_token');
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetMinidoses = createAsyncThunk(
    'nccTSI/GetMinidoses',
    async (_data, thunkAPI) => {
        try {
            let Response = await axios({ method: "GET", baseURL: API_BASE_URL, url: `/microchipList` });
            // console.log('GetMinidoses Response.data >>', Response.data);
            if (Response.status == 200) {
                return Response.data.data;
            }
            else {
                return thunkAPI.rejectWithValue({ error: Response.data.msg });
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetProtocolFormulation = createAsyncThunk(
    'nccTSI/GetProtocolFormulation',
    async (params, thunkAPI) => {
        try {
            let Response = await axios({ method: "GET", baseURL: API_BASE_URL, url: `/protocolList`, params: params });
            // console.log('GetProtocolFormulation Response.data >>', Response.data);
            if (Response.status == 200) {
                return Response.data?.data?.data || [];
            }
            else {
                return thunkAPI.rejectWithValue({ error: Response.data.msg });
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const orderAdd = createAsyncThunk(
    'nccTSI/order/add',
    async (data, thunkAPI) => {
        try {
            let Response = await axios({ method: "POST", baseURL: API_BASE_URL, url: `/add/${data.Order_sn}`, data: data });
            // console.log('orderAdd Response.data >>', Response.data);
            if (Response.status == 200) {
                return Response.data.data;
            }
            else {
                return thunkAPI.rejectWithValue({ error: Response.data.msg });
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const orderTake = createAsyncThunk(
    'nccTSI/order/take',
    async (data, thunkAPI) => {
        try {
            let Response = await axios({ method: "POST", baseURL: API_BASE_URL, url: `/takeOrder`, data: data });
            // console.log('orderTake Response.data >>', Response.data);
            if (Response.status == 200) {
                return Response.data.data;
            }
            else {
                return thunkAPI.rejectWithValue({ error: Response.data.msg });
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const orderUpdate = createAsyncThunk(
    'nccTSI/order/update',
    async (data, thunkAPI) => {
        try {
            let Response = await axios({ method: "POST", baseURL: API_BASE_URL, url: `/updateOrder`, data: data });
            // console.log('orderUpdate Response.data >>', Response.data);
            if (Response.status == 200) {
                return Response.data;
            }
            else {
                return thunkAPI.rejectWithValue({ error: Response.data.msg });
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const getSupplementFacts = createAsyncThunk(
    'nccTSI/order/getSupplementFacts',
    async (data, thunkAPI) => {
        try {
            let Response = await axios({ method: "POST", baseURL: API_BASE_URL, url: `/getSupplementFacts`, data: data });
            // console.log('getSupplementFacts Response.data >>', Response.data);
            if (Response.status == 200) {
                return Response.data.data;
            }
            else {
                return thunkAPI.rejectWithValue({ error: Response.data.msg });
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const setPoOrder = createAsyncThunk(
    'nccTSI/order/setPoOrder',
    async (data, thunkAPI) => {
        try {
            let Response = await axios({ method: "POST", baseURL: API_BASE_URL, url: `/setPoOrder`, data: data });
            // console.log('setPoOrder Response.data >>', Response.data);
            if (Response.status == 200) {
                return Response.data.data;
            }
            else {
                return thunkAPI.rejectWithValue({ error: Response.data.msg });
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const getOrderSupplementFacts = createAsyncThunk(
    'nccTSI/order/getOrderSupplementFacts',
    async (data, thunkAPI) => {
        try {
            let Response = await axios({ method: "POST", baseURL: API_BASE_URL, url: `/getOrderSupplementFacts`, data: data });
            // console.log('getOrderSupplementFacts Response.data >>', Response.data);
            if (Response.status == 200) {
                return Response.data.data;
            }
            else {
                return thunkAPI.rejectWithValue({ error: Response.data.msg });
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
//======================
export const nccTSISlice = createSlice({
    name: 'nccTSI',
    initialState: {
        authdata: {
            access_token: sessionStorage.getItem('access_token')
        },
        miniDoseListData: {},
        protocolFormulationListData: [],
        orderTakeData: {},
        SupplementFactsData: {},
        poOrderData: {},
        orderSupplementFactsData: [],
        //---
        isFetchingOBJ: {},
        isError: false,
        errorMessage: '',
    },
    reducers: {
        updateState: (state, { payload }) => {
            if (payload.isError !== undefined) { state.isError = payload.isError }
            if (payload.errorMessage !== undefined) { state.errorMessage = payload.errorMessage }
            if (payload.SupplementFactsData !== undefined) { state.SupplementFactsData = {} }
            //---
            return state;
        },
    },
    extraReducers: (builder) => {
        //-----
        builder.addCase(Authentication.fulfilled, (state, { payload }) => {
            try {
                state.authdata = payload || {};
                state.isFetchingOBJ['Authentication'] = false;
                state.isError = false;
                state.errorMessage = '';
                return state;
            } catch (error) {
                console.error('[Authentication.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(Authentication.rejected, (state, { payload }) => {
            try {
                state.authdata =  {};
                state.isFetchingOBJ['Authentication'] = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[Authentication.rejected] try catch error >>', error);
            }
        })
        builder.addCase(Authentication.pending, (state) => {
            state.isFetchingOBJ['Authentication'] = true;
        })
        //----
        builder.addCase(GetMinidoses.fulfilled, (state, { payload }) => {
            try {
                state.miniDoseListData = payload || {};
                state.isFetchingOBJ['GetMinidoses'] = false;
                state.isError = false;
                state.errorMessage = '';
                return state;
            } catch (error) {
                console.error('[GetMinidoses.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetMinidoses.rejected, (state, { payload }) => {
            try {
                state.isFetchingOBJ['GetMinidoses'] = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetMinidoses.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetMinidoses.pending, (state) => {
            state.isFetchingOBJ['GetMinidoses'] = true;
        })
        //----
        builder.addCase(GetProtocolFormulation.fulfilled, (state, { payload }) => {
            try {
                state.protocolFormulationListData = payload || [];
                state.isFetchingOBJ['GetProtocolFormulation'] = false;
                state.isError = false;
                state.errorMessage = '';
                return state;
            } catch (error) {
                console.error('[GetProtocolFormulation.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetProtocolFormulation.rejected, (state, { payload }) => {
            try {
                state.isFetchingOBJ['GetProtocolFormulation'] = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetProtocolFormulation.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetProtocolFormulation.pending, (state) => {
            state.isFetchingOBJ['GetProtocolFormulation'] = true;
        })
        //----
        builder.addCase(orderAdd.fulfilled, (state, { payload }) => {
            try {
                state.isFetchingOBJ['orderAdd'] = false;
                state.isError = false;
                state.errorMessage = '';
                return state;
            } catch (error) {
                console.error('[orderAdd.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(orderAdd.rejected, (state, { payload }) => {
            try {
                state.isFetchingOBJ['orderAdd'] = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[orderAdd.rejected] try catch error >>', error);
            }
        })
        builder.addCase(orderAdd.pending, (state) => {
            state.isFetchingOBJ['orderAdd'] = true;
        })
        //----
        builder.addCase(orderTake.fulfilled, (state, { payload }) => {
            try {
                state.orderTakeData = payload || {};
                state.isFetchingOBJ['orderTake'] = false;
                state.isError = false;
                state.errorMessage = '';
                return state;
            } catch (error) {
                console.error('[orderTake.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(orderTake.rejected, (state, { payload }) => {
            try {
                state.isFetchingOBJ['orderTake'] = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[orderTake.rejected] try catch error >>', error);
            }
        })
        builder.addCase(orderTake.pending, (state) => {
            state.isFetchingOBJ['orderTake'] = true;
        })
        //----
        builder.addCase(orderUpdate.fulfilled, (state, { payload }) => {
            try {
                state.isFetchingOBJ['orderUpdate'] = false;
                state.isError = false;
                state.errorMessage = '';
                return state;
            } catch (error) {
                console.error('[orderUpdate.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(orderUpdate.rejected, (state, { payload }) => {
            try {
                state.isFetchingOBJ['orderUpdate'] = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[orderUpdate.rejected] try catch error >>', error);
            }
        })
        builder.addCase(orderUpdate.pending, (state) => {
            state.isFetchingOBJ['orderUpdate'] = true;
        })
        //----
        builder.addCase(getSupplementFacts.fulfilled, (state, { payload }) => {
            try {
                state.SupplementFactsData = payload||{};
                state.isFetchingOBJ['getSupplementFacts'] = false;
                state.isError = false;
                state.errorMessage = '';
                return state;
            } catch (error) {
                console.error('[getSupplementFacts.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(getSupplementFacts.rejected, (state, { payload }) => {
            try {
                state.isFetchingOBJ['getSupplementFacts'] = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[getSupplementFacts.rejected] try catch error >>', error);
            }
        })
        builder.addCase(getSupplementFacts.pending, (state) => {
            state.isFetchingOBJ['getSupplementFacts'] = true;
        })
        //----
        builder.addCase(setPoOrder.fulfilled, (state, { payload }) => {
            try {
                state.poOrderData = payload||{};
                state.isFetchingOBJ['setPoOrder'] = false;
                state.isError = false;
                state.errorMessage = '';
                return state;
            } catch (error) {
                console.error('[setPoOrder.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(setPoOrder.rejected, (state, { payload }) => {
            try {
                state.isFetchingOBJ['setPoOrder'] = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[setPoOrder.rejected] try catch error >>', error);
            }
        })
        builder.addCase(setPoOrder.pending, (state) => {
            state.isFetchingOBJ['setPoOrder'] = true;
        })
        //----
        builder.addCase(getOrderSupplementFacts.fulfilled, (state, { payload }) => {
            try {
                state.orderSupplementFactsData = payload||[];
                state.isFetchingOBJ['getOrderSupplementFacts'] = false;
                state.isError = false;
                state.errorMessage = '';
                return state;
            } catch (error) {
                console.error('[getOrderSupplementFacts.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(getOrderSupplementFacts.rejected, (state, { payload }) => {
            try {
                state.isFetchingOBJ['getOrderSupplementFacts'] = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[getOrderSupplementFacts.rejected] try catch error >>', error);
            }
        })
        builder.addCase(getOrderSupplementFacts.pending, (state) => {
            state.isFetchingOBJ['getOrderSupplementFacts'] = true;
        })
        //---
    }
});
//======================
export const { updateState } = nccTSISlice.actions;
export const nccTSISelector = (state) => state.ncc.nccTSI;
