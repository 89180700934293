import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import CONFIG from '../../Utils/config';
//---
const extractErrorMessage = (error) =>
    error?.response?.data?.Message ||
    error?.response?.data ||
    error?.response ||
    error?.message;
const extractErrorMessageRejected = (payload) =>
    payload?.response?.data?.Message?.toString() ||
    payload?.response?.data?.toString() ||
    payload?.response?.toString() ||
    payload?.err?.toString() ||
    payload?.data?.Message?.toString() ||
    payload?.data?.message?.toString() ||
    payload?.data?.toString() ||
    payload?.result?.toString() ||
    payload?.Message?.toString() ||
    payload?.message?.toString() ||
    payload?.error?.toString() ||
    "API Response Invalid.";
//---
export const loginUser = createAsyncThunk(
    'login/login',
    async (userdata, thunkAPI) => {
        try {
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}login/login`, data: userdata, headers: CONFIG.API_HEADER.headers });
            // console.log('login result.data >>', result);
            if (result.data.success) {
                await localStorage.setItem('ncc_Token', result.data.token);
                return result.data.result;
            }
            else {
                await localStorage.removeItem('ncc_Token');
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
             return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const patientloginUser = createAsyncThunk(
    'login/patientloginUser',
    async (userdata, thunkAPI) => {
        try {
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}login/login`, data: userdata, headers: CONFIG.API_HEADER.headers });
            // console.log('patientloginUser result.data >>', result);
            if (result.data.success) {
                await localStorage.setItem('ncc_Token', result.data.token);
                return result.data.result;
            }
            else {
                await localStorage.removeItem('ncc_Token');
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
             return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const UpdatePassword = createAsyncThunk(
    'login/UpdatePassword',
    async (userdata, thunkAPI) => {
        try {
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}login/UpdatePassword`, data: userdata, headers: CONFIG.API_HEADER.headers });
            // console.log('UpdatePassword result.data >>', result);
            if (result.data.success) {
                await localStorage.setItem('ncc_Token', result.data.token);
                return result.data.result;
            }
            else {
                await localStorage.removeItem('ncc_Token');
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
             return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const ForgotPasswordEmail = createAsyncThunk(
    'Profile/ForgotPasswordEmail',
    async (userdata, thunkAPI) => {
        try {
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Profile/ForgotPasswordEmail`, params: userdata, data: userdata, headers: CONFIG.API_HEADER.headers });
            // console.log('ForgotPasswordEmail result.data >>', result);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
             return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const UpdateForgotPassword = createAsyncThunk(
    'Profile/UpdateForgotPassword',
    async (userdata, thunkAPI) => {
        try {
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}Profile/UpdateForgotPassword`, data: userdata, headers: CONFIG.API_HEADER.headers });
            // console.log('UpdateForgotPassword result.data >>', result);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
             return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const SendOtp = createAsyncThunk(
    'login/SendOtp',
    async (userdata, thunkAPI) => {
        try {
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}login/SendOtp`, data: userdata, headers: CONFIG.API_HEADER.headers });
            // console.log('SendOtp result.data >>', result);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
             return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const InsertPatient = createAsyncThunk(
    'Patient/InsertPatient',
    async (userdata, thunkAPI) => {
        try {
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Patient/InsertPatient`, data: userdata, headers: CONFIG.API_HEADER.headers });
            // console.log('InsertPatient result.data >>', result);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
             return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
//======================
export const loginSlice = createSlice({
    name: 'login',
    initialState: {
        logindata: [],
        forgetpassworddata: {},
        updatedpassworddata: {},
        SendOtpdata: {},
        InsertPatientdata: {},
        isForget: false,
        isPasswordupdate: false,
        isSendOtp: false,
        ispatientadd: false,
        isLogin: false,
        isFetchingOBJ: {},
        isFetching: false,
        isError: false,
        errorMessage: '',
    },
    reducers: {
        updateState: (state, { payload }) => {
            state.logindata = payload.logindata !== undefined ? payload.logindata : state.logindata;
            state.isLogin = payload.isLogin !== undefined ? payload.isLogin : state.isLogin;
            state.isForget = payload.isForget !== undefined ? payload.isForget : state.isForget;
            state.isPasswordupdate = payload.isPasswordupdate !== undefined ? payload.isPasswordupdate : state.isPasswordupdate;
            state.isSendOtp = payload.isSendOtp !== undefined ? payload.isSendOtp : state.isSendOtp;
            state.ispatientadd = payload.ispatientadd !== undefined ? payload.ispatientadd : state.ispatientadd;
            state.isError = payload.isError !== undefined ? payload.isError : state.isError;
            state.errorMessage = payload.errorMessage !== undefined ? payload.errorMessage : state.errorMessage;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loginUser.fulfilled, (state, { payload }) => {
            try {
                state.logindata = payload;
                state.isFetching = false;
                state.isFetchingOBJ.loginUser = false;
                state.isError = false;
                state.isLogin = true;
                state.errorMessage = '';
            } catch (error) {
                console.error('[loginUser.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(loginUser.rejected, (state, { payload }) => {
            try {
                if (payload.result) {
                    state.logindata = payload.result;
                    state.isError = false;
                    state.isLogin = true;
                } else {
                    state.logindata = [];
                    state.isError = true;
                    state.isLogin = false;
                }
                state.isFetching = false;
                state.isFetchingOBJ.loginUser = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[loginUser.rejected] try catch error >>', error);
            }
        })
        builder.addCase(loginUser.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.loginUser = true;
        })
        //----
        builder.addCase(patientloginUser.fulfilled, (state, { payload }) => {
            try {
                state.logindata = payload;
                state.isFetching = false;
                state.isFetchingOBJ.patientloginUser = false;
                state.isError = false;
                state.isLogin = true;
                state.errorMessage = '';
            } catch (error) {
                console.error('[patientloginUser.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(patientloginUser.rejected, (state, { payload }) => {
            try {
                state.logindata = [];
                state.isError = true;
                state.isLogin = false;
                state.isFetching = false;
                state.isFetchingOBJ.patientloginUser = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[patientloginUser.rejected] try catch error >>', error);
            }
        })
        builder.addCase(patientloginUser.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.patientloginUser = true;
        })
        //----
        builder.addCase(UpdatePassword.fulfilled, (state, { payload }) => {
            try {
                state.logindata = payload;
                state.isFetching = false;
                state.isFetchingOBJ.UpdatePassword = false;
                state.isError = false;
                state.isLogin = true;
                state.errorMessage = '';
            } catch (error) {
                console.error('[UpdatePassword.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(UpdatePassword.rejected, (state, { payload }) => {
            try {
                state.logindata = [];
                state.isFetching = false;
                state.isFetchingOBJ.UpdatePassword = false;
                state.isError = true;
                state.isLogin = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[UpdatePassword.rejected] try catch error >>', error);
            }
        })
        builder.addCase(UpdatePassword.pending, (state) => {
            state.isFetching = false;
            state.isFetchingOBJ.UpdatePassword = false;
        })
        //----
        builder.addCase(ForgotPasswordEmail.fulfilled, (state, { payload }) => {
            try {
                state.forgetpassworddata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.ForgotPasswordEmail = false;
                state.isError = false;
                state.isForget = true;
                state.errorMessage = '';
            } catch (error) {
                console.error('[ForgotPasswordEmail.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(ForgotPasswordEmail.rejected, (state, { payload }) => {
            try {
                state.forgetpassworddata = {};
                state.isFetching = false;
                state.isFetchingOBJ.ForgotPasswordEmail = false;
                state.isError = true;
                state.isForget = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[ForgotPasswordEmail.rejected] try catch error >>', error);
            }
        })
        builder.addCase(ForgotPasswordEmail.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.ForgotPasswordEmail = true;
        })
        //----
        builder.addCase(UpdateForgotPassword.fulfilled, (state, { payload }) => {
            try {
                state.updatedpassworddata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateForgotPassword = false;
                state.isError = false;
                state.isPasswordupdate = true;
                state.errorMessage = '';
            } catch (error) {
                console.error('[UpdateForgotPassword.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(UpdateForgotPassword.rejected, (state, { payload }) => {
            try {
                state.updatedpassworddata = {};
                state.isFetching = false;
                state.isFetchingOBJ.UpdateForgotPassword = false;
                state.isError = true;
                state.isPasswordupdate = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[UpdateForgotPassword.rejected] try catch error >>', error);
            }
        })
        builder.addCase(UpdateForgotPassword.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.UpdateForgotPassword = true;
        })
        //----
        builder.addCase(SendOtp.fulfilled, (state, { payload }) => {
            try {
                state.SendOtpdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.SendOtp = false;
                state.isError = false;
                state.isSendOtp = true;
                state.errorMessage = '';
            } catch (error) {
                console.error('[SendOtp.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(SendOtp.rejected, (state, { payload }) => {
            try {
                state.SendOtpdata = {};
                state.isFetching = false;
                state.isFetchingOBJ.SendOtp = false;
                state.isError = true;
                state.isSendOtp = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[SendOtp.rejected] try catch error >>', error);
            }
        })
        builder.addCase(SendOtp.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.SendOtp = true;
        })
        //----
        builder.addCase(InsertPatient.fulfilled, (state, { payload }) => {
            try {
                state.InsertPatientdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.InsertPatient = false;
                state.isError = false;
                state.ispatientadd = true;
                state.errorMessage = '';
            } catch (error) {
                console.error('[InsertPatient.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(InsertPatient.rejected, (state, { payload }) => {
            try {
                state.InsertPatientdata = {};
                state.isFetching = false;
                state.isFetchingOBJ.InsertPatient = false;
                state.isError = true;
                state.ispatientadd = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[InsertPatient.rejected] try catch error >>', error);
            }
        })
        builder.addCase(InsertPatient.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.InsertPatient = true;
        })
    }
});
//======================
export const { updateState } = loginSlice.actions;
export const loginSelector = (state) => state.ncc.login;
