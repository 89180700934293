//---
// const URL = "http://192.168.29.101:1109";  // -- local pc bhavesh bhai development api
const URL = "https://saasbe.customcompounding.com.au";  // -- production api
//----------
const API_ENDPOINT = URL + "/v1/";
//---------
const API_HEADER = { headers: { 'Authorization': null, 'Content-Type': 'application/json' } }
const SITE_NAME = 'NCC'
let DEFAULTTHEME = "theme-light theme-Default";
let SELECTEDTHEME = null;
const ADDRESSAPIKEY = "AIzaSyBcDf-XL9xE8KW1yaly2-BMs9ONxyjIQLs";
//----
const EMAILTEMPLATEFIELD = [
    { label: 'Customer Name', value: 'CustomerName' },
    { label: 'User Name', value: 'UserName' },
    { label: 'Email Address', value: 'Email' },
    { label: 'Reset Password Link', value: 'ResetPasswordLink' },
    { label: 'Order Id', value: 'OrderId' },
    { label: 'Phone', value: 'Phone' },
    { label: 'Client ID', value: 'ClientID' },
    { label: 'Payment Link', value: 'PaymentLink' },
    { label: 'Delay Reason', value: 'DelayReason' }
];
const SUPPORTEMAILTEMPLATEFIELD = [
    { label: 'Email', value: 'Email' },
    { label: 'TicketID', value: 'TicketID' },
    { label: 'Subject', value: 'Subject' },
    { label: 'Priority', value: 'Priority' },
    { label: 'Status', value: 'Status' }
];
const STATUS = [
    { label: "Pass", value: "1" },
    { label: "Fail", value: "2" },
    { label: "Confirm", value: "3" }
]
const DROPDOWN_COMPOUNDITEMS = [
    { label: "1", value: '1' },
    { label: "2", value: '2' },
    { label: "3", value: '3' },
    { label: "4", value: '4' },
    { label: "5", value: '5' },
];
//------
const CONFIG = { URL, API_ENDPOINT, API_HEADER, SITE_NAME, DEFAULTTHEME, SELECTEDTHEME, EMAILTEMPLATEFIELD, SUPPORTEMAILTEMPLATEFIELD, ADDRESSAPIKEY, STATUS, DROPDOWN_COMPOUNDITEMS };
export default CONFIG;