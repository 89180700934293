import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../axios';
import CONFIG from '../../Utils/config';
import Service from '../../Utils/service';
import _ from 'lodash';
//---
const extractErrorMessage = (error) =>
    error?.response?.data?.Message ||
    error?.response?.data ||
    error?.response ||
    error?.message;
const extractErrorMessageRejected = (payload) =>
    payload?.response?.data?.Message?.toString() ||
    payload?.response?.data?.toString() ||
    payload?.response?.toString() ||
    payload?.err?.toString() ||
    payload?.data?.Message?.toString() ||
    payload?.data?.message?.toString() ||
    payload?.data?.toString() ||
    payload?.result?.toString() ||
    payload?.Message?.toString() ||
    payload?.message?.toString() ||
    payload?.error?.toString() ||
    "API Response Invalid.";
//---
export const Getallroles = createAsyncThunk(
    'allExternalRoles/Getallroles',
    async (_userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'GET', url: `${CONFIG.API_ENDPOINT}allExternalRoles`, headers: authHeader });
            // console.log('Getallroles result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetTeamRoles = createAsyncThunk(
    'TeamRoles/GetTeamRoles',
    async (_userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'GET', url: `${CONFIG.API_ENDPOINT}TeamRoles`, headers: authHeader });
            // console.log('GetTeamRoles result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetallBrandNames = createAsyncThunk(
    'allBrandNames/GetallBrandNames',
    async (_userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'GET', url: `${CONFIG.API_ENDPOINT}allBrandNames`, headers: authHeader });
            // console.log('GetallBrandNames result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
//====================== allfile
export const Getallfile = createAsyncThunk(
    'allFiles/allfile',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}allFiles`, data: userdata, headers: authHeader });
            // console.log('allfile result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const Addallfile = createAsyncThunk(
    'allFiles/add',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader('multipart/form-data');
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}allFiles/create`, data: postdata, headers: authHeader });
            // console.log('Addallfile result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data.err);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const Deleteallfile = createAsyncThunk(
    'allFiles/delete',
    async (param, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'DELETE', url: `${CONFIG.API_ENDPOINT}allFiles/fileID`, params: param, headers: authHeader });
            // console.log('Deleteallfile result.data >>', result.data);
            if (result.data.success) {
                result.postdata = param;
                return result.postdata;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
//============= Brand Management 
export const GetBrand = createAsyncThunk(
    'Brand/GetBrand',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Brand`, data: userdata, headers: authHeader });
            // console.log('GetBrand result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const AddBrand = createAsyncThunk(
    'Brand/add',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader('multipart/form-data');
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Brand/create`, data: postdata, headers: authHeader });
            // console.log('AddBrand result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const UpdateBrand = createAsyncThunk(
    'Brand/Update',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader('multipart/form-data');
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}Brand`, data: postdata, headers: authHeader });
            // console.log('UpdateBrand result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const DeleteBrand = createAsyncThunk(
    'Brand/delete',
    async (param, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'DELETE', url: `${CONFIG.API_ENDPOINT}Brand/ID`, params: param, headers: authHeader });
            // console.log('DeleteBrand result.data >>', result.data);
            if (result.data.success) {
                result.postdata = param;
                return result.postdata;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
//============= Externalpopup
export const GetExternalpopup = createAsyncThunk(
    'ExternalPopup/GetExternalpopup',
    async (_userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}ExternalPopup/getExternalpopup`, headers: authHeader });
            // console.log('Externalpopup result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const updateExternalpopup = createAsyncThunk(
    'ExternalPopup/updateExternalpopup',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}ExternalPopup`, data: postdata, headers: authHeader });
            // console.log('updateExternalpopup result.data >>', result.data);
            if (result.data.success) {
                return result.data.result;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
//============= External Users
export const GetExternalusers = createAsyncThunk(
    'ExternalUsers/GetExternalusers',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}ExternalUsers`, data: userdata, headers: authHeader });
            // console.log('GetExternalusers result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const AddExternalusers = createAsyncThunk(
    'ExternalUsers/addExternalusers',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}ExternalUsers/create`, data: postdata, headers: authHeader });
            // console.log('AddExternalusers result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const UpdateExternalusers = createAsyncThunk(
    'ExternalUsers/UpdateExternalusers',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}ExternalUsers`, data: postdata, headers: authHeader });
            // console.log('UpdateExternalusers result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const EnableExternalusers = createAsyncThunk(
    'ExternalUsers/EnableExternalusers',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}ExternalUsers/EnableDisableUser`, data: postdata, headers: authHeader });
            // console.log('EnableExternalusers result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const DeleteExternalusers = createAsyncThunk(
    'ExternalUsers/deleteExternalusers',
    async (param, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'DELETE', url: `${CONFIG.API_ENDPOINT}ExternalUsers`, params: param, headers: authHeader });
            // console.log('DeleteExternalusers result.data >>', result.data);
            if (result.data.success) {
                result.postdata = param;
                return result.postdata;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const userResetpassword = createAsyncThunk(
    'ExternalUsers/Resetpassword',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}ExternalUsers/updatePassword`, data: postdata, headers: authHeader });
            // console.log('Resetpassword result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const ExportExternalusers = createAsyncThunk(
    'ExternalUsers/ExportExternalusers',
    async (_postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}ExternalUsers/ExportExternalUsers`, headers: authHeader });
            // console.log('ExportExternalusers result.data >>', result.data);
            if (result.data.success) {
                return result.data.result;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
//============= Announcement
export const GetAnnouncement = createAsyncThunk(
    'announcements/GetAnnouncement',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}announcements`, data: userdata, headers: authHeader });
            // console.log('GetAnnouncement result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const AddAnnouncement = createAsyncThunk(
    'announcements/addAnnouncement',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}announcements/create`, data: postdata, headers: authHeader });
            // console.log('AddAnnouncement result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const UpdateAnnouncement = createAsyncThunk(
    'announcements/UpdateAnnouncement',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}announcements`, data: postdata, headers: authHeader });
            // console.log('UpdateAnnouncement result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const DeleteAnnouncement = createAsyncThunk(
    'announcements/deleteAnnouncement',
    async (param, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'DELETE', url: `${CONFIG.API_ENDPOINT}announcements`, params: param, headers: authHeader });
            // console.log('DeleteAnnouncement result.data >>', result.data);
            if (result.data.success) {
                result.postdata = param;
                return result.postdata;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
//============= Email Template
export const GetEmailtemp = createAsyncThunk(
    'template/GetEmailtemp',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}template`, data: userdata, headers: authHeader });
            // console.log('GetEmailtemp result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const UpdateEmailtemp = createAsyncThunk(
    'template/UpdateEmailtemp',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}template`, data: postdata, headers: authHeader });
            // console.log('UpdateEmailtemp result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
//============= ingredients
export const GetIngredient = createAsyncThunk(
    'ingredients/GetIngredient',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}ingredients`, data: userdata, headers: authHeader });
            // console.log('GetIngredient result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const AddIngredient = createAsyncThunk(
    'ingredients/addIngredient',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}ingredients/create`, data: postdata, headers: authHeader });
            // console.log('AddIngredient result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const UpdateIngredient = createAsyncThunk(
    'ingredients/UpdateIngredient',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}ingredients`, data: postdata, headers: authHeader });
            // console.log('UpdateIngredient result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const DeleteIngredient = createAsyncThunk(
    'ingredients/deleteIngredient',
    async (param, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'DELETE', url: `${CONFIG.API_ENDPOINT}ingredients`, params: param, headers: authHeader });
            // console.log('DeleteIngredient result.data >>', result.data);
            if (result.data.success) {
                result.postdata = param;
                return result.postdata;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
//============= Medicineinfo
export const GetMedicineinfo = createAsyncThunk(
    'MedicineFile/GetMedicineinfo',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}MedicineFile`, data: userdata, headers: authHeader });
            // console.log('GetMedicineinfo result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const AddMedicineinfo = createAsyncThunk(
    'MedicineFile/addMedicineinfo',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader('multipart/form-data');
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}MedicineFile/create`, data: postdata, headers: authHeader });
            // console.log('AddMedicineinfo result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const UpdateMedicineinfo = createAsyncThunk(
    'MedicineFile/UpdateMedicineinfo',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}MedicineFile/`, data: postdata, headers: authHeader });
            // console.log('UpdateMedicineinfo result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const DeleteMedicineinfo = createAsyncThunk(
    'MedicineFile/deleteMedicineinfo',
    async (param, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'DELETE', url: `${CONFIG.API_ENDPOINT}MedicineFile/fileID`, params: param, headers: authHeader });
            // console.log('DeleteMedicineinfo result.data >>', result.data);
            if (result.data.success) {
                result.postdata = param;
                return result.postdata;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
//============= Patient List
export const SearchPatient = createAsyncThunk(
    'Patient/SearchPatient',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Patient/SearchPatient`, params: userdata, data: userdata, headers: authHeader });
            // console.log('SearchPatient result.data >>', result.data);
            if (result.data.success) {
                return result.data.result;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const UpdatePatient = createAsyncThunk(
    'Patient/UpdatePatient',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}Patient`, data: postdata, headers: authHeader });
            // console.log('UpdatePatient result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const DeletePatient = createAsyncThunk(
    'Patient/DeletePatient',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'DELETE', url: `${CONFIG.API_ENDPOINT}Patient`, params: userdata, headers: authHeader });
            // console.log('DeletePatient result.data >>', result.data);
            if (result.data.success) {
                result.userdata = userdata;
                return result.userdata;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
//============= Price List
export const GetPriceList = createAsyncThunk(
    'PriceList/GetPriceList',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}PriceList`, data: userdata, headers: authHeader });
            // console.log('GetPriceList result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const AddPriceList = createAsyncThunk(
    'PriceList/addPriceList',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}PriceList/create`, data: postdata, headers: authHeader });
            // console.log('AddPriceList result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const UpdatePriceList = createAsyncThunk(
    'PriceList/UpdatePriceList',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}PriceList`, data: postdata, headers: authHeader });
            // console.log('UpdatePriceList result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const DeletePriceList = createAsyncThunk(
    'PriceList/deletePriceList',
    async (param, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'DELETE', url: `${CONFIG.API_ENDPOINT}PriceList/ID`, params: param, headers: authHeader });
            // console.log('DeletePriceList result.data >>', result.data);
            if (result.data.success) {
                result.postdata = param;
                return result.postdata;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const Productimport = createAsyncThunk(
    'PriceList/Productimport',
    async (filedata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}PriceList/ImportProduct`, data: filedata, headers: authHeader });
            // console.log('Productimport result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data.err);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const DeleteallProduct = createAsyncThunk(
    'PriceList/DeleteallProduct',
    async (param, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'DELETE', url: `${CONFIG.API_ENDPOINT}PriceList/deleteAll`, headers: authHeader });
            // console.log('DeleteallProduct result.data >>', result.data);
            if (result.data.success) {
                result.postdata = param;
                return result.postdata;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const ImportRxOneProducts = createAsyncThunk(
    'pricelist/ImportRxOneProducts',
    async (_postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}PriceList/ImportRxOneProducts`, headers: authHeader });
            // console.log('ImportRxOneProducts result.data >>', result.data);
            if (result.data.success) {
                return result.data.result;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
//============= QAQC Template
export const GetQAQCTemplates = createAsyncThunk(
    'QAQCTemplates/GetQAQCTemplates',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}QAQCTemplates`, data: userdata, headers: authHeader });
            // console.log('GetQAQCTemplates result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetQAQCTemplatesbybetchno = createAsyncThunk(
    'QAQCchecklist/GetQAQCTemplatesbybetchno',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}QAQCchecklist/GetByBatchNo`, params: userdata, data: userdata, headers: authHeader });
            // console.log('GetQAQCTemplatesbybetchno result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const AddQAQCTemplates = createAsyncThunk(
    'QAQCTemplates/addQAQCTemplates',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}QAQCTemplates/create`, data: postdata, headers: authHeader });
            // console.log('AddQAQCTemplates result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const UpdateQAQCTemplates = createAsyncThunk(
    'QAQCTemplates/UpdateQAQCTemplates',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}QAQCTemplates`, data: postdata, headers: authHeader });
            // console.log('UpdateQAQCTemplates result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const DeleteQAQCTemplates = createAsyncThunk(
    'QAQCTemplates/deleteQAQCTemplates',
    async (param, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'DELETE', url: `${CONFIG.API_ENDPOINT}QAQCTemplates/ID`, params: param, headers: authHeader });
            // console.log('DeleteQAQCTemplates result.data >>', result.data);
            if (result.data.success) {
                result.postdata = param;
                return result.postdata;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetQAQCTemplatesphoto = createAsyncThunk(
    'QAQCTemplates/GetQAQCTemplatesphoto',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'GET', url: `${CONFIG.API_ENDPOINT}QAQCTemplates/GetTempatePhotos`, params: postdata, headers: authHeader });
            // console.log('GetQAQCTemplatesphoto result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const AddQAQCTemplatesphoto = createAsyncThunk(
    'QAQCTemplates/AddQAQCTemplatesphoto',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader('multipart/form-data');
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}QAQCTemplates/AddTemplatePhoto`, data: postdata, headers: authHeader });
            // console.log('AddQAQCTemplatesphoto result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const DeleteQAQCTemplatesphoto = createAsyncThunk(
    'QAQCTemplates/DeleteQAQCTemplatesphoto',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'DELETE', url: `${CONFIG.API_ENDPOINT}QAQCTemplates/DeleteTemplatePhoto`, params: postdata, headers: authHeader });
            // console.log('DeleteQAQCTemplatesphoto result.data >>', result.data);
            if (result.data.success) {
                return postdata;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
//============= Sms Template
export const GetSmsTemplates = createAsyncThunk(
    'SMSTemplate/GetSmsTemplates',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}SMSTemplate`, data: userdata, headers: authHeader });
            // console.log('GetSmsTemplates result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const UpdateSmsTemplates = createAsyncThunk(
    'SMSTemplate/UpdateSmsTemplates',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}SMSTemplate`, data: postdata, headers: authHeader });
            // console.log('UpdateSmsTemplates result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
//============= TrainingVideos
export const GetTrainingVideos = createAsyncThunk(
    'TrainingVideos/GetTrainingVideos',
    async (data, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}TrainingVideos`, data: data, headers: authHeader });
            // console.log('GetTrainingVideos result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const AddTrainingVideos = createAsyncThunk(
    'TrainingVideos/addTrainingVideos',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}TrainingVideos/create`, data: postdata, headers: authHeader });
            // console.log('AddTrainingVideos result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const UpdateTrainingVideos = createAsyncThunk(
    'TrainingVideos/UpdateTrainingVideos',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}TrainingVideos`, data: postdata, headers: authHeader });
            // console.log('UpdateTrainingVideos result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const DeleteTrainingVideos = createAsyncThunk(
    'TrainingVideos/deleteTrainingVideos',
    async (param, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'DELETE', url: `${CONFIG.API_ENDPOINT}TrainingVideos`, params: param, headers: authHeader });
            // console.log('DeleteTrainingVideos result.data >>', result.data);
            if (result.data.success) {
                result.postdata = param;
                return result.postdata;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
//============= TrocheFlavour
export const GetTrocheFlavour = createAsyncThunk(
    'TrocheFlavour/GetTrocheFlavour',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}TrocheFlavour`, data: userdata, headers: authHeader });
            // console.log('GetTrocheFlavour result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const AddTrocheFlavour = createAsyncThunk(
    'TrocheFlavour/addTrocheFlavour',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}TrocheFlavour/create`, data: postdata, headers: authHeader });
            // console.log('AddTrocheFlavour result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const DeleteTrocheFlavour = createAsyncThunk(
    'TrocheFlavour/deleteTrocheFlavour',
    async (param, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'DELETE', url: `${CONFIG.API_ENDPOINT}TrocheFlavour`, params: param, headers: authHeader });
            // console.log('DeleteTrocheFlavour result.data >>', result.data);
            if (result.data.success) {
                result.postdata = param;
                return result.postdata;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
//============= Team Member
export const Getteammember = createAsyncThunk(
    'Teammember/GetTeammember',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}TeamMember`, data: userdata, headers: authHeader });
            // console.log('GetTeammember result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const AddTeamMember = createAsyncThunk(
    'TeamMember/addTeamMember',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}TeamMember/create`, data: postdata, headers: authHeader });
            // console.log('AddTeamMember result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const UpdateTeammember = createAsyncThunk(
    'TeamMember/UpdateTeammember',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}TeamMember`, data: postdata, headers: authHeader });
            // console.log('UpdateTeammember result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const statusUpdateTeammember = createAsyncThunk(
    'TeamMember/statusUpdateTeammember',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}TeamMember/TeamMemberStatusChange`, params: postdata, data: postdata, headers: authHeader });
            // console.log('statusUpdateTeammember result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const DeleteTeamMember = createAsyncThunk(
    'TeamMember/deleteTeamMember',
    async (param, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'DELETE', url: `${CONFIG.API_ENDPOINT}TeamMember`, params: param, headers: authHeader });
            // console.log('DeleteTeamMember result.data >>', result.data);
            if (result.data.success) {
                result.postdata = param;
                return result.postdata;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
//============= General Setting
export const GetGeneralSettings = createAsyncThunk(
    'GeneralSettings/GetGeneralSettings',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}GeneralSettings`, data: userdata, headers: authHeader });
            // console.log('GetGeneralSettings result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const UpdateGeneralsetting = createAsyncThunk(
    'GeneralSettings/UpdateGeneralsetting',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}GeneralSettings`, data: postdata, headers: authHeader });
            // console.log('UpdateGeneralsetting result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
//============= Order Type
export const Getordertype = createAsyncThunk(
    'OrderType/Getordertype',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}OrderType`, data: userdata, headers: authHeader });
            // console.log('Getordertype result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const AddOrdertype = createAsyncThunk(
    'OrderType/addORdertype',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}OrderType/create`, data: postdata, headers: authHeader });
            // console.log('AddORdertype result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const UpdateOrdertype = createAsyncThunk(
    'OrderType/UpdateOrdertype',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}OrderType`, data: postdata, headers: authHeader });
            // console.log('UpdateOrdertype result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const DeleteOrdertype = createAsyncThunk(
    'OrderType/DeleteOrdertype',
    async (param, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'DELETE', url: `${CONFIG.API_ENDPOINT}OrderType`, params: param, headers: authHeader });
            // console.log('DeleteOrdertype result.data >>', result.data);
            if (result.data.success) {
                result.postdata = param;
                return result.postdata;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
//----
export const getOptionforitems = createAsyncThunk(
    'optionforitems/optionforitems',
    async (data, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}optionforitems`, data: data, headers: authHeader });
            // console.log('getOptionforitems result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const AddOptionforitems = createAsyncThunk(
    'optionforitems/addoptionforitems',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}optionforitems/create`, data: postdata, headers: authHeader });
            // console.log('AddOptionforitems result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
//----
export const UpdateOptionforitems = createAsyncThunk(
    'optionforitems/UpdateOptionforitems',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}optionforitems`, data: postdata, headers: authHeader });
            // console.log('UpdateOptionforitems result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
//----
export const DeleteOptionforitems = createAsyncThunk(
    'optionforitems/DeleteOptionforitems',
    async (param, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'DELETE', url: `${CONFIG.API_ENDPOINT}optionforitems`, params: param, headers: authHeader });
            // console.log('DeleteOptionforitems result.data >>', result.data);
            if (result.data.success) {
                result.param = param;
                return result.param;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
//============= Clinic product
export const GetClinicProducts = createAsyncThunk(
    'ClinicProducts /GetClinicProducts',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}ClinicProducts`, data: userdata, headers: authHeader });
            // console.log('GetClinicProducts result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const AddClinicProducts = createAsyncThunk(
    'ClinicProducts/addClinicProducts',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}ClinicProducts/create`, data: postdata, headers: authHeader });
            // console.log('AddClinicProducts result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const UpdateClinicproduct = createAsyncThunk(
    'ClinicProducts/UpdateClinicproduct',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}ClinicProducts`, data: postdata, headers: authHeader });
            // console.log('UpdateClinicproduct result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const DeleteClinicProducts = createAsyncThunk(
    'ClinicProducts/deleteClinicProducts',
    async (param, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'DELETE', url: `${CONFIG.API_ENDPOINT}ClinicProducts`, params: param, headers: authHeader });
            // console.log('DeleteClinicProducts result.data >>', result.data);
            if (result.data.success) {
                result.postdata = param;
                return result.postdata;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const ImportClinicProduct = createAsyncThunk(
    'ClinicProducts/ImportClinicProduct',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader('multipart/form-data');
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}ClinicProducts/ImportClinicProduct`, data: userdata, headers: authHeader });
            // console.log('ImportClinicProduct result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const ExportClinicProducts = createAsyncThunk(
    'ClinicProducts/ExportClinicProducts',
    async (_param, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}ClinicProducts/ExportClinicProduct`, headers: authHeader });
            // console.log('ExportClinicProducts result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetQAQCBatchList = createAsyncThunk(
    'QAQCchecklist/GetQAQCBatchList',
    async (data, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}QAQCchecklist/GetQAQCBatchList`, data: data, headers: authHeader });
            // console.log('GetQAQCBatchList result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const QAQCbyTemplateid = createAsyncThunk(
    'QAQCchecklist/QAQCbyTemplateid',
    async (data, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}QAQCchecklist/QAQCbyTemplateid`, params: data, data: data, headers: authHeader });
            // console.log('QAQCbyTemplateid result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const AddQAOrderByBatch = createAsyncThunk(
    'QAQCchecklist/AddQAOrderByBatch',
    async (data, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}QAQCchecklist/AddQAOrderByBatch`, data: data, headers: authHeader });
            // console.log('AddQAOrderByBatch result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const UpdateQAOrderByBatch = createAsyncThunk(
    'QAQCchecklist/UpdateQAOrderByBatch',
    async (data, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}QAQCchecklist/UpdateQAOrderByBatch`, data: data, headers: authHeader });
            // console.log('UpdateQAOrderByBatch result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const DeleteQAQCchecklist = createAsyncThunk(
    'QAQCchecklist/DeleteQAQCchecklist',
    async (param, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'DELETE', url: `${CONFIG.API_ENDPOINT}QAQCchecklist/ID`, params: param, headers: authHeader });
            // console.log('DeleteQAQCchecklist result.data >>', result.data);
            if (result.data.success) {
                result.postdata = param;
                return result.postdata;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetInternalTemplate = createAsyncThunk(
    'InternalTemplate/GetInternalTemplate',
    async (data, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}InternalTemplate`, data: data, headers: authHeader });
            // console.log('GetInternalTemplate result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetallInternalTemplate = createAsyncThunk(
    'InternalTemplate/GetallInternalTemplate',
    async (data, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'GET', url: `${CONFIG.API_ENDPOINT}allInternalTemplate`, params: data, headers: authHeader });
            // console.log('GetallInternalTemplate result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetInternalTemplateByID = createAsyncThunk(
    'InternalTemplate/GetInternalTemplateByID',
    async (data, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'GET', url: `${CONFIG.API_ENDPOINT}InternalTemplate/GetInternalTemplateByID`, params: data, headers: authHeader });
            // console.log('GetInternalTemplateByID result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const AddInternalTemplate = createAsyncThunk(
    'InternalTemplate/AddInternalTemplate',
    async (data, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}InternalTemplate/create`, data: data, headers: authHeader });
            // console.log('AddInternalTemplate result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const UpdateInternalTemplate = createAsyncThunk(
    'InternalTemplate/UpdateInternalTemplate',
    async (data, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}InternalTemplate`, data: data, headers: authHeader });
            // console.log('UpdateInternalTemplate result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const DeleteInternalTemplate = createAsyncThunk(
    'InternalTemplate/deleteInternalTemplate',
    async (param, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'DELETE', url: `${CONFIG.API_ENDPOINT}InternalTemplate`, params: param, headers: authHeader });
            // console.log('DeleteInternalTemplate result.data >>', result.data);
            if (result.data.success) {
                result.postdata = param;
                return result.postdata;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
//======================
export const systemSlice = createSlice({
    name: 'system',
    initialState: {
        dropdownrolesdata: [],
        dropdownbranddata: [],
        dropdownTeamRolesdata: [],
        allfiledata: [],
        Branddata: {},
        Extpopupdata: [],
        ExternalUsersdata: [],
        ExternalUsersexportdata: null,
        Announcementdata: {},
        Emailtempdata: [],
        Ingredientsdata: [],
        Medicineinfodata: [],
        Patientdata: [],
        PriceListdata: [],
        QAQCTemplatesdata: [],
        SmsTemplatesdata: [],
        TrainingVideosdata: [],
        TrocheFlavourdata: [],
        Teammemberdata: [],
        Ordertypedata: [],
        GeneralSettingsdata: [],
        ClinicProductsdata: [],
        QAQCBatchListdata: [],
        QAQCTotalBatchList: null,
        ExportClinicProductsdata: null,
        QAQCbyTemplateiddata: {},
        QAQCTemplatesphotodata: [],
        InternalTemplatebyiddata: {},
        allInternalTemplatedata: [],
        InternalTemplatedata: {},
        optionforitemData: {},
        //---
        isinternaltemplateAdded: false,
        isinternaltemplateUpdated: false,
        isinternaltemplateDelete: false,
        isFileAdded: false,
        isFileDelete: false,
        isBrandAdded: false,
        isBrandUpdated: false,
        isBrandDelete: false,
        isExternaluserAdded: false,
        isExternaluserUpdated: false,
        isExternaluserDelete: false,
        isEnableuserUpdated: false,
        isAnnouncementAdded: false,
        isAnnouncementUpdated: false,
        isAnnouncementDelete: false,
        isExternalpopupUpdated: false,
        isEmailtempUpdated: false,
        isIngredientsAdded: false,
        isIngredientsUpdated: false,
        isIngredientsDelete: false,
        isMedicineinfoAdded: false,
        isMedicineinfoUpdated: false,
        isMedicineinfoDelete: false,
        isPatientUpdated: false,
        isPatientDelete: false,
        isPriceListAdded: false,
        isPriceListUpdated: false,
        isPriceListDelete: false,
        isProductimport: false,
        isQAQCTemplatesAdded: false,
        isQAQCTemplatesUpdated: false,
        isQAQCTemplatesDelete: false,
        isTrainingVideosAdded: false,
        isTrainingVideosUpdated: false,
        isTrainingVideosDelete: false,
        isTrocheFlavourAdded: false,
        isTrocheFlavourUpdated: false,
        isTrocheFlavourDelete: false,
        isTeammemberdataAdded: false,
        isTeammemberdataUpdated: false,
        isTeammemberdataDelete: false,
        isTeammemberdatastatus: false,
        isGeneralSettingsAdded: false,
        isGeneralSettingsUpdated: false,
        isGeneralSettingsDelete: false,
        isoptionforitemAdded: false,
        isoptionforitemUpdated: false,
        isOptionforitemDelete: false,
        isOrdertypedataAdded: false,
        isOrdertypedataUpdated: false,
        isOrdertypedataDelete: false,
        isSmsTemplatesUpdated: false,
        isPasswordreset: false,
        isClinicproductAdded: false,
        isClinicproductUpdated: false,
        isClinicproductDeleted: false,
        isClinicproductimport: false,
        isQaqcbybatchadded: false,
        isQaqcbybatchUpdated: false,
        isQaqcbybatchDeleted: false,
        hasmore: false,
        isQAQCTemplatesphotoAdded: false,
        isQAQCTemplatesphotoDelete: false,
        isRxoneproduct: false,
        //---
        isFetchingOBJ: {},
        isFetching: false,
        isError: false,
        errorMessage: '',
    },
    reducers: {
        updateState: (state, { payload }) => {
            state.isFileAdded = payload.isFileAdded !== undefined ? payload.isFileAdded : state.isFileAdded;
            state.isFileDelete = payload.isFileDelete !== undefined ? payload.isFileDelete : state.isFileDelete;
            state.isBrandAdded = payload.isBrandAdded !== undefined ? payload.isBrandAdded : state.isBrandAdded;
            state.isBrandUpdated = payload.isBrandUpdated !== undefined ? payload.isBrandUpdated : state.isBrandUpdated;
            state.isBrandDelete = payload.isBrandDelete !== undefined ? payload.isBrandDelete : state.isBrandDelete;
            state.isExternaluserAdded = payload.isExternaluserAdded !== undefined ? payload.isExternaluserAdded : state.isExternaluserAdded;
            state.isExternaluserUpdated = payload.isExternaluserUpdated !== undefined ? payload.isExternaluserUpdated : state.isExternaluserUpdated;
            state.isExternaluserDelete = payload.isExternaluserDelete !== undefined ? payload.isExternaluserDelete : state.isExternaluserDelete;
            state.isEnableuserUpdated = payload.isEnableuserUpdated !== undefined ? payload.isEnableuserUpdated : state.isEnableuserUpdated;
            state.isAnnouncementAdded = payload.isAnnouncementAdded !== undefined ? payload.isAnnouncementAdded : state.isAnnouncementAdded;
            state.isAnnouncementUpdated = payload.isAnnouncementUpdated !== undefined ? payload.isAnnouncementUpdated : state.isAnnouncementUpdated;
            state.isAnnouncementDelete = payload.isAnnouncementDelete !== undefined ? payload.isAnnouncementDelete : state.isAnnouncementDelete;
            state.isExternalpopupUpdated = payload.isExternalpopupUpdated !== undefined ? payload.isExternalpopupUpdated : state.isExternalpopupUpdated;
            state.isEmailtempUpdated = payload.isEmailtempUpdated !== undefined ? payload.isEmailtempUpdated : state.isEmailtempUpdated;
            state.isIngredientsAdded = payload.isIngredientsAdded !== undefined ? payload.isIngredientsAdded : state.isIngredientsAdded;
            state.isIngredientsUpdated = payload.isIngredientsUpdated !== undefined ? payload.isIngredientsUpdated : state.isIngredientsUpdated;
            state.isIngredientsDelete = payload.isIngredientsDelete !== undefined ? payload.isIngredientsDelete : state.isIngredientsDelete;
            state.isMedicineinfoAdded = payload.isMedicineinfoAdded !== undefined ? payload.isMedicineinfoAdded : state.isMedicineinfoAdded;
            state.isMedicineinfoUpdated = payload.isMedicineinfoUpdated !== undefined ? payload.isMedicineinfoUpdated : state.isMedicineinfoUpdated;
            state.isMedicineinfoDelete = payload.isMedicineinfoDelete !== undefined ? payload.isMedicineinfoDelete : state.isMedicineinfoDelete;
            state.isPatientUpdated = payload.isPatientUpdated !== undefined ? payload.isPatientUpdated : state.isPatientUpdated;
            state.isPatientDelete = payload.isPatientDelete !== undefined ? payload.isPatientDelete : state.isPatientDelete;
            state.isPriceListAdded = payload.isPriceListAdded !== undefined ? payload.isPriceListAdded : state.isPriceListAdded;
            state.isPriceListUpdated = payload.isPriceListUpdated !== undefined ? payload.isPriceListUpdated : state.isPriceListUpdated;
            state.isPriceListDelete = payload.isPriceListDelete !== undefined ? payload.isPriceListDelete : state.isPriceListDelete;
            state.isProductimport = payload.isProductimport !== undefined ? payload.isProductimport : state.isProductimport;
            state.isQAQCTemplatesAdded = payload.isQAQCTemplatesAdded !== undefined ? payload.isQAQCTemplatesAdded : state.isQAQCTemplatesAdded;
            state.isQAQCTemplatesUpdated = payload.isQAQCTemplatesUpdated !== undefined ? payload.isQAQCTemplatesUpdated : state.isQAQCTemplatesUpdated;
            state.isQAQCTemplatesDelete = payload.isQAQCTemplatesDelete !== undefined ? payload.isQAQCTemplatesDelete : state.isQAQCTemplatesDelete;
            state.isTrainingVideosAdded = payload.isTrainingVideosAdded !== undefined ? payload.isTrainingVideosAdded : state.isTrainingVideosAdded;
            state.isTrainingVideosUpdated = payload.isTrainingVideosUpdated !== undefined ? payload.isTrainingVideosUpdated : state.isTrainingVideosUpdated;
            state.isTrainingVideosDelete = payload.isTrainingVideosDelete !== undefined ? payload.isTrainingVideosDelete : state.isTrainingVideosDelete;
            state.isTrocheFlavourAdded = payload.isTrocheFlavourAdded !== undefined ? payload.isTrocheFlavourAdded : state.isTrocheFlavourAdded;
            state.isTrocheFlavourUpdated = payload.isTrocheFlavourUpdated !== undefined ? payload.isTrocheFlavourUpdated : state.isTrocheFlavourUpdated;
            state.isTrocheFlavourDelete = payload.isTrocheFlavourDelete !== undefined ? payload.isTrocheFlavourDelete : state.isTrocheFlavourDelete;
            state.isTeammemberdataAdded = payload.isTeammemberdataAdded !== undefined ? payload.isTeammemberdataAdded : state.isTeammemberdataAdded;
            state.isTeammemberdataUpdated = payload.isTeammemberdataUpdated !== undefined ? payload.isTeammemberdataUpdated : state.isTeammemberdataUpdated;
            state.isTeammemberdataDelete = payload.isTeammemberdataDelete !== undefined ? payload.isTeammemberdataDelete : state.isTeammemberdataDelete;
            state.isTeammemberdatastatus = payload.isTeammemberdatastatus !== undefined ? payload.isTeammemberdatastatus : state.isTeammemberdatastatus;
            state.isGeneralSettingsAdded = payload.isGeneralSettingsAdded !== undefined ? payload.isGeneralSettingsAdded : state.isGeneralSettingsAdded;
            state.isGeneralSettingsUpdated = payload.isGeneralSettingsUpdated !== undefined ? payload.isGeneralSettingsUpdated : state.isGeneralSettingsUpdated;
            state.isGeneralSettingsDelete = payload.isGeneralSettingsDelete !== undefined ? payload.isGeneralSettingsDelete : state.isGeneralSettingsDelete;
            state.isoptionforitemAdded = payload.isoptionforitemAdded !== undefined ? payload.isoptionforitemAdded : state.isoptionforitemAdded;
            state.isoptionforitemUpdated = payload.isoptionforitemUpdated !== undefined ? payload.isoptionforitemUpdated : state.isoptionforitemUpdated;
            state.isOptionforitemDelete = payload.isOptionforitemDelete !== undefined ? payload.isOptionforitemDelete : state.isOptionforitemDelete;
            state.isOrdertypedataAdded = payload.isOrdertypedataAdded !== undefined ? payload.isOrdertypedataAdded : state.isOrdertypedataAdded;
            state.isOrdertypedataUpdated = payload.isOrdertypedataUpdated !== undefined ? payload.isOrdertypedataUpdated : state.isOrdertypedataUpdated;
            state.isOrdertypedataDelete = payload.isOrdertypedataDelete !== undefined ? payload.isOrdertypedataDelete : state.isOrdertypedataDelete;
            state.isSmsTemplatesUpdated = payload.isSmsTemplatesUpdated !== undefined ? payload.isSmsTemplatesUpdated : state.isSmsTemplatesUpdated;
            state.isPasswordreset = payload.isPasswordreset !== undefined ? payload.isPasswordreset : state.isPasswordreset;
            state.isClinicproductAdded = payload.isClinicproductAdded !== undefined ? payload.isClinicproductAdded : state.isClinicproductAdded;
            state.isClinicproductUpdated = payload.isClinicproductUpdated !== undefined ? payload.isClinicproductUpdated : state.isClinicproductUpdated;
            state.isClinicproductDeleted = payload.isClinicproductDeleted !== undefined ? payload.isClinicproductDeleted : state.isClinicproductDeleted;
            state.isClinicproductimport = payload.isClinicproductimport !== undefined ? payload.isClinicproductimport : state.isClinicproductimport;
            state.ExternalUsersexportdata = payload.ExternalUsersexportdata !== undefined ? payload.ExternalUsersexportdata : state.ExternalUsersexportdata;
            state.ExportClinicProductsdata = payload.ExportClinicProductsdata !== undefined ? payload.ExportClinicProductsdata : state.ExportClinicProductsdata;
            state.isQaqcbybatchadded = payload.isQaqcbybatchadded !== undefined ? payload.isQaqcbybatchadded : state.isQaqcbybatchadded;
            state.isQaqcbybatchUpdated = payload.isQaqcbybatchUpdated !== undefined ? payload.isQaqcbybatchUpdated : state.isQaqcbybatchUpdated;
            state.isQaqcbybatchDeleted = payload.isQaqcbybatchDeleted !== undefined ? payload.isQaqcbybatchDeleted : state.isQaqcbybatchDeleted;
            state.isinternaltemplateAdded = payload.isinternaltemplateAdded !== undefined ? payload.isinternaltemplateAdded : state.isinternaltemplateAdded;
            state.isinternaltemplateUpdated = payload.isinternaltemplateUpdated !== undefined ? payload.isinternaltemplateUpdated : state.isinternaltemplateUpdated;
            state.isinternaltemplateDelete = payload.isinternaltemplateDelete !== undefined ? payload.isinternaltemplateDelete : state.isinternaltemplateDelete;
            state.allInternalTemplatedata = payload.allInternalTemplatedata !== undefined ? payload.allInternalTemplatedata : state.allInternalTemplatedata;
            state.isQAQCTemplatesphotoAdded = payload.isQAQCTemplatesphotoAdded !== undefined ? payload.isQAQCTemplatesphotoAdded : state.isQAQCTemplatesphotoAdded;
            state.isQAQCTemplatesphotoDelete = payload.isQAQCTemplatesphotoDelete !== undefined ? payload.isQAQCTemplatesphotoDelete : state.isQAQCTemplatesphotoDelete;
            state.isRxoneproduct = payload.isRxoneproduct !== undefined ? payload.isRxoneproduct : state.isRxoneproduct;
            state.hasmore = payload.hasmore !== undefined ? payload.hasmore : state.hasmore;
            state.isFetching = payload.isFetching !== undefined ? payload.isFetching : state.isFetching;
            state.isError = payload.isError !== undefined ? payload.isError : state.isError;
            state.errorMessage = payload.errorMessage !== undefined ? payload.errorMessage : state.errorMessage;
        },
    },
    extraReducers: (builder) => {
        //================== Dropdown
        builder.addCase(Getallroles.fulfilled, (state, { payload }) => {
            try {
                state.dropdownrolesdata = payload;
                state.isFetching = false;
                state.isFetchingOBJ.Getallroles = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[Getallroles.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(Getallroles.rejected, (state, { payload }) => {
            try {
                state.dropdownrolesdata = [];
                state.isFetching = false;
                state.isFetchingOBJ.Getallroles = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[Getallroles.rejected] try catch error >>', error);
            }
        })
        builder.addCase(Getallroles.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.Getallroles = true;
        })
        //---
        builder.addCase(GetTeamRoles.fulfilled, (state, { payload }) => {
            try {
                state.dropdownTeamRolesdata = payload;
                state.isFetching = false;
                state.isFetchingOBJ.GetTeamRoles = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetTeamRoles.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetTeamRoles.rejected, (state, { payload }) => {
            try {
                state.dropdownTeamRolesdata = [];
                state.isFetching = false;
                state.isFetchingOBJ.GetTeamRoles = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetTeamRoles.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetTeamRoles.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetTeamRoles = true;
        })
        //---
        builder.addCase(GetallBrandNames.fulfilled, (state, { payload }) => {
            try {
                state.dropdownbranddata = payload;
                state.isFetching = false;
                state.isFetchingOBJ.GetallBrandNames = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetallBrandNames.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetallBrandNames.rejected, (state, { payload }) => {
            try {
                state.dropdownbranddata = [];
                state.isFetching = false;
                state.isFetchingOBJ.GetallBrandNames = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetallBrandNames.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetallBrandNames.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetallBrandNames = true;
        })
        //================== All Files
        builder.addCase(Getallfile.fulfilled, (state, { payload }) => {
            try {
                state.allfiledata = payload;
                state.isFetchingOBJ.Getallfile = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[Getallfile.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(Getallfile.rejected, (state, { payload }) => {
            try {
                state.allfiledata = [];
                state.isFetchingOBJ.Getallfile = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[Getallfile.rejected] try catch error >>', error);
            }
        })
        builder.addCase(Getallfile.pending, (state) => {
            state.isFetchingOBJ.Getallfile = true;
        })
        //---
        builder.addCase(Addallfile.fulfilled, (state, { payload }) => {
            try {
                let updated = payload.result;
                //let reciveitems = [...state.allfiledata.data, updated.result];
                let existData = [...state.allfiledata.data];
                let newData = [...existData, updated];
                state.allfiledata.recordsTotal += 1;
                state.allfiledata.recordsFiltered += 1;
                state.allfiledata.data = newData;
                state.isFileAdded = true;
                state.isFetching = false;
                state.isFetchingOBJ.Addallfile = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[Addallfile.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(Addallfile.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.Addallfile = false;
                state.isError = true;
                state.isFileAdded = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[Addallfile.rejected] try catch error >>', error);
            }
        })
        builder.addCase(Addallfile.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.Addallfile = true;
        })
        //---
        builder.addCase(Deleteallfile.fulfilled, (state, { payload }) => {
            try {
                let exist = { ...state.allfiledata };
                let newExist = exist.data?.filter((e) => {
                    if (e.ID !== payload.fileID) {
                        return e;
                    }
                    return false;
                });
                exist.data = newExist;
                state.allfiledata = exist;
                state.isFetching = false;
                state.isFetchingOBJ.Deleteallfile = false;
                state.isError = false;
                state.isFileDelete = true;
            } catch (error) {
                console.error('[Deleteallfile.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(Deleteallfile.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.Deleteallfile = false;
                state.isError = true;
                state.isFileDelete = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[Deleteallfile.rejected] try catch error >>', error);
            }
        })
        builder.addCase(Deleteallfile.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.Deleteallfile = true;
        })
        //================== Brand Management
        builder.addCase(GetBrand.fulfilled, (state, { payload }) => {
            try {
                state.Branddata = payload;
                state.isFetchingOBJ.GetBrand = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetBrand.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetBrand.rejected, (state, { payload }) => {
            try {
                state.Branddata = {};
                state.isFetchingOBJ.GetBrand = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetBrand.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetBrand.pending, (state) => {
            state.isFetchingOBJ.GetBrand = true;
        })
        //---
        builder.addCase(ExportExternalusers.fulfilled, (state, { payload }) => {
            try {
                state.ExternalUsersexportdata = payload;
                state.isFetching = false;
                state.isFetchingOBJ.ExportExternalusers = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[ExportExternalusers.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(ExportExternalusers.rejected, (state, { payload }) => {
            try {
                state.ExternalUsersexportdata = null;
                state.isFetching = false;
                state.isFetchingOBJ.ExportExternalusers = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[ExportExternalusers.rejected] try catch error >>', error);
            }
        })
        builder.addCase(ExportExternalusers.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.ExportExternalusers = true;
        })
        //---
        builder.addCase(AddBrand.fulfilled, (state, { payload }) => {
            try {
                let updated = payload.result;
                let existData = [...state.Branddata.data];
                let newData = [...existData, updated];
                state.Branddata.recordsTotal += 1;
                state.Branddata.recordsFiltered += 1;
                state.Branddata.data = newData;
                state.isBrandAdded = true;
                state.isFetching = false;
                state.isFetchingOBJ.AddBrand = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[AddBrand.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(AddBrand.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.AddBrand = false;
                state.isError = true;
                state.isBrandAdded = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[AddBrand.rejected] try catch error >>', error);
            }
        })
        builder.addCase(AddBrand.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.AddBrand = true;
        })
        //---
        builder.addCase(UpdateBrand.fulfilled, (state, { payload }) => {
            try {
                let exist = { ...state.Branddata };
                let updated = payload.result;
                const index = state.Branddata.data.findIndex(opt => opt.shop_ownership_id === updated.shop_ownership_id)
                exist.data[index] = updated;
                state.Branddata = exist;
                state.isBrandUpdated = true;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateBrand = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[UpdateBrand.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(UpdateBrand.rejected, (state, { payload }) => {
            try {
                state.isBrandUpdated = false;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateBrand = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[UpdateBrand.rejected] try catch error >>', error);
            }
        })
        builder.addCase(UpdateBrand.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.UpdateBrand = true;
        })
        //---
        builder.addCase(DeleteBrand.fulfilled, (state, { payload }) => {
            try {
                let exist = { ...state.Branddata };
                let newExist = exist.data?.filter((e) => {
                    if (e.shop_ownership_id !== payload.ID) {
                        return e;
                    }
                    return false;
                });
                exist.data = newExist;
                state.Branddata = exist;
                state.isFetching = false;
                state.isFetchingOBJ.DeleteBrand = false;
                state.isError = false;
                state.isBrandDelete = true;
            } catch (error) {
                console.error('[DeleteBrand.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(DeleteBrand.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.DeleteBrand = false;
                state.isError = true;
                state.isBrandDelete = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[DeleteBrand.rejected] try catch error >>', error);
            }
        })
        builder.addCase(DeleteBrand.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.DeleteBrand = true;
        })
        //================== Externalpopup
        builder.addCase(GetExternalpopup.fulfilled, (state, { payload }) => {
            try {
                state.Extpopupdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.GetExternalpopup = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetExternalpopup.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetExternalpopup.rejected, (state, { payload }) => {
            try {
                state.Extpopupdata = [];
                state.isFetching = false;
                state.isFetchingOBJ.GetExternalpopup = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetExternalpopup.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetExternalpopup.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetExternalpopup = true;
        })
        //---
        builder.addCase(updateExternalpopup.fulfilled, (state, { payload }) => {
            try {
                state.Extpopupdata = payload;
                state.isExternalpopupUpdated = true;
                state.isFetching = false;
                state.isFetchingOBJ.updateExternalpopup = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[updateExternalpopup.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(updateExternalpopup.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.updateExternalpopup = false;
                state.isExternalpopupUpdated = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[updateExternalpopup.rejected] try catch error >>', error);
            }
        })
        builder.addCase(updateExternalpopup.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.updateExternalpopup = true;
        })
        //================== ExternalUsers
        builder.addCase(GetExternalusers.fulfilled, (state, { payload }) => {
            try {
                state.ExternalUsersdata = payload;
                state.isFetchingOBJ.GetExternalusers = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetExternalpopup.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetExternalusers.rejected, (state, { payload }) => {
            try {
                state.ExternalUsersdata = [];
                state.isFetchingOBJ.GetExternalusers = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetExternalpopup.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetExternalusers.pending, (state) => {
            state.isFetchingOBJ.GetExternalusers = true;
        })
        //---
        builder.addCase(AddExternalusers.fulfilled, (state, { payload }) => {
            try {
                let updated = payload.result;
                let existData = [...state.ExternalUsersdata.data];
                let newData = [...existData, updated];
                state.ExternalUsersdata.recordsTotal += 1;
                state.ExternalUsersdata.recordsFiltered += 1;
                state.ExternalUsersdata.data = newData;
                state.isExternaluserAdded = true;
                state.isFetching = false;
                state.isFetchingOBJ.AddExternalusers = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[AddExternalusers.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(AddExternalusers.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.AddExternalusers = false;
                state.isError = true;
                state.isExternaluserAdded = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[AddExternalusers.rejected] try catch error >>', error);
            }
        })
        builder.addCase(AddExternalusers.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.AddExternalusers = true;
        })
        //---
        builder.addCase(UpdateExternalusers.fulfilled, (state, { payload }) => {
            try {
                let exist = { ...state.ExternalUsersdata };
                let updated = payload.result;
                const index = state.ExternalUsersdata.data.findIndex(opt => opt.client_id === updated.client_id)
                exist.data[index] = updated;
                state.ExternalUsersdata = exist;
                state.isExternaluserUpdated = true;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateExternalusers = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[UpdateExternalusers.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(UpdateExternalusers.rejected, (state, { payload }) => {
            try {
                state.isExternaluserUpdated = false;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateExternalusers = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[UpdateExternalusers.rejected] try catch error >>', error);
            }
        })
        builder.addCase(UpdateExternalusers.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.UpdateExternalusers = true;
        })
        //---
        builder.addCase(EnableExternalusers.fulfilled, (state, { payload }) => {
            try {
                let exist = { ...state.ExternalUsersdata };
                let updated = payload.result;
                const index = state.ExternalUsersdata.data.findIndex(opt => opt.client_id === updated.client_id)
                exist.data[index] = updated;
                state.ExternalUsersdata = exist;
                state.isEnableuserUpdated = true;
                state.isFetching = false;
                state.isFetchingOBJ.EnableExternalusers = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[EnableExternalusers.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(EnableExternalusers.rejected, (state, { payload }) => {
            try {
                state.isEnableuserUpdated = false;
                state.isFetching = false;
                state.isFetchingOBJ.EnableExternalusers = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[EnableExternalusers.rejected] try catch error >>', error);
            }
        })
        builder.addCase(EnableExternalusers.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.EnableExternalusers = true;
        })
        //---
        builder.addCase(DeleteExternalusers.fulfilled, (state, { payload }) => {
            try {
                let exist = { ...state.ExternalUsersdata };
                let newExist = exist.data?.filter((e) => {
                    if (e.client_id !== payload.ID) {
                        return e;
                    }
                    return false;
                });
                exist.data = newExist;
                state.ExternalUsersdata = exist;
                state.isFetching = false;
                state.isFetchingOBJ.DeleteExternalusers = false;
                state.isError = false;
                state.isExternaluserDelete = true;
            } catch (error) {
                console.error('[DeleteExternalusers.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(DeleteExternalusers.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.DeleteExternalusers = false;
                state.isError = true;
                state.isExternaluserDelete = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[DeleteExternalusers.rejected] try catch error >>', error);
            }
        })
        builder.addCase(DeleteExternalusers.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.DeleteExternalusers = true;
        })
        //---
        builder.addCase(userResetpassword.fulfilled, (state, { payload }) => {
            try {
                let exist = { ...state.ExternalUsersdata };
                let updated = payload.result;
                const index = state.ExternalUsersdata.data.findIndex(opt => opt.client_id === updated.client_id)
                exist.data[index] = updated;
                state.ExternalUsersdata = exist;
                state.isPasswordreset = true;
                state.isFetching = false;
                state.isFetchingOBJ.userResetpassword = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[userResetpassword.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(userResetpassword.rejected, (state, { payload }) => {
            try {
                state.isPasswordreset = false;
                state.isFetching = false;
                state.isFetchingOBJ.userResetpassword = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[userResetpassword.rejected] try catch error >>', error);
            }
        })
        builder.addCase(userResetpassword.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.userResetpassword = true;
        })
        //================== Announcement
        builder.addCase(GetAnnouncement.fulfilled, (state, { payload }) => {
            try {
                if (state.hasmore) {
                    let updated = payload.data;
                    const newData = _.unionBy(state.Announcementdata.data, updated, 'Announcement_id');
                    state.Announcementdata.data = newData;
                } else {
                    state.Announcementdata = payload;
                }
                state.isFetchingOBJ.GetAnnouncement = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetAnnouncement.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetAnnouncement.rejected, (state, { payload }) => {
            try {
                state.Announcementdata = {};
                state.isFetchingOBJ.GetAnnouncement = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetAnnouncement.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetAnnouncement.pending, (state) => {
            state.isFetchingOBJ.GetAnnouncement = true;
        })
        //---
        builder.addCase(AddAnnouncement.fulfilled, (state, { payload }) => {
            try {
                let updated = payload.result;
                let existData = [...state.Announcementdata.data];
                let newData = [...existData, updated];
                state.Announcementdata.recordsTotal += 1;
                state.Announcementdata.recordsFiltered += 1;
                state.Announcementdata.data = newData;
                state.isAnnouncementAdded = true;
                state.isFetching = false;
                state.isFetchingOBJ.AddAnnouncement = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[AddAnnouncement.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(AddAnnouncement.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.AddAnnouncement = false;
                state.isError = true;
                state.isAnnouncementAdded = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[AddAnnouncement.rejected] try catch error >>', error);
            }
        })
        builder.addCase(AddAnnouncement.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.AddAnnouncement = true;
        })
        //---
        builder.addCase(UpdateAnnouncement.fulfilled, (state, { payload }) => {
            try {
                let exist = { ...state.Announcementdata };
                let updated = payload.result;
                const index = state.Announcementdata.data.findIndex(opt => opt.Announcement_id === updated.Announcement_id)
                exist.data[index] = updated;
                state.Announcementdata = exist;
                state.isAnnouncementUpdated = true;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateAnnouncement = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[UpdateAnnouncement.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(UpdateAnnouncement.rejected, (state, { payload }) => {
            try {
                state.isAnnouncementUpdated = false;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateAnnouncement = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[UpdateAnnouncement.rejected] try catch error >>', error);
            }
        })
        builder.addCase(UpdateAnnouncement.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.UpdateAnnouncement = true;
        })
        //---
        builder.addCase(DeleteAnnouncement.fulfilled, (state, { payload }) => {
            try {
                let exist = [...state.Announcementdata.data];
                let newExist = exist?.filter((e) => {
                    if (e.Announcement_id !== payload.ID) {
                        return e;
                    }
                    return false;
                });
                exist = newExist;
                state.Announcementdata.data = exist;
                state.isFetching = false;
                state.isFetchingOBJ.DeleteAnnouncement = false;
                state.isError = false;
                state.isAnnouncementDelete = true;
            } catch (error) {
                console.error('[DeleteAnnouncement.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(DeleteAnnouncement.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.DeleteAnnouncement = false;
                state.isError = true;
                state.isAnnouncementDelete = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[DeleteAnnouncement.rejected] try catch error >>', error);
            }
        })
        builder.addCase(DeleteAnnouncement.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.DeleteAnnouncement = true;
        })
        //================== Email Template
        builder.addCase(GetEmailtemp.fulfilled, (state, { payload }) => {
            try {
                state.Emailtempdata = payload;
                state.isFetchingOBJ.GetEmailtemp = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetEmailtemp.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetEmailtemp.rejected, (state, { payload }) => {
            try {
                state.Emailtempdata = [];
                state.isFetchingOBJ.GetEmailtemp = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetEmailtemp.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetEmailtemp.pending, (state) => {
            state.isFetchingOBJ.GetEmailtemp = true;
        })
        //---
        builder.addCase(UpdateEmailtemp.fulfilled, (state, { payload }) => {
            try {
                let exist = { ...state.Emailtempdata };
                let updated = payload.result;
                const index = state.Emailtempdata.data.findIndex(opt => opt.Id === updated.Id)
                exist.data[index] = updated;
                state.Emailtempdata = exist;
                state.isEmailtempUpdated = true;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateEmailtemp = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[UpdateEmailtemp.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(UpdateEmailtemp.rejected, (state, { payload }) => {
            try {
                state.isEmailtempUpdated = false;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateEmailtemp = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[UpdateEmailtemp.rejected] try catch error >>', error);
            }
        })
        builder.addCase(UpdateEmailtemp.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.UpdateEmailtemp = true;
        })
        //================== Ingredient
        builder.addCase(GetIngredient.fulfilled, (state, { payload }) => {
            try {
                state.Ingredientsdata = payload;
                state.isFetchingOBJ.GetIngredient = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetIngredient.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetIngredient.rejected, (state, { payload }) => {
            try {
                state.Ingredientsdata = [];
                state.isFetchingOBJ.GetIngredient = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetIngredient.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetIngredient.pending, (state) => {
            state.isFetchingOBJ.GetIngredient = true;
        })
        //---
        builder.addCase(AddIngredient.fulfilled, (state, { payload }) => {
            try {
                let updated = payload.result;
                let existData = [...state.Ingredientsdata.data];
                let newData = [...existData, updated];
                state.Ingredientsdata.recordsTotal += 1;
                state.Ingredientsdata.recordsFiltered += 1;
                state.Ingredientsdata.data = newData;
                state.isIngredientsAdded = true;
                state.isFetching = false;
                state.isFetchingOBJ.AddIngredient = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[AddIngredient.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(AddIngredient.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.AddIngredient = false;
                state.isError = true;
                state.isIngredientsAdded = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[AddIngredient.rejected] try catch error >>', error);
            }
        })
        builder.addCase(AddIngredient.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.AddIngredient = true;
        })
        //---
        builder.addCase(UpdateIngredient.fulfilled, (state, { payload }) => {
            try {
                let exist = { ...state.Ingredientsdata };
                let updated = payload.result;
                const index = state.Ingredientsdata.data.findIndex(opt => opt.itemid === updated.itemid)
                exist.data[index] = updated;
                state.Ingredientsdata = exist;
                state.isIngredientsUpdated = true;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateIngredient = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[UpdateIngredient.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(UpdateIngredient.rejected, (state, { payload }) => {
            try {
                state.isIngredientsUpdated = false;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateIngredient = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[UpdateIngredient.rejected] try catch error >>', error);
            }
        })
        builder.addCase(UpdateIngredient.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.UpdateIngredient = true;
        })
        //---
        builder.addCase(DeleteIngredient.fulfilled, (state, { payload }) => {
            try {
                let exist = { ...state.Ingredientsdata };
                let newExist = exist.data?.filter((e) => {
                    if (e.itemid !== payload.ID) {
                        return e;
                    }
                    return false;
                });
                exist.data = newExist;
                state.Ingredientsdata = exist;
                state.isFetching = false;
                state.isFetchingOBJ.DeleteIngredient = false;
                state.isError = false;
                state.isIngredientsDelete = true;
            } catch (error) {
                console.error('[DeleteIngredient.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(DeleteIngredient.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.DeleteIngredient = false;
                state.isError = true;
                state.isIngredientsDelete = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[DeleteIngredient.rejected] try catch error >>', error);
            }
        })
        builder.addCase(DeleteIngredient.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.DeleteIngredient = true;
        })
        //================== Medicineinfo
        builder.addCase(GetMedicineinfo.fulfilled, (state, { payload }) => {
            try {
                state.Medicineinfodata = payload;
                state.isFetchingOBJ.GetMedicineinfo = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetMedicineinfo.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetMedicineinfo.rejected, (state, { payload }) => {
            try {
                state.Medicineinfodata = [];
                state.isFetchingOBJ.GetMedicineinfo = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetMedicineinfo.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetMedicineinfo.pending, (state) => {
            state.isFetchingOBJ.GetMedicineinfo = true;
        })
        //---
        builder.addCase(AddMedicineinfo.fulfilled, (state, { payload }) => {
            try {
                let updated = payload.result;
                let existData = [...state.Medicineinfodata.data];
                let newData = [...existData, updated];
                state.Medicineinfodata.recordsTotal += 1;
                state.Medicineinfodata.recordsFiltered += 1;
                state.Medicineinfodata.data = newData;
                state.isMedicineinfoAdded = true;
                state.isFetching = false;
                state.isFetchingOBJ.AddMedicineinfo = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[AddMedicineinfo.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(AddMedicineinfo.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.AddMedicineinfo = false;
                state.isError = true;
                state.isMedicineinfoAdded = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[AddMedicineinfo.rejected] try catch error >>', error);
            }
        })
        builder.addCase(AddMedicineinfo.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.AddMedicineinfo = true;
        })
        //---
        builder.addCase(UpdateMedicineinfo.fulfilled, (state, { payload }) => {
            try {
                let exist = { ...state.Medicineinfodata };
                let updated = payload.result;
                const index = state.Medicineinfodata.data.findIndex(opt => opt.ID === updated.ID)
                exist.data[index] = updated;
                state.Medicineinfodata = exist;
                state.isMedicineinfoUpdated = true;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateMedicineinfo = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[UpdateMedicineinfo.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(UpdateMedicineinfo.rejected, (state, { payload }) => {
            try {
                state.isMedicineinfoUpdated = false;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateMedicineinfo = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[UpdateMedicineinfo.rejected] try catch error >>', error);
            }
        })
        builder.addCase(UpdateMedicineinfo.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.UpdateMedicineinfo = true;
        })
        //---
        builder.addCase(DeleteMedicineinfo.fulfilled, (state, { payload }) => {
            try {
                let exist = { ...state.Medicineinfodata };
                let newExist = exist.data?.filter((e) => {
                    if (e.ID !== payload.fileID) {
                        return e;
                    }
                    return false;
                });
                exist.data = newExist;
                state.Medicineinfodata = exist;
                state.isFetching = false;
                state.isFetchingOBJ.DeleteMedicineinfo = false;
                state.isError = false;
                state.isMedicineinfoDelete = true;
            } catch (error) {
                console.error('[DeleteMedicineinfo.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(DeleteMedicineinfo.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.DeleteMedicineinfo = false;
                state.isError = true;
                state.isMedicineinfoDelete = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[DeleteMedicineinfo.rejected] try catch error >>', error);
            }
        })
        builder.addCase(DeleteMedicineinfo.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.DeleteMedicineinfo = true;
        })
        //================== Patient List
        builder.addCase(SearchPatient.fulfilled, (state, { payload }) => {
            try {
                state.Patientdata = payload;
                state.isFetching = false;
                state.isFetchingOBJ.SearchPatient = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[SearchPatient.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(SearchPatient.rejected, (state, { payload }) => {
            try {
                state.Patientdata = [];
                state.isFetching = false;
                state.isFetchingOBJ.SearchPatient = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[SearchPatient.rejected] try catch error >>', error);
            }
        })
        builder.addCase(SearchPatient.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.SearchPatient = true;
        })
        //---
        builder.addCase(UpdatePatient.fulfilled, (state, { payload }) => {
            try {
                let exist = [...state.Patientdata];
                let updated = payload.result;
                const index = state.Patientdata.findIndex(opt => opt.client_id === updated.client_id)
                exist[index] = updated;
                state.Patientdata = exist;
                state.isPatientUpdated = true;
                state.isFetching = false;
                state.isFetchingOBJ.UpdatePatient = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[UpdatePatient.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(UpdatePatient.rejected, (state, { payload }) => {
            try {
                state.isPatientUpdated = false;
                state.isFetching = false;
                state.isFetchingOBJ.UpdatePatient = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[UpdatePatient.rejected] try catch error >>', error);
            }
        })
        builder.addCase(UpdatePatient.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.UpdatePatient = true;
        })
        //---
        builder.addCase(DeletePatient.fulfilled, (state, { payload }) => {
            try {
                let exist = [...state.Patientdata];
                let newExist = exist?.filter((e) => {
                    if (e.client_id !== payload.ID) {
                        return e;
                    }
                    return false;
                });
                exist = newExist;
                state.Patientdata = exist;
                state.isFetching = false;
                state.isFetchingOBJ.DeletePatient = false;
                state.isError = false;
                state.isPatientDelete = true;
            } catch (error) {
                console.error('[DeletePatient.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(DeletePatient.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.DeletePatient = false;
                state.isError = true;
                state.isPatientDelete = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[DeletePatient.rejected] try catch error >>', error);
            }
        })
        builder.addCase(DeletePatient.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.DeletePatient = true;
        })
        //================== Price List
        builder.addCase(GetPriceList.fulfilled, (state, { payload }) => {
            try {
                state.PriceListdata = payload;
                state.isFetchingOBJ.GetPriceList = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetPriceList.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetPriceList.rejected, (state, { payload }) => {
            try {
                state.PriceListdata = [];
                state.isFetchingOBJ.GetPriceList = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetPriceList.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetPriceList.pending, (state) => {
            state.isFetchingOBJ.GetPriceList = true;
        })
        //---
        builder.addCase(AddPriceList.fulfilled, (state, { payload }) => {
            try {
                let updated = payload.result;
                let existData = [...state.PriceListdata.data];
                let newData = [...existData, updated];
                state.PriceListdata.recordsTotal += 1;
                state.PriceListdata.recordsFiltered += 1;
                state.PriceListdata.data = newData;
                state.isPriceListAdded = true;
                state.isFetching = false;
                state.isFetchingOBJ.AddPriceList = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[AddPriceList.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(AddPriceList.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.AddPriceList = false;
                state.isError = true;
                state.isPriceListAdded = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[AddPriceList.rejected] try catch error >>', error);
            }
        })
        builder.addCase(AddPriceList.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.AddPriceList = true;
        })
        //---
        builder.addCase(UpdatePriceList.fulfilled, (state, { payload }) => {
            try {
                let exist = { ...state.PriceListdata };
                let updated = payload.result;
                const index = state.PriceListdata.data.findIndex(opt => opt.Id === updated.Id)
                exist.data[index] = updated;
                state.PriceListdata = exist;
                state.isPriceListUpdated = true;
                state.isFetching = false;
                state.isFetchingOBJ.UpdatePriceList = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[UpdatePriceList.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(UpdatePriceList.rejected, (state, { payload }) => {
            try {
                state.isPriceListUpdated = false;
                state.isFetching = false;
                state.isFetchingOBJ.UpdatePriceList = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[UpdatePriceList.rejected] try catch error >>', error);
            }
        })
        builder.addCase(UpdatePriceList.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.UpdatePriceList = true;
        })
        //---
        builder.addCase(DeletePriceList.fulfilled, (state, { payload }) => {
            try {
                let exist = { ...state.PriceListdata };
                let newExist = exist.data?.filter((e) => {
                    if (e.Id !== payload.ID) {
                        return e;
                    }
                    return false;
                });
                exist.data = newExist;
                state.PriceListdata = exist;
                state.isFetching = false;
                state.isFetchingOBJ.DeletePriceList = false;
                state.isError = false;
                state.isPriceListDelete = true;
            } catch (error) {
                console.error('[DeletePriceList.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(DeletePriceList.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.DeletePriceList = false;
                state.isError = true;
                state.isPriceListDelete = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[DeletePriceList.rejected] try catch error >>', error);
            }
        })
        builder.addCase(DeletePriceList.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.DeletePriceList = true;
        })
        //---
        builder.addCase(Productimport.fulfilled, (state, { payload }) => {
            try {
                let updated = payload.result;
                let existData = [...state.PriceListdata.data];
                let newData = [...existData, updated];
                state.PriceListdata.recordsTotal += 1;
                state.PriceListdata.recordsFiltered += 1;
                state.PriceListdata.data = newData;
                state.isProductimport = true;
                state.isFetching = false;
                state.isFetchingOBJ.Productimport = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[Productimport.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(Productimport.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.Productimport = false;
                state.isError = true;
                state.isProductimport = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[Productimport.rejected] try catch error >>', error);
            }
        })
        builder.addCase(Productimport.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.Productimport = true;
        })
        //---
        builder.addCase(DeleteallProduct.fulfilled, (state, { payload }) => {
            try {
                state.PriceListdata = [];
                state.isFetching = false;
                state.isFetchingOBJ.DeleteallProduct = false;
                state.isError = false;
                state.isPriceListDelete = true;
            } catch (error) {
                console.error('[DeleteallProduct.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(DeleteallProduct.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.DeleteallProduct = false;
                state.isError = true;
                state.isPriceListDelete = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[DeleteallProduct.rejected] try catch error >>', error);
            }
        })
        builder.addCase(DeleteallProduct.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.DeleteallProduct = true;
        })
        //---
        builder.addCase(ImportRxOneProducts.fulfilled, (state, { payload }) => {
            try {
                state.isRxoneproduct = true;
                state.isFetchingOBJ.ImportRxOneProducts = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[ImportRxOneProducts.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(ImportRxOneProducts.rejected, (state, { payload }) => {
            try {
                state.isRxoneproduct = false;
                state.isFetchingOBJ.ImportRxOneProducts = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[ImportRxOneProducts.rejected] try catch error >>', error);
            }
        })
        builder.addCase(ImportRxOneProducts.pending, (state) => {
            state.isFetchingOBJ.ImportRxOneProducts = true;
        })
        //================== QAQCTemplates
        builder.addCase(GetQAQCTemplates.fulfilled, (state, { payload }) => {
            try {
                state.QAQCTemplatesdata = payload;
                state.isFetchingOBJ.GetQAQCTemplates = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetQAQCTemplates.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetQAQCTemplates.rejected, (state, { payload }) => {
            try {
                state.QAQCTemplatesdata = [];
                state.isFetchingOBJ.GetQAQCTemplates = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetQAQCTemplates.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetQAQCTemplates.pending, (state) => {
            state.isFetchingOBJ.GetQAQCTemplates = true;
        })
        //---
        builder.addCase(GetQAQCTemplatesbybetchno.fulfilled, (state, { payload }) => {
            try {
                state.QAQCTemplatesdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.GetQAQCTemplatesbybetchno = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetQAQCTemplatesbybetchno.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetQAQCTemplatesbybetchno.rejected, (state, { payload }) => {
            try {
                state.QAQCTemplatesdata = [];
                state.isFetching = false;
                state.isFetchingOBJ.GetQAQCTemplatesbybetchno = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetQAQCTemplatesbybetchno.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetQAQCTemplatesbybetchno.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetQAQCTemplatesbybetchno = true;
        })
        //---
        builder.addCase(AddQAQCTemplates.fulfilled, (state, { payload }) => {
            try {
                let updated = payload.result;
                let existData = [...state.QAQCTemplatesdata.data];
                let newData = [...existData, updated];
                state.QAQCTemplatesdata.recordsTotal += 1;
                state.QAQCTemplatesdata.recordsFiltered += 1;
                state.QAQCTemplatesdata.data = newData;
                state.isQAQCTemplatesAdded = true;
                state.isFetching = false;
                state.isFetchingOBJ.AddQAQCTemplates = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[AddQAQCTemplates.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(AddQAQCTemplates.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.AddQAQCTemplates = false;
                state.isError = true;
                state.isQAQCTemplatesAdded = false;
                state.errorMessage = (payload?.err) ? (payload.err) : ((payload?.error?.data?.Message) ? payload.error.data.Message : (payload?.error ? payload.error : "API Response Invalid."));
            } catch (error) {
                console.error('[AddQAQCTemplates.rejected] try catch error >>', error);
            }
        })
        builder.addCase(AddQAQCTemplates.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.AddQAQCTemplates = true;
        })
        //---
        builder.addCase(UpdateQAQCTemplates.fulfilled, (state, { payload }) => {
            try {
                let exist = { ...state.QAQCTemplatesdata };
                let updated = payload.result;
                const index = state.QAQCTemplatesdata.data.findIndex(opt => opt.ID === updated.ID)
                exist.data[index] = updated;
                state.QAQCTemplatesdata = exist;
                state.isQAQCTemplatesUpdated = true;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateQAQCTemplates = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[UpdateQAQCTemplates.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(UpdateQAQCTemplates.rejected, (state, { payload }) => {
            try {
                state.isQAQCTemplatesUpdated = false;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateQAQCTemplates = false;
                state.isError = true;
                state.errorMessage = (payload?.err) ? (payload.err) : ((payload?.error?.data?.Message) ? payload.error.data.Message : (payload?.error ? payload.error : "API Response Invalid."));
            } catch (error) {
                console.error('[UpdateQAQCTemplates.rejected] try catch error >>', error);
            }
        })
        builder.addCase(UpdateQAQCTemplates.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.UpdateQAQCTemplates = true;
        })
        //---
        builder.addCase(DeleteQAQCTemplates.fulfilled, (state, { payload }) => {
            try {
                let exist = { ...state.QAQCTemplatesdata };
                let newExist = exist.data?.filter((e) => {
                    if (e.ID !== payload.ID) {
                        return e;
                    }
                    return false;
                });
                exist.data = newExist;
                state.QAQCTemplatesdata = exist;
                state.isFetching = false;
                state.isFetchingOBJ.DeleteQAQCTemplates = false;
                state.isError = false;
                state.isQAQCTemplatesDelete = true;
            } catch (error) {
                console.error('[DeleteQAQCTemplates.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(DeleteQAQCTemplates.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.DeleteQAQCTemplates = false;
                state.isError = true;
                state.isQAQCTemplatesDelete = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[DeleteQAQCTemplates.rejected] try catch error >>', error);
            }
        })
        builder.addCase(DeleteQAQCTemplates.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.DeleteQAQCTemplates = true;
        })
        //---
        builder.addCase(GetQAQCTemplatesphoto.fulfilled, (state, { payload }) => {
            try {
                state.QAQCTemplatesphotodata = payload.data;
                state.isFetching = false;
                state.isFetchingOBJ.GetQAQCTemplatesphoto = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetQAQCTemplatesphoto.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetQAQCTemplatesphoto.rejected, (state, { payload }) => {
            try {
                state.QAQCTemplatesphotodata = [];
                state.isFetching = false;
                state.isFetchingOBJ.GetQAQCTemplatesphoto = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetQAQCTemplatesphoto.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetQAQCTemplatesphoto.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetQAQCTemplatesphoto = true;
        })
        //---
        builder.addCase(AddQAQCTemplatesphoto.fulfilled, (state, { payload }) => {
            try {
                let updated = payload.result;
                state.QAQCTemplatesphotodata = updated;
                state.isQAQCTemplatesphotoAdded = true;
                state.isFetching = false;
                state.isFetchingOBJ.AddQAQCTemplatesphoto = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[AddQAQCTemplatesphoto.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(AddQAQCTemplatesphoto.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.AddQAQCTemplatesphoto = false;
                state.isError = true;
                state.isQAQCTemplatesphotoAdded = false;
                state.errorMessage = (payload?.err) ? (payload.err) : ((payload?.error?.data?.Message) ? payload.error.data.Message : (payload?.error ? payload.error : "API Response Invalid."));
            } catch (error) {
                console.error('[AddQAQCTemplatesphoto.rejected] try catch error >>', error);
            }
        })
        builder.addCase(AddQAQCTemplatesphoto.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.AddQAQCTemplatesphoto = true;
        })
        //---
        builder.addCase(DeleteQAQCTemplatesphoto.fulfilled, (state, { payload }) => {
            try {
                let exist = [...state.QAQCTemplatesphotodata];
                let newExist = exist?.filter((e) => {
                    if (e.ID !== payload.PhotoID) {
                        return e;
                    }
                    return false;
                });
                exist = newExist;
                state.QAQCTemplatesphotodata = exist;
                state.isFetching = false;
                state.isFetchingOBJ.DeleteQAQCTemplatesphoto = false;
                state.isError = false;
                state.isQAQCTemplatesphotoDelete = true;
            } catch (error) {
                console.error('[DeleteAddQAQCTemplatesphoto.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(DeleteQAQCTemplatesphoto.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.DeleteQAQCTemplatesphoto = false;
                state.isError = true;
                state.isQAQCTemplatesphotoDelete = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[DeleteAddQAQCTemplatesphoto.rejected] try catch error >>', error);
            }
        })
        builder.addCase(DeleteQAQCTemplatesphoto.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.DeleteQAQCTemplatesphoto = true;
        })
        //================== SMSTemplates
        builder.addCase(GetSmsTemplates.fulfilled, (state, { payload }) => {
            try {
                state.SmsTemplatesdata = payload;
                state.isFetchingOBJ.GetSmsTemplates = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetSmsTemplates.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetSmsTemplates.rejected, (state, { payload }) => {
            try {
                state.SmsTemplatesdata = [];
                state.isFetchingOBJ.GetSmsTemplates = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetSmsTemplates.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetSmsTemplates.pending, (state) => {
            state.isFetchingOBJ.GetSmsTemplates = true;
        })
        //---
        builder.addCase(UpdateSmsTemplates.fulfilled, (state, { payload }) => {
            try {
                let exist = { ...state.SmsTemplatesdata };
                let updated = payload.result;
                const index = state.SmsTemplatesdata.data.findIndex(opt => opt.Id === updated.Id)
                exist.data[index] = updated;
                state.SmsTemplatesdata = exist;
                state.isSmsTemplatesUpdated = true;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateSmsTemplates = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[UpdateSmsTemplates.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(UpdateSmsTemplates.rejected, (state, { payload }) => {
            try {
                state.isSmsTemplatesUpdated = false;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateSmsTemplates = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[UpdateSmsTemplates.rejected] try catch error >>', error);
            }
        })
        builder.addCase(UpdateSmsTemplates.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.UpdateSmsTemplates = true;
        })
        //================== TrainingVideos
        builder.addCase(GetTrainingVideos.fulfilled, (state, { payload }) => {
            try {
                state.TrainingVideosdata = payload;
                state.isFetchingOBJ.GetTrainingVideos = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetTrainingVideos.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetTrainingVideos.rejected, (state, { payload }) => {
            try {
                state.TrainingVideosdata = [];
                state.isFetchingOBJ.GetTrainingVideos = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetTrainingVideos.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetTrainingVideos.pending, (state) => {
            state.isFetchingOBJ.GetTrainingVideos = true;
        })
        //---
        builder.addCase(AddTrainingVideos.fulfilled, (state, { payload }) => {
            try {
                let updated = payload.result;
                let existData = [...state.TrainingVideosdata.data];
                let newData = [...existData, updated];
                state.TrainingVideosdata.recordsTotal += 1;
                state.TrainingVideosdata.recordsFiltered += 1;
                state.TrainingVideosdata.data = newData;
                state.isTrainingVideosAdded = true;
                state.isFetching = false;
                state.isFetchingOBJ.AddTrainingVideos = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[AddTrainingVideos.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(AddTrainingVideos.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.AddTrainingVideos = false;
                state.isError = true;
                state.isTrainingVideosAdded = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[AddTrainingVideos.rejected] try catch error >>', error);
            }
        })
        builder.addCase(AddTrainingVideos.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.AddTrainingVideos = true;
        })
        //---
        builder.addCase(UpdateTrainingVideos.fulfilled, (state, { payload }) => {
            try {
                let exist = { ...state.TrainingVideosdata };
                let updated = payload.result;
                const index = state.TrainingVideosdata.data.findIndex(opt => opt.ID === updated.ID)
                exist.data[index] = updated;
                state.TrainingVideosdata = exist;
                state.isTrainingVideosUpdated = true;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateTrainingVideos = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[UpdateTrainingVideos.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(UpdateTrainingVideos.rejected, (state, { payload }) => {
            try {
                state.isTrainingVideosUpdated = false;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateTrainingVideos = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[UpdateTrainingVideos.rejected] try catch error >>', error);
            }
        })
        builder.addCase(UpdateTrainingVideos.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.UpdateTrainingVideos = true;
        })
        //---
        builder.addCase(DeleteTrainingVideos.fulfilled, (state, { payload }) => {
            try {
                let exist = { ...state.TrainingVideosdata };
                let newExist = exist.data?.filter((e) => {
                    if (e.ID !== payload.ID) {
                        return e;
                    }
                    return false;
                });
                exist.data = newExist;
                state.TrainingVideosdata = exist;
                state.isFetching = false;
                state.isFetchingOBJ.DeleteTrainingVideos = false;
                state.isError = false;
                state.isTrainingVideosDelete = true;
            } catch (error) {
                console.error('[DeleteTrainingVideos.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(DeleteTrainingVideos.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.DeleteTrainingVideos = false;
                state.isError = true;
                state.isTrainingVideosDelete = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[DeleteTrainingVideos.rejected] try catch error >>', error);
            }
        })
        builder.addCase(DeleteTrainingVideos.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.DeleteTrainingVideos = true;
        })
        //================== TrocheFlavour
        builder.addCase(GetTrocheFlavour.fulfilled, (state, { payload }) => {
            try {
                state.TrocheFlavourdata = payload;
                state.isFetchingOBJ.GetTrocheFlavour = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetTrocheFlavour.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetTrocheFlavour.rejected, (state, { payload }) => {
            try {
                state.TrocheFlavourdata = [];
                state.isFetchingOBJ.GetTrocheFlavour = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetTrocheFlavour.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetTrocheFlavour.pending, (state) => {
            state.isFetchingOBJ.GetTrocheFlavour = true;
        })
        //---
        builder.addCase(AddTrocheFlavour.fulfilled, (state, { payload }) => {
            try {
                let updated = payload.result;
                let existData = [...state.TrocheFlavourdata.data];
                let newData = [...existData, updated];
                state.TrocheFlavourdata.recordsTotal += 1;
                state.TrocheFlavourdata.recordsFiltered += 1;
                state.TrocheFlavourdata.data = newData;
                state.isTrocheFlavourAdded = true;
                state.isFetching = false;
                state.isFetchingOBJ.AddTrocheFlavour = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[AddTrocheFlavour.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(AddTrocheFlavour.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.AddTrocheFlavour = false;
                state.isError = true;
                state.isTrocheFlavourAdded = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[AddTrocheFlavour.rejected] try catch error >>', error);
            }
        })
        builder.addCase(AddTrocheFlavour.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.AddTrocheFlavour = true;
        })
        //---
        builder.addCase(DeleteTrocheFlavour.fulfilled, (state, { payload }) => {
            try {
                let exist = { ...state.TrocheFlavourdata };
                let newExist = exist.data?.filter((e) => {
                    if (e.trocheid !== payload.ID) {
                        return e;
                    }
                    return false;
                });
                exist.data = newExist;
                state.TrocheFlavourdata = exist;
                state.isFetching = false;
                state.isFetchingOBJ.DeleteTrocheFlavour = false;
                state.isError = false;
                state.isTrocheFlavourDelete = true;
            } catch (error) {
                console.error('[DeleteTrocheFlavour.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(DeleteTrocheFlavour.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.DeleteTrocheFlavour = false;
                state.isError = true;
                state.isTrocheFlavourDelete = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[DeleteTrocheFlavour.rejected] try catch error >>', error);
            }
        })
        builder.addCase(DeleteTrocheFlavour.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.DeleteTrocheFlavour = true;
        })
        //================== Teammember
        builder.addCase(Getteammember.fulfilled, (state, { payload }) => {
            try {
                state.Teammemberdata = payload;
                state.isFetchingOBJ.Getteammember = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[Getteammember.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(Getteammember.rejected, (state, { payload }) => {
            try {
                state.Teammemberdata = [];
                state.isFetchingOBJ.Getteammember = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[Getteammember.rejected] try catch error >>', error);
            }
        })
        builder.addCase(Getteammember.pending, (state) => {
            state.isFetchingOBJ.Getteammember = true;
        })
        //---
        builder.addCase(AddTeamMember.fulfilled, (state, { payload }) => {
            try {
                let updated = payload.result;
                let existData = [...state.Teammemberdata.data];
                let newData = [...existData, updated];
                state.Teammemberdata.recordsTotal += 1;
                state.Teammemberdata.recordsFiltered += 1;
                state.Teammemberdata.data = newData;
                state.isTeammemberdataAdded = true;
                state.isFetching = false;
                state.isFetchingOBJ.AddTeamMember = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[AddTeamMember.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(AddTeamMember.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.AddTeamMember = false;
                state.isError = true;
                state.isTeammemberdataAdded = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[AddTeamMember.rejected] try catch error >>', error);
            }
        })
        builder.addCase(AddTeamMember.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.AddTeamMember = true;
        })
        //---
        builder.addCase(UpdateTeammember.fulfilled, (state, { payload }) => {
            try {
                let exist = { ...state.Teammemberdata };
                let updated = payload.result;
                const index = state.Teammemberdata.data.findIndex(opt => opt.client_id === updated.client_id)
                exist.data[index] = updated;
                state.Teammemberdata = exist;
                state.isTeammemberdataUpdated = true;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateTeammember = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[UpdateTeammember.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(UpdateTeammember.rejected, (state, { payload }) => {
            try {
                state.isTeammemberdataUpdated = false;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateTeammember = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[UpdateTeammember.rejected] try catch error >>', error);
            }
        })
        builder.addCase(UpdateTeammember.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.UpdateTeammember = true;
        })
        //---
        builder.addCase(DeleteTeamMember.fulfilled, (state, { payload }) => {
            try {
                let exist = { ...state.Teammemberdata };
                let newExist = exist.data?.filter((e) => {
                    if (e.client_id !== payload.ID) {
                        return e;
                    }
                    return false;
                });
                exist.data = newExist;
                state.Teammemberdata = exist;
                state.isFetching = false;
                state.isFetchingOBJ.DeleteTeamMember = false;
                state.isError = false;
                state.isTeammemberdataDelete = true;
            } catch (error) {
                console.error('[DeleteTeamMember.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(DeleteTeamMember.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.DeleteTeamMember = false;
                state.isError = true;
                state.isTeammemberdataDelete = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[DeleteTeamMember.rejected] try catch error >>', error);
            }
        })
        builder.addCase(DeleteTeamMember.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.DeleteTeamMember = true;
        })
        //---
        builder.addCase(statusUpdateTeammember.fulfilled, (state, { payload }) => {
            try {
                let exist = { ...state.Teammemberdata };
                let updated = payload.result;
                const index = state.Teammemberdata.data.findIndex(opt => opt.client_id === updated.client_id)
                exist.data[index] = updated;
                state.Teammemberdata = exist;
                state.isTeammemberdatastatus = true;
                state.isFetching = false;
                state.isFetchingOBJ.statusUpdateTeammember = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[statusUpdateTeammember.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(statusUpdateTeammember.rejected, (state, { payload }) => {
            try {
                state.isTeammemberdatastatus = false;
                state.isFetching = false;
                state.isFetchingOBJ.statusUpdateTeammember = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[statusUpdateTeammember.rejected] try catch error >>', error);
            }
        })
        builder.addCase(statusUpdateTeammember.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.statusUpdateTeammember = true;
        })
        //================== General Setting
        builder.addCase(GetGeneralSettings.fulfilled, (state, { payload }) => {
            try {
                state.GeneralSettingsdata = payload.data;
                state.isFetching = false;
                state.isFetchingOBJ.GetGeneralSettings = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetGeneralSettings.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetGeneralSettings.rejected, (state, { payload }) => {
            try {
                state.GeneralSettingsdata = [];
                state.isFetching = false;
                state.isFetchingOBJ.GetGeneralSettings = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetGeneralSettings.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetGeneralSettings.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetGeneralSettings = true;
        })
        //---
        builder.addCase(UpdateGeneralsetting.fulfilled, (state, { payload }) => {
            try {
                let exist = [...state.GeneralSettingsdata];
                let updated = payload.result;
                const index = state.GeneralSettingsdata.findIndex(opt => opt.ID === updated.ID)
                exist[index] = updated;
                state.GeneralSettingsdata = exist;
                state.isGeneralSettingsUpdated = true;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateGeneralsetting = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[UpdateGeneralsetting.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(UpdateGeneralsetting.rejected, (state, { payload }) => {
            try {
                state.isGeneralSettingsUpdated = false;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateGeneralsetting = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[UpdateGeneralsetting.rejected] try catch error >>', error);
            }
        })
        builder.addCase(UpdateGeneralsetting.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.UpdateGeneralsetting = true;
        })
        //================== Order Type
        builder.addCase(Getordertype.fulfilled, (state, { payload }) => {
            try {
                state.Ordertypedata = payload;
                state.isFetchingOBJ.Getordertype = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[Getordertype.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(Getordertype.rejected, (state, { payload }) => {
            try {
                state.Ordertypedata = [];
                state.isFetchingOBJ.Getordertype = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[Getordertype.rejected] try catch error >>', error);
            }
        })
        builder.addCase(Getordertype.pending, (state) => {
            state.isFetchingOBJ.Getordertype = true;
        })
        //---
        builder.addCase(AddOrdertype.fulfilled, (state, { payload }) => {
            try {
                let updated = payload.result;
                let existData = [...state.Ordertypedata.data];
                let newData = [...existData, updated];
                state.Ordertypedata.recordsTotal += 1;
                state.Ordertypedata.recordsFiltered += 1;
                state.Ordertypedata.data = newData;
                state.isOrdertypedataAdded = true;
                state.isFetching = false;
                state.isFetchingOBJ.AddOrdertype = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[AddOrdertype.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(AddOrdertype.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.AddOrdertype = false;
                state.isError = true;
                state.isOrdertypedataAdded = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[AddOrdertype.rejected] try catch error >>', error);
            }
        })
        builder.addCase(AddOrdertype.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.AddOrdertype = true;
        })
        //---
        builder.addCase(UpdateOrdertype.fulfilled, (state, { payload }) => {
            try {
                let exist = { ...state.Ordertypedata };
                let updated = payload.result;
                const index = state.Ordertypedata.data.findIndex(opt => opt.Id === updated.Id)
                exist.data[index] = updated;
                state.Ordertypedata = exist;
                state.isOrdertypedataUpdated = true;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateOrdertype = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[UpdateOrdertype.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(UpdateOrdertype.rejected, (state, { payload }) => {
            try {
                state.isOrdertypedataUpdated = false;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateOrdertype = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[UpdateOrdertype.rejected] try catch error >>', error);
            }
        })
        builder.addCase(UpdateOrdertype.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.UpdateOrdertype = true;
        })
        //---
        builder.addCase(DeleteOrdertype.fulfilled, (state, { payload }) => {
            try {
                let exist = { ...state.Ordertypedata };
                let newExist = exist.data?.filter((e) => {
                    if (e.Id !== payload.ID) {
                        return e;
                    }
                    return false;
                });
                exist.data = newExist;
                state.Ordertypedata = exist;
                state.isFetching = false;
                state.isFetchingOBJ.DeleteOrdertype = false;
                state.isError = false;
                state.isOrdertypedataDelete = true;
            } catch (error) {
                console.error('[DeleteOrdertype.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(DeleteOrdertype.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.DeleteOrdertype = false;
                state.isError = true;
                state.isOrdertypedataDelete = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[DeleteOrdertype.rejected] try catch error >>', error);
            }
        })
        builder.addCase(DeleteOrdertype.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.DeleteOrdertype = true;
        })
        //-----
        builder.addCase(getOptionforitems.fulfilled, (state, { payload }) => {
            try {
                state.optionforitemData = payload
                state.isFetching = false;
                state.isFetchingOBJ.getOptionforitems = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[getOptionforitems.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(getOptionforitems.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.getOptionforitems = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[getOptionforitems.rejected] try catch error >>', error);
            }
        })
        builder.addCase(getOptionforitems.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.getOptionforitems = true;
        })
        //---
        builder.addCase(AddOptionforitems.fulfilled, (state, { payload }) => {
            try {
                let updated = payload.result;
                let existData = [...state.optionforitemData.data];
                let newData = [...existData, updated];
                state.optionforitemData.recordsTotal += 1;
                state.optionforitemData.recordsFiltered += 1;
                state.optionforitemData.data = newData;
                state.isoptionforitemAdded = true;
                state.isFetching = false;
                state.isFetchingOBJ.AddOptionforitems = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[AddOptionforitems.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(AddOptionforitems.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.AddOptionforitems = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[AddOptionforitems.rejected] try catch error >>', error);
            }
        })
        builder.addCase(AddOptionforitems.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.AddOptionforitems = true;
        })
        //-----
        builder.addCase(UpdateOptionforitems.fulfilled, (state, { payload }) => {
            try {
                let exist = { ...state.optionforitemData };
                let updated = payload.result;
                const index = state.optionforitemData.data.findIndex(opt => opt.ID === updated.ID)
                exist.data[index] = updated;
                state.optionforitemData = exist;
                state.isoptionforitemUpdated = true;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateOptionforitems = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[UpdateOrdertype.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(UpdateOptionforitems.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.UpdateOptionforitems = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[UpdateOptionforitems.rejected] try catch error >>', error);
            }
        })
        builder.addCase(UpdateOptionforitems.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.UpdateOptionforitems = true;
        })
        //----
        builder.addCase(DeleteOptionforitems.fulfilled, (state, { payload }) => {
            try {
                let exist = { ...state.optionforitemData };
                let newExist = exist.data?.filter((e) => e.ID !== payload.ID);
                newExist.recordsTotal -= 1;
                newExist.recordsFiltered -= 1;
                exist.data = newExist;
                state.optionforitemData = exist;
                state.isOptionforitemDelete = true;
                state.isFetching = false;
                state.isFetchingOBJ.DeleteOptionforitems = false;
                state.isError = false;
            } catch (error) {
                console.error('[DeleteOptionforitems.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(DeleteOptionforitems.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.DeleteOptionforitems = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[DeleteOptionforitems.rejected] try catch error >>', error);
            }
        })
        builder.addCase(DeleteOptionforitems.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.DeleteOptionforitems = true;
        })
        //================== Clinic products
        builder.addCase(GetClinicProducts.fulfilled, (state, { payload }) => {
            try {
                let updated = payload.data;
                state.ClinicProductsdata = updated;
                state.isFetching = false;
                state.isFetchingOBJ.GetClinicProducts = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetClinicProducts.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetClinicProducts.rejected, (state, { payload }) => {
            try {
                state.ClinicProductsdata = [];
                state.isFetching = false;
                state.isFetchingOBJ.GetClinicProducts = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetClinicProducts.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetClinicProducts.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetClinicProducts = true;
        })
        //---
        builder.addCase(AddClinicProducts.fulfilled, (state, { payload }) => {
            try {
                let updated = payload.result;
                let existData = [...state.ClinicProductsdata];
                let newData = [...existData, updated];
                state.ClinicProductsdata = newData;
                state.isClinicproductAdded = true;
                state.isFetching = false;
                state.isFetchingOBJ.AddClinicProducts = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[AddClinicProducts.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(AddClinicProducts.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.AddClinicProducts = false;
                state.isError = true;
                state.isClinicproductAdded = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[AddClinicProducts.rejected] try catch error >>', error);
            }
        })
        builder.addCase(AddClinicProducts.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.AddClinicProducts = true;
        })
        //---
        builder.addCase(UpdateClinicproduct.fulfilled, (state, { payload }) => {
            try {
                let exist = [...state.ClinicProductsdata];
                let updated = payload.result;
                const index = state.ClinicProductsdata.findIndex(opt => opt.ID === updated.ID)
                exist[index] = updated;
                state.ClinicProductsdata = exist;
                state.isClinicproductUpdated = true;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateClinicproduct = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[UpdateClinicproduct.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(UpdateClinicproduct.rejected, (state, { payload }) => {
            try {
                state.isClinicproductUpdated = false;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateClinicproduct = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[UpdateClinicproduct.rejected] try catch error >>', error);
            }
        })
        builder.addCase(UpdateClinicproduct.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.UpdateClinicproduct = true;
        })
        //---
        builder.addCase(DeleteClinicProducts.fulfilled, (state, { payload }) => {
            try {
                let exist = [...state.ClinicProductsdata];
                let newExist = exist?.filter((e) => {
                    if (e.ID !== payload.ID) {
                        return e;
                    }
                    return false;
                });
                exist = newExist;
                state.ClinicProductsdata = exist;
                state.isFetching = false;
                state.isFetchingOBJ.DeleteClinicProducts = false;
                state.isError = false;
                state.isClinicproductDeleted = true;
            } catch (error) {
                console.error('[DeleteClinicProducts.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(DeleteClinicProducts.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.DeleteClinicProducts = false;
                state.isError = true;
                state.isClinicproductDeleted = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[DeleteClinicProducts.rejected] try catch error >>', error);
            }
        })
        builder.addCase(DeleteClinicProducts.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.DeleteClinicProducts = true;
        })
        //---
        builder.addCase(ImportClinicProduct.fulfilled, (state, { payload }) => {
            try {
                state.isClinicproductimport = true;
                state.isFetching = false;
                state.isFetchingOBJ.ImportClinicProduct = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[ImportClinicProduct.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(ImportClinicProduct.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.ImportClinicProduct = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[ImportClinicProduct.rejected] try catch error >>', error);
            }
        })
        builder.addCase(ImportClinicProduct.pending, (state) => {
            state.isFetching = false;
            state.isFetchingOBJ.ImportClinicProduct = false;
        })
        //---
        builder.addCase(ExportClinicProducts.fulfilled, (state, { payload }) => {
            try {
                state.ExportClinicProductsdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.ExportClinicProducts = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[ExportClinicProducts.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(ExportClinicProducts.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.ExportClinicProducts = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[ExportClinicProducts.rejected] try catch error >>', error);
            }
        })
        builder.addCase(ExportClinicProducts.pending, (state) => {
            state.isFetching = false;
            state.isFetchingOBJ.ExportClinicProducts = false;
        })
        //---
        builder.addCase(GetQAQCBatchList.fulfilled, (state, { payload }) => {
            try {
                if (state.hasmore) {
                    let updated = payload.data;
                    const newData = _.unionBy(state.QAQCBatchListdata, updated, 'ID');
                    state.QAQCBatchListdata = newData;
                } else {
                    state.QAQCBatchListdata = payload.data;
                }
                state.QAQCTotalBatchList = payload.recordsTotal;
                state.isFetching = false;
                state.isFetchingOBJ.GetQAQCBatchList = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetQAQCBatchList.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetQAQCBatchList.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.GetQAQCBatchList = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetQAQCBatchList.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetQAQCBatchList.pending, (state) => {
            state.isFetching = false;
            state.isFetchingOBJ.GetQAQCBatchList = false;
        })
        //---
        builder.addCase(QAQCbyTemplateid.fulfilled, (state, { payload }) => {
            try {
                state.QAQCbyTemplateiddata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.QAQCbyTemplateid = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[QAQCbyTemplateid.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(QAQCbyTemplateid.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.QAQCbyTemplateid = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[QAQCbyTemplateid.rejected] try catch error >>', error);
            }
        })
        builder.addCase(QAQCbyTemplateid.pending, (state) => {
            state.isFetching = false;
            state.isFetchingOBJ.QAQCbyTemplateid = false;
        })
        //---
        builder.addCase(AddQAOrderByBatch.fulfilled, (state, { payload }) => {
            try {
                let updated = payload.result;
                let existData = [...state.QAQCBatchListdata];
                let newData = [...existData, updated];
                state.QAQCBatchListdata = newData;
                state.isQaqcbybatchadded = true;
                state.isFetching = false;
                state.isFetchingOBJ.AddQAOrderByBatch = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[AddQAOrderByBatch.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(AddQAOrderByBatch.rejected, (state, { payload }) => {
            try {
                state.isQaqcbybatchadded = false;
                state.isFetching = false;
                state.isFetchingOBJ.AddQAOrderByBatch = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[AddQAOrderByBatch.rejected] try catch error >>', error);
            }
        })
        builder.addCase(AddQAOrderByBatch.pending, (state) => {
            state.isFetching = false;
            state.isFetchingOBJ.AddQAOrderByBatch = false;
        })
        //---
        builder.addCase(UpdateQAOrderByBatch.fulfilled, (state, { payload }) => {
            try {
                let exist = [...state.QAQCBatchListdata];
                let updated = payload.result;
                const index = state.QAQCBatchListdata.findIndex(opt => opt.ID === updated.ID)
                exist[index] = updated;
                state.QAQCBatchListdata = exist;
                state.isQaqcbybatchUpdated = true;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateQAOrderByBatch = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[UpdateQAOrderByBatch.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(UpdateQAOrderByBatch.rejected, (state, { payload }) => {
            try {
                state.isQaqcbybatchUpdated = false;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateQAOrderByBatch = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[UpdateQAOrderByBatch.rejected] try catch error >>', error);
            }
        })
        builder.addCase(UpdateQAOrderByBatch.pending, (state) => {
            state.isFetching = false;
            state.isFetchingOBJ.UpdateQAOrderByBatch = false;
        })
        //---
        builder.addCase(DeleteQAQCchecklist.fulfilled, (state, { payload }) => {
            try {
                let exist = [...state.QAQCBatchListdata];
                let newExist = exist?.filter((e) => {
                    if (e.ID !== payload.ID) {
                        return e;
                    }
                    return false;
                });
                exist = newExist;
                state.QAQCBatchListdata = exist;
                state.isQaqcbybatchDeleted = true;
                state.isFetching = false;
                state.isFetchingOBJ.DeleteQAQCchecklist = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[DeleteQAQCchecklist.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(DeleteQAQCchecklist.rejected, (state, { payload }) => {
            try {
                state.isQaqcbybatchDeleted = false;
                state.isFetching = false;
                state.isFetchingOBJ.DeleteQAQCchecklist = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[DeleteQAQCchecklist.rejected] try catch error >>', error);
            }
        })
        builder.addCase(DeleteQAQCchecklist.pending, (state) => {
            state.isFetching = false;
            state.isFetchingOBJ.DeleteQAQCchecklist = false;
        })
        //---
        builder.addCase(GetInternalTemplateByID.fulfilled, (state, { payload }) => {
            try {
                state.InternalTemplatebyiddata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.GetInternalTemplateByID = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetInternalTemplateByID.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetInternalTemplateByID.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.GetInternalTemplateByID = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetInternalTemplateByID.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetInternalTemplateByID.pending, (state) => {
            state.isFetching = false;
            state.isFetchingOBJ.GetInternalTemplateByID = false;
        })
        //---
        builder.addCase(GetallInternalTemplate.fulfilled, (state, { payload }) => {
            try {
                state.allInternalTemplatedata = payload.data;
                state.isFetching = false;
                state.isFetchingOBJ.GetallInternalTemplate = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetallInternalTemplate.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetallInternalTemplate.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.GetallInternalTemplate = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetallInternalTemplate.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetallInternalTemplate.pending, (state) => {
            state.isFetching = false;
            state.isFetchingOBJ.GetallInternalTemplate = false;
        })
        //---
        builder.addCase(GetInternalTemplate.fulfilled, (state, { payload }) => {
            try {
                state.InternalTemplatedata = payload;
                state.isFetching = false;
                state.isFetchingOBJ.GetInternalTemplate = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetInternalTemplate.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetInternalTemplate.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.GetInternalTemplate = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetInternalTemplate.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetInternalTemplate.pending, (state) => {
            state.isFetching = false;
            state.isFetchingOBJ.GetInternalTemplate = false;
        })
        //---
        builder.addCase(AddInternalTemplate.fulfilled, (state, { payload }) => {
            try {
                let updated = payload.result;
                let existData = [...state.InternalTemplatedata.data];
                let newData = [...existData, updated];
                state.InternalTemplatedata.recordsTotal += 1;
                state.InternalTemplatedata.recordsFiltered += 1;
                state.InternalTemplatedata.data = newData;
                state.isinternaltemplateAdded = true;
                state.isFetching = false;
                state.isFetchingOBJ.AddInternalTemplate = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[AddInternalTemplate.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(AddInternalTemplate.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.AddInternalTemplate = false;
                state.isError = true;
                state.isinternaltemplateAdded = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[AddInternalTemplate.rejected] try catch error >>', error);
            }
        })
        builder.addCase(AddInternalTemplate.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.AddInternalTemplate = true;
        })
        //----
        builder.addCase(UpdateInternalTemplate.fulfilled, (state, { payload }) => {
            try {
                let exist = { ...state.InternalTemplatedata };
                let updated = payload.result;
                const index = state.InternalTemplatedata.data.findIndex(opt => opt.TemplateID === updated.TemplateID)
                exist.data[index] = updated;
                state.InternalTemplatedata = exist;
                state.isinternaltemplateUpdated = true;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateInternalTemplate = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[UpdateInternalTemplate.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(UpdateInternalTemplate.rejected, (state, { payload }) => {
            try {
                state.isinternaltemplateUpdated = false;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateInternalTemplate = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[UpdateInternalTemplate.rejected] try catch error >>', error);
            }
        })
        builder.addCase(UpdateInternalTemplate.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.UpdateInternalTemplate = true;
        })
        //---
        builder.addCase(DeleteInternalTemplate.fulfilled, (state, { payload }) => {
            try {
                let exist = { ...state.InternalTemplatedata };
                let newExist = exist.data?.filter((e) => {
                    if (e.TemplateID !== payload.TemplateID) {
                        return e;
                    }
                    return false;
                });
                exist.data = newExist;
                state.InternalTemplatedata = exist;
                state.isFetching = false;
                state.isFetchingOBJ.DeleteInternalTemplate = false;
                state.isError = false;
                state.isinternaltemplateDelete = true;
            } catch (error) {
                console.error('[DeleteInternalTemplate.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(DeleteInternalTemplate.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.DeleteInternalTemplate = false;
                state.isError = true;
                state.isinternaltemplateDelete = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[DeleteInternalTemplate.rejected] try catch error >>', error);
            }
        })
        builder.addCase(DeleteInternalTemplate.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.DeleteInternalTemplate = true;
        })
    }
});
//======================
export const { updateState } = systemSlice.actions;
export const systemSelector = (state) => state.ncc.system;