import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../axios';
import CONFIG from '../../Utils/config';
import Service from '../../Utils/service';
import _ from 'lodash';
//---
const extractErrorMessage = (error) =>
    error?.response?.data?.Message ||
    error?.response?.data ||
    error?.response ||
    error?.message;
const extractErrorMessageRejected = (payload) =>
    payload?.response?.data?.Message?.toString() ||
    payload?.response?.data?.toString() ||
    payload?.response?.toString() ||
    payload?.err?.toString() ||
    payload?.data?.Message?.toString() ||
    payload?.data?.message?.toString() ||
    payload?.data?.toString() ||
    payload?.result?.toString() ||
    payload?.Message?.toString() ||
    payload?.message?.toString() ||
    payload?.error?.toString() ||
    "API Response Invalid.";
//---
export const ScheduledOrderToday = createAsyncThunk(
    'Order/ScheduledOrderToday',
    async (params, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Order/ScheduledOrderToday`, params: params, data: params, headers: authHeader });
            // console.log('ScheduledOrderToday result.data >>', result.data);
            if (result.data.success) {
                return result.data.result;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const OrderStatus = createAsyncThunk(
    'Order/OrderStatus',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Order/OrderStatus`, data: userdata, headers: authHeader });
            // console.log('OrderStatus result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const UpdateSterile = createAsyncThunk(
    'Order/UpdateSterile',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}Order/UpdateSterile`, params: userdata, headers: authHeader });
            // console.log('UpdateSterile result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const Getorderfromorderid = createAsyncThunk(
    'Order/SOrderID',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Order/SOrderID`, params: userdata, data: userdata, headers: authHeader });
            // console.log('Getorderfromorderid result.data >>', result.data);
            if (result.data.success) {
                return result.data.result;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const UpdateorderPrice = createAsyncThunk(
    'Order/UpdatePrice',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}Order/UpdatePrice`, params: userdata, data: userdata, headers: authHeader });
            // console.log('UpdateorderPrice result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const UpdateShopName = createAsyncThunk(
    'Order/UpdateShopName',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}Order/UpdateShopName`, params: userdata, data: userdata, headers: authHeader });
            // console.log('UpdateShopName result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetOrderComment = createAsyncThunk(
    'OrderComment/GetOrderComment',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}OrderComment`, data: userdata, headers: authHeader });
            // console.log('GetOrderComment result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const AddOrderComment = createAsyncThunk(
    'OrderComment/add',
    async (postdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}OrderComment/create`, data: postdata, headers: authHeader });
            // console.log('AddOrderComment result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetOrderfile = createAsyncThunk(
    'Order/GetOrderfile',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Order/SOrderFiles`, params: userdata, data: userdata, headers: authHeader });
            // console.log('GetOrderfile result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const AddOrderfile = createAsyncThunk(
    'Order/AddOrderfile',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader('multipart/form-data');
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Order/UploadOrderFiles`, data: userdata, headers: authHeader });
            // console.log('AddOrderfile result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const Addproductphoto = createAsyncThunk(
    'Order/Addproductphoto',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader('multipart/form-data');
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Order/UploadProductPhotosByOrderId`, data: userdata, headers: authHeader });
            // console.log('Addproductphoto result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const Deleteproductphoto = createAsyncThunk(
    'order/delete',
    async (param, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'DELETE', url: `${CONFIG.API_ENDPOINT}Order/ProductPhotoID`, params: param, headers: authHeader });
            // console.log('Deleteproductphoto result.data >>', result.data);
            if (result.data.success) {
                result.postdata = param;
                return result.postdata;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const Getorderproduct = createAsyncThunk(
    'Order/Getorderproduct',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Order/SOrderProduct`, params: userdata, data: userdata, headers: authHeader });
            // console.log('Getorderproduct result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const AddOrderproduct = createAsyncThunk(
    'Order/AddOrderproduct',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Order/SAddOrderProduct`, data: userdata, headers: authHeader });
            // console.log('AddOrderproduct result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const UpdateDueDate = createAsyncThunk(
    'Order/UpdateDueDate',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}Order/UpdateDueDate`, params: userdata, headers: authHeader });
            // console.log('UpdateDueDate result.data >>', result.data);
            if (result.data.success) {
                return result.data.result;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetorderStatus = createAsyncThunk(
    'Order/GetorderStatus',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Order/SOrderStatus`, params: userdata, data: userdata, headers: authHeader });
            // console.log('GetorderStatus result.data >>', result.data);
            if (result.data.success) {
                return result.data.result;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const getsmsclient = createAsyncThunk(
    'Order/getsmsclient',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Order/SSMSClient`, params: userdata, data: userdata, headers: authHeader });
            // console.log('getsmsclient result.data >>', result.data);
            if (result.data.success) {
                return result.data.result;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const Sendsmsclient = createAsyncThunk(
    'Order/Sendsmsclient',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Order/SendSMSClient`, data: userdata, headers: authHeader });
            // console.log('Sendsmsclient result.data >>', result.data);
            if (result.data.success) {
                return result.data.result;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const AddOrdermedicinefile = createAsyncThunk(
    'Order/AddOrdermedicinefile',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader('multipart/form-data');
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Order/UploadMedicineInfoFile`, data: userdata, headers: authHeader });
            // console.log('AddOrdermedicinefile result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const MedicineInfoByOrder = createAsyncThunk(
    'Order/MedicineInfoByOrder',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader('multipart/form-data');
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Order/MedicineInfoByOrder`, params: userdata, data: userdata, headers: authHeader });
            // console.log('MedicineInfoByOrder result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const MedicineInfoAll = createAsyncThunk(
    'Order/MedicineInfoAll',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader('multipart/form-data');
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Order/MedicineInfoAll`, params: userdata, data: userdata, headers: authHeader });
            // console.log('MedicineInfoAll result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const AddOrdermedicinefileall = createAsyncThunk(
    'Order/AddOrdermedicinefileall',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Order/UploadSelectMedicineInfoFile`, data: userdata, headers: authHeader });
            // console.log('AddOrdermedicinefileall result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const AddRiskassessment = createAsyncThunk(
    'Order/AddRiskassessment',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Order/Riskassessment`, data: userdata, headers: authHeader });
            // console.log('AddRiskassessment result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const Updateorderstatus = createAsyncThunk(
    'Order/Updateorderstatus',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}Order/UpdateOrderStatus`, data: userdata, headers: authHeader });
            // console.log('Updateorderstatus result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const UpdatePatient = createAsyncThunk(
    'Order/UpdatePatient',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}Order/UpdatePatient`, data: userdata, headers: authHeader });
            // console.log('UpdatePatient result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const PrintDetail = createAsyncThunk(
    'Order/PrintDetail',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Order/PrintDetail`, params: userdata, data: userdata, headers: authHeader });
            // console.log('PrintDetail result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const PrintAllDetail = createAsyncThunk(
    'Order/PrintAllDetail',
    async (objData, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Order/PrintAllDetail`, params: objData.params, data: objData.data, headers: authHeader });
            // console.log('PrintAllDetail result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const ReprintedLabelClinic = createAsyncThunk(
    'PrintedLabel/ReprintedLabelClinic',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}PrintedLabel/ReprintedLabelClinic`, params: userdata, data: userdata, headers: authHeader });
            // console.log('ReprintedLabelClinic result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const CheckPayment = createAsyncThunk(
    'Order/CheckPayment',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Order/CheckPayment`, params: userdata, data: userdata, headers: authHeader });
            // console.log('CheckPayment result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const SendPaymentPxCreate = createAsyncThunk(
    'InternalOrder/SendPaymentPxCreate',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}InternalOrder/SendPaymentPxCreate`, data: userdata, headers: authHeader });
            // console.log('SendPaymentPxCreate result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const TransaferToPaymentScreen = createAsyncThunk(
    'InternalOrder/TransaferToPaymentScreen',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}InternalOrder/TransaferToPaymentScreen`, params: userdata, data: userdata, headers: authHeader });
            // console.log('TransaferToPaymentScreen result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetProductPhotosByOrderId = createAsyncThunk(
    'Order/GetProductPhotosByOrderId',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Order/GetProductPhotosByOrderId`, params: userdata, data: userdata, headers: authHeader });
            // console.log('GetProductPhotosByOrderId result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const ReceivedOrder = createAsyncThunk(
    'Order/ReceivedOrder',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Order/ReceivedOrder`, params: userdata, data: userdata, headers: authHeader });
            // console.log('ReceivedOrder result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const RePrintHorderHisto = createAsyncThunk(
    'Order/RePrintHorderHisto',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Order/RePrintHorderHisto`, params: userdata, data: userdata, headers: authHeader });
            // console.log('RePrintHorderHisto result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const ClinicPatientListByOrder = createAsyncThunk(
    'ClinicOrder/ClinicPatientListByOrder',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}ClinicOrder/ClinicPatientListByOrder`, params: userdata, data: userdata, headers: authHeader });
            // console.log('ClinicPatientListByOrder result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const createClinicPatient = createAsyncThunk(
    'ClinicOrder/createClinicPatient',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}ClinicOrder/createClinicPatient`, data: userdata, headers: authHeader });
            // console.log('createClinicPatient result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const QAQCOrderbatchList = createAsyncThunk(
    'Order/QAQCOrderbatchList',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Order/QAQCOrderbatchList`, params: userdata, data: userdata, headers: authHeader });
            // console.log('QAQCOrderbatchList result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const ViewQaQCByOrderbatchId = createAsyncThunk(
    'Order/ViewQaQCByOrderbatchId',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Order/ViewQaQCByOrderbatchId`, params: userdata, data: userdata, headers: authHeader });
            // console.log('ViewQaQCByOrderbatchId result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const AddQAQCbyBatch = createAsyncThunk(
    'Order/AddQAQCbyBatch',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Order/AddQAQCbyBatch`, data: userdata, headers: authHeader });
            // console.log('AddQAQCbyBatch result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const DeleteQAQCbyBatch = createAsyncThunk(
    'Order/DeleteQAQCbyBatch',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'DELETE', url: `${CONFIG.API_ENDPOINT}Order/DeleteQAQCOrderBatch`, params: userdata, headers: authHeader });
            // console.log('DeleteQAQCbyBatch result.data >>', result.data);
            if (result.data.success) {
                result.postdata = userdata;
                return result.postdata;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const UpdateBulkOrderStatus = createAsyncThunk(
    'Order/UpdateBulkOrderStatus',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}Order/UpdateBulkOrderStatus`, data: userdata, headers: authHeader });
            // console.log('UpdateBulkOrderStatus result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const VoidTransaction = createAsyncThunk(
    'Order/VoidTransaction',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Order/VoidTransaction`, data: userdata, headers: authHeader });
            // console.log('VoidTransaction result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const RefundTransaction = createAsyncThunk(
    'Order/RefundTransaction',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Order/RefundTransaction`, params: userdata, data: userdata, headers: authHeader });
            // console.log('RefundTransaction result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const SOrderPrescription = createAsyncThunk(
    'Order/SOrderPrescription',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Order/SOrderPrescription`, params: userdata, data: userdata, headers: authHeader });
            // console.log('SOrderPrescription result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const ExternalSOrderPrescription = createAsyncThunk(
    'ExternalOrder/ExternalSOrderPrescription',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}ExternalOrder/SOrderPrescription`, params: userdata, data: userdata, headers: authHeader });
            // console.log('ExternalSOrderPrescription result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const SSManualPush = createAsyncThunk(
    'Order/SSManualPush',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Order/SSManualPush`, params: userdata, data: userdata, headers: authHeader });
            // console.log('SSManualPush result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const UpdateShipping = createAsyncThunk(
    'Order/UpdateShipping',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'PUT', url: `${CONFIG.API_ENDPOINT}Order/UpdateShipping`, params: userdata, data: userdata, headers: authHeader });
            // console.log('UpdateShipping result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const orderSlice = createSlice({
    name: 'order',
    initialState: {
        scheduledOrderTodaydata: [],
        ordersdata: {},
        allordersdata: {},
        Ordercommentdata: [],
        Orderfiledata: {},
        Orderproductdata: [],
        Orderstatusdata: [],
        UpdateBulkOrderStatusdata: [],
        Clientsmsdata: [],
        medicineFiledata: [],
        MedicineInfoAlldata: [],
        AddMedicineInfoAlldata: [],
        Riskassessmentdata: [],
        Updateorderstatusdata: {},
        PrintDetaildata: {},
        PrintAllDetaildata: [],
        ReprintedLabelClinicdata: [],
        CheckPaymentdata: null,
        SendPaymentPxCreatedata: null,
        TransaferToPaymentScreendata: null,
        Productphotodata: [],
        RePrintHorderHistodata: {},
        ClinicPatientListByOrderdata: [],
        QAQCbyBatchdata: [],
        ViewQaQCByOrderbatchIddata: {},
        SOrderPrescriptiondata: {},
        ExternalSOrderPrescriptiondata: {},
        Ssmanualpushmessage: null,
        //----
        isOrdercommentadd: false,
        isOrderfileadd: false,
        ispriceupdate: false,
        isUpdateShipping: false,
        issterileupdate: false,
        issmssent: false,
        ismedicineinfoFileadded: false,
        ismedicineinfoFilealladded: false,
        isRiskassessmentdataadded: false,
        isOrderstatusupdated: false,
        isOrderpatientupdated: false,
        isSendPaymentPxCreate: false,
        isOrderreceive: false,
        isproductphotoadd: false,
        isproductphotodelete: false,
        isduedateupdate: false,
        isshopnameupdate: false,
        ispatientorderadded: false,
        isQAQCbyBatchAdded: false,
        isQAQCbyBatchDelete: false,
        isBulkstatusupdated: false,
        isVoidpayment: false,
        isRefundpayment: false,
        isSsmanualpush: false,
        isFetching: false,
        isFetchingOBJ: {},
        isError: false,
        //--
        errorMessage: '',
        hasmore: false,
    },
    reducers: {
        updateState: (state, { payload }) => {
            state.allordersdata = payload.allordersdata !== undefined ? payload.allordersdata : state.allordersdata;
            state.TransaferToPaymentScreendata = payload.TransaferToPaymentScreendata !== undefined ? payload.TransaferToPaymentScreendata : state.TransaferToPaymentScreendata;
            state.isOrdercommentadd = payload.isOrdercommentadd !== undefined ? payload.isOrdercommentadd : state.isOrdercommentadd;
            state.isOrderfileadd = payload.isOrderfileadd !== undefined ? payload.isOrderfileadd : state.isOrderfileadd;
            state.isproductphotoadd = payload.isproductphotoadd !== undefined ? payload.isproductphotoadd : state.isproductphotoadd;
            state.isproductphotodelete = payload.isproductphotodelete !== undefined ? payload.isproductphotodelete : state.isproductphotodelete;
            state.ispriceupdate = payload.ispriceupdate !== undefined ? payload.ispriceupdate : state.ispriceupdate;
            state.isUpdateShipping = payload.isUpdateShipping !== undefined ? payload.isUpdateShipping : state.isUpdateShipping;
            state.issterileupdate = payload.issterileupdate !== undefined ? payload.issterileupdate : state.issterileupdate;
            state.isduedateupdate = payload.isduedateupdate !== undefined ? payload.isduedateupdate : state.isduedateupdate;
            state.ismedicineinfoFileadded = payload.ismedicineinfoFileadded !== undefined ? payload.ismedicineinfoFileadded : state.ismedicineinfoFileadded;
            state.ismedicineinfoFilealladded = payload.ismedicineinfoFilealladded !== undefined ? payload.ismedicineinfoFilealladded : state.ismedicineinfoFilealladded;
            state.isRiskassessmentdataadded = payload.isRiskassessmentdataadded !== undefined ? payload.isRiskassessmentdataadded : state.isRiskassessmentdataadded;
            state.isOrderstatusupdated = payload.isOrderstatusupdated !== undefined ? payload.isOrderstatusupdated : state.isOrderstatusupdated;
            state.isOrderpatientupdated = payload.isOrderpatientupdated !== undefined ? payload.isOrderpatientupdated : state.isOrderpatientupdated;
            state.isSendPaymentPxCreate = payload.isSendPaymentPxCreate !== undefined ? payload.isSendPaymentPxCreate : state.isSendPaymentPxCreate;
            state.isOrderreceive = payload.isOrderreceive !== undefined ? payload.isOrderreceive : state.isOrderreceive;
            state.issmssent = payload.issmssent !== undefined ? payload.issmssent : state.issmssent;
            state.ispatientorderadded = payload.ispatientorderadded !== undefined ? payload.ispatientorderadded : state.ispatientorderadded;
            state.isQAQCbyBatchAdded = payload.isQAQCbyBatchAdded !== undefined ? payload.isQAQCbyBatchAdded : state.isQAQCbyBatchAdded;
            state.isQAQCbyBatchDelete = payload.isQAQCbyBatchDelete !== undefined ? payload.isQAQCbyBatchDelete : state.isQAQCbyBatchDelete;
            state.isBulkstatusupdated = payload.isBulkstatusupdated !== undefined ? payload.isBulkstatusupdated : state.isBulkstatusupdated;
            state.isVoidpayment = payload.isVoidpayment !== undefined ? payload.isVoidpayment : state.isVoidpayment;
            state.isRefundpayment = payload.isRefundpayment !== undefined ? payload.isRefundpayment : state.isRefundpayment;
            state.isSsmanualpush = payload.isSsmanualpush !== undefined ? payload.isSsmanualpush : state.isSsmanualpush;
            state.Ssmanualpushmessage = payload.Ssmanualpushmessage !== undefined ? payload.Ssmanualpushmessage : state.Ssmanualpushmessage;
            state.isshopnameupdate = payload.isshopnameupdate !== undefined ? payload.isshopnameupdate : state.isshopnameupdate;
            state.isorderproductAdded = payload.isorderproductAdded !== undefined ? payload.isorderproductAdded : state.isorderproductAdded;
            state.isError = payload.isError !== undefined ? payload.isError : state.isError;
            state.errorMessage = payload.errorMessage !== undefined ? payload.errorMessage : state.errorMessage;
            state.hasmore = payload.hasmore !== undefined ? payload.hasmore : state.hasmore;
        },
        clearState: (state) => {
            state.allordersdata = {};
            state.Updateorderstatusdata = {};
            state.UpdateBulkOrderStatusdata = [];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(ScheduledOrderToday.fulfilled, (state, { payload }) => {
            try {
                state.scheduledOrderTodaydata = payload;
                state.isFetchingOBJ.ScheduledOrderToday = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[OrderStatus.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(ScheduledOrderToday.rejected, (state, { payload }) => {
            try {
                state.scheduledOrderTodaydata = [];
                state.isFetchingOBJ.ScheduledOrderToday = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[OrderStatus.rejected] try catch error >>', error);
            }
        })
        builder.addCase(ScheduledOrderToday.pending, (state) => {
            state.isFetchingOBJ.ScheduledOrderToday = true;
        })
        //---
        builder.addCase(OrderStatus.fulfilled, (state, { payload }) => {
            try {
                if (state.hasmore) {
                    let updated = payload.data;
                    const newData = _.unionBy(state.ordersdata.data, updated, 'orderid');
                    state.ordersdata.data = newData;
                } else {
                    state.ordersdata = payload;
                }
                state.isFetchingOBJ.OrderStatus = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[OrderStatus.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(OrderStatus.rejected, (state, { payload }) => {
            try {
                state.ordersdata = [];
                state.isFetchingOBJ.OrderStatus = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[OrderStatus.rejected] try catch error >>', error);
            }
        })
        builder.addCase(OrderStatus.pending, (state) => {
            if (state.hasmore) {
                state.isFetchingOBJ.OrderStatus = false;
            } else {
                state.isFetchingOBJ.OrderStatus = true;
            }
        })
        //----
        builder.addCase(Getorderfromorderid.fulfilled, (state, { payload }) => {
            try {
                state.allordersdata = payload;
                state.isFetching = false;
                state.isFetchingOBJ.Getorderfromorderid = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[Getorderfromorderid.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(Getorderfromorderid.rejected, (state, { payload }) => {
            try {
                state.allordersdata = {};
                state.isFetching = false;
                state.isFetchingOBJ.Getorderfromorderid = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[Getorderfromorderid.rejected] try catch error >>', error);
            }
        })
        builder.addCase(Getorderfromorderid.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.Getorderfromorderid = true;
        })
        //----
        builder.addCase(UpdateorderPrice.fulfilled, (state, { payload }) => {
            try {
                let updated = payload.result;
                state.allordersdata = updated;
                state.ispriceupdate = true;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateorderPrice = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[UpdateorderPrice.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(UpdateorderPrice.rejected, (state, { payload }) => {
            try {
                state.ispriceupdate = false;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateorderPrice = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[UpdateorderPrice.rejected] try catch error >>', error);
            }
        })
        builder.addCase(UpdateorderPrice.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.UpdateorderPrice = true;
        })
        //----
        builder.addCase(UpdateShopName.fulfilled, (state) => {
            try {
                state.isshopnameupdate = true;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateShopName = false
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[UpdateShopName.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(UpdateShopName.rejected, (state, { payload }) => {
            try {
                state.isshopnameupdate = false;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateShopName = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[UpdateShopName.rejected] try catch error >>', error);
            }
        })
        builder.addCase(UpdateShopName.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.UpdateShopName = true;
        })
        //----
        builder.addCase(UpdateSterile.fulfilled, (state, { payload }) => {
            try {
                let updated = payload.result;
                state.allordersdata = updated;
                state.issterileupdate = true;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateSterile = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[UpdateSterile.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(UpdateSterile.rejected, (state, { payload }) => {
            try {
                state.issterileupdate = false;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateSterile = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[UpdateSterile.rejected] try catch error >>', error);
            }
        })
        builder.addCase(UpdateSterile.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.UpdateSterile = true;
        })
        //----
        builder.addCase(GetOrderComment.fulfilled, (state, { payload }) => {
            try {
                state.Ordercommentdata = payload;
                state.isFetching = false;
                state.isFetchingOBJ.GetOrderComment = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetOrderComment.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetOrderComment.rejected, (state, { payload }) => {
            try {
                state.Ordercommentdata = [];
                state.isFetching = false;
                state.isFetchingOBJ.GetOrderComment = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetOrderComment.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetOrderComment.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetOrderComment = true;
        })
        //----
        builder.addCase(AddOrderComment.fulfilled, (state, { payload }) => {
            try {
                let updated = payload.result;
                let existData = [...state.Ordercommentdata.data];
                let newData = [...existData, updated];
                state.Ordercommentdata.data = newData;
                state.isOrdercommentadd = true;
                state.isFetching = false;
                state.isFetchingOBJ.AddOrderComment = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[AddOrderComment.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(AddOrderComment.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.AddOrderComment = false;
                state.isError = true;
                state.isOrdercommentadd = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[AddOrderComment.rejected] try catch error >>', error);
            }
        })
        builder.addCase(AddOrderComment.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.AddOrderComment = true;
        })
        //----
        builder.addCase(GetOrderfile.fulfilled, (state, { payload }) => {
            try {
                state.Orderfiledata = payload;
                state.isFetching = false;
                state.isFetchingOBJ.GetOrderfile = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetOrderfile.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetOrderfile.rejected, (state, { payload }) => {
            try {
                state.Orderfiledata = {};
                state.isFetching = false;
                state.isFetchingOBJ.GetOrderfile = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetOrderfile.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetOrderfile.pending, (state) => {
            state.Orderfiledata = {};
            state.isFetching = true;
            state.isFetchingOBJ.GetOrderfile = true;
        })
        //----
        builder.addCase(AddOrderfile.fulfilled, (state, { payload }) => {
            try {
                let updated = payload.result;
                let existData = [...state.Orderfiledata.result];
                let newData = [...existData, updated];
                state.Orderfiledata.result = newData;
                state.isOrderfileadd = true;
                state.isFetching = false;
                state.isFetchingOBJ.AddOrderfile = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[AddOrderfile.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(AddOrderfile.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.AddOrderfile = false;
                state.isError = true;
                state.isOrderfileadd = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[AddOrderfile.rejected] try catch error >>', error);
            }
        })
        builder.addCase(AddOrderfile.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.AddOrderfile = true;
        })
        //----
        builder.addCase(Addproductphoto.fulfilled, (state, { payload }) => {
            try {
                let updated = payload.result;
                let existData = [...state.Productphotodata];
                let newData = [...existData, updated];
                state.Productphotodata = newData;
                state.isproductphotoadd = true;
                state.isFetching = false;
                state.isFetchingOBJ.Addproductphoto = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[Addproductphoto.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(Addproductphoto.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.Addproductphoto = false;
                state.isError = true;
                state.isproductphotoadd = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[Addproductphoto.rejected] try catch error >>', error);
            }
        })
        builder.addCase(Addproductphoto.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.Addproductphoto = true;
        })
        //----
        builder.addCase(Deleteproductphoto.fulfilled, (state, { payload }) => {
            try {
                let exist = [...state.Productphotodata];
                let newExist = exist?.filter((e) => {
                    if (e.ID !== payload.ProductPhotoID) {
                        return e;
                    }
                    return false;
                });
                exist = newExist;
                state.Productphotodata = exist;
                state.isFetching = false;
                state.isFetchingOBJ.Deleteproductphoto = false;
                state.isError = false;
                state.isproductphotodelete = true;
            } catch (error) {
                console.error('[Deleteproductphoto.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(Deleteproductphoto.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.Deleteproductphoto = false;
                state.isError = true;
                state.isproductphotodelete = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[Deleteproductphoto.rejected] try catch error >>', error);
            }
        })
        builder.addCase(Deleteproductphoto.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.Deleteproductphoto = true;
        })
        //----
        builder.addCase(AddOrderproduct.fulfilled, (state) => {
            try {
                state.isorderproductAdded = true;
                state.isFetching = false;
                state.isFetchingOBJ.AddOrderproduct = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[AddOrderproduct.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(AddOrderproduct.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.AddOrderproduct = false;
                state.isError = true;
                state.isorderproductAdded = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[AddOrderproduct.rejected] try catch error >>', error);
            }
        })
        builder.addCase(AddOrderproduct.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.AddOrderproduct = true;
        })
        //----
        builder.addCase(Getorderproduct.fulfilled, (state, { payload }) => {
            try {
                state.Orderproductdata = payload;
                state.isFetching = false;
                state.isFetchingOBJ.Getorderproduct = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[Getorderproduct.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(Getorderproduct.rejected, (state, { payload }) => {
            try {
                state.Orderproductdata = [];
                state.isFetching = false;
                state.isFetchingOBJ.Getorderproduct = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[Getorderproduct.rejected] try catch error >>', error);
            }
        })
        builder.addCase(Getorderproduct.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.Getorderproduct = true;
        })
        //----
        builder.addCase(GetorderStatus.fulfilled, (state, { payload }) => {
            try {
                state.Orderstatusdata = payload;
                state.isFetchingOBJ.GetorderStatus = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetorderStatus.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetorderStatus.rejected, (state, { payload }) => {
            try {
                state.Orderstatusdata = {};
                state.isFetchingOBJ.GetorderStatus = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetorderStatus.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetorderStatus.pending, (state) => {
            state.isFetchingOBJ.GetorderStatus = true;
        })
        //----
        builder.addCase(UpdateDueDate.fulfilled, (state, { payload }) => {
            try {
                state.allordersdata = payload;
                state.isduedateupdate = true;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateDueDate = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[UpdateDueDate.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(UpdateDueDate.rejected, (state, { payload }) => {
            try {
                state.allordersdata = {};
                state.isduedateupdate = false;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateDueDate = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[UpdateDueDate.rejected] try catch error >>', error);
            }
        })
        builder.addCase(UpdateDueDate.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.UpdateDueDate = true;
        })
        //----
        builder.addCase(getsmsclient.fulfilled, (state, { payload }) => {
            try {
                state.Clientsmsdata = payload;
                state.isFetching = false;
                state.isFetchingOBJ.getsmsclient = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[getsmsclient.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(getsmsclient.rejected, (state, { payload }) => {
            try {
                state.Clientsmsdata = [];
                state.isFetchingOBJ.getsmsclient = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[getsmsclient.rejected] try catch error >>', error);
            }
        })
        builder.addCase(getsmsclient.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.getsmsclient = true;
        })
        //----
        builder.addCase(Sendsmsclient.fulfilled, (state, { payload }) => {
            try {
                let updated = payload;
                let existData = [...state.Clientsmsdata];
                let newData = [...existData, updated];
                state.Clientsmsdata = newData;
                state.issmssent = true;
                state.isFetching = false;
                state.isFetchingOBJ.Sendsmsclient = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[Sendsmsclient.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(Sendsmsclient.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.Sendsmsclient = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[Sendsmsclient.rejected] try catch error >>', error);
            }
        })
        builder.addCase(Sendsmsclient.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.Sendsmsclient = true;
        })
        //----
        builder.addCase(AddOrdermedicinefile.fulfilled, (state, { payload }) => {
            try {
                state.medicineFiledata = payload.result;
                state.ismedicineinfoFileadded = true;
                state.isFetching = false;
                state.isFetchingOBJ.AddOrdermedicinefile = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[AddOrdermedicinefile.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(AddOrdermedicinefile.rejected, (state, { payload }) => {
            try {
                state.medicineFiledata = [];
                state.ismedicineinfoFileadded = false;
                state.isFetching = false;
                state.isFetchingOBJ.AddOrdermedicinefile = false
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[AddOrdermedicinefile.rejected] try catch error >>', error);
            }
        })
        builder.addCase(AddOrdermedicinefile.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.AddOrdermedicinefile = true;
        })
        //----
        builder.addCase(MedicineInfoByOrder.fulfilled, (state, { payload }) => {
            try {
                state.medicineFiledata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.MedicineInfoByOrder = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[MedicineInfoByOrder.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(MedicineInfoByOrder.rejected, (state, { payload }) => {
            try {
                state.medicineFiledata = [];
                state.isFetching = false;
                state.isFetchingOBJ.MedicineInfoByOrder = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[MedicineInfoByOrder.rejected] try catch error >>', error);
            }
        })
        builder.addCase(MedicineInfoByOrder.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.MedicineInfoByOrder = true;
        })
        //----
        builder.addCase(MedicineInfoAll.fulfilled, (state, { payload }) => {
            try {
                state.MedicineInfoAlldata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.MedicineInfoAll = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[MedicineInfoAll.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(MedicineInfoAll.rejected, (state, { payload }) => {
            try {
                state.MedicineInfoAlldata = [];
                state.isFetching = false;
                state.isFetchingOBJ.MedicineInfoAll = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[MedicineInfoAll.rejected] try catch error >>', error);
            }
        })
        builder.addCase(MedicineInfoAll.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.MedicineInfoAll = true;
        })
        //----
        builder.addCase(AddOrdermedicinefileall.fulfilled, (state, { payload }) => {
            try {
                state.AddMedicineInfoAlldata = payload.result;
                state.ismedicineinfoFilealladded = true;
                state.isFetching = false;
                state.isFetchingOBJ.AddOrdermedicinefileall = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[AddOrdermedicinefileall.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(AddOrdermedicinefileall.rejected, (state, { payload }) => {
            try {
                state.AddMedicineInfoAlldata = [];
                state.ismedicineinfoFilealladded = false;
                state.isFetching = false;
                state.isFetchingOBJ.AddOrdermedicinefileall = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[AddOrdermedicinefileall.rejected] try catch error >>', error);
            }
        })
        builder.addCase(AddOrdermedicinefileall.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.AddOrdermedicinefileall = true;
        })
        //----
        builder.addCase(AddRiskassessment.fulfilled, (state, { payload }) => {
            try {
                state.Riskassessmentdata = payload.result;
                state.isRiskassessmentdataadded = true;
                state.isFetching = false;
                state.isFetchingOBJ.AddRiskassessment = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[AddRiskassessment.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(AddRiskassessment.rejected, (state, { payload }) => {
            try {
                state.Riskassessmentdata = [];
                state.isRiskassessmentdataadded = false;
                state.isFetching = false;
                state.isFetchingOBJ.AddRiskassessment = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[AddRiskassessment.rejected] try catch error >>', error);
            }
        })
        builder.addCase(AddRiskassessment.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.AddRiskassessment = true;
        })
        //----
        builder.addCase(Updateorderstatus.fulfilled, (state, { payload }) => {
            try {
                state.Updateorderstatusdata = payload.result;
                state.isOrderstatusupdated = true;
                state.isFetchingOBJ.Updateorderstatus = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[Updateorderstatus.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(Updateorderstatus.rejected, (state, { payload }) => {
            try {
                state.Updateorderstatusdata = {};
                state.isOrderstatusupdated = false;
                state.isFetchingOBJ.Updateorderstatus = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[Updateorderstatus.rejected] try catch error >>', error);
            }
        })
        builder.addCase(Updateorderstatus.pending, (state) => {
            state.isFetchingOBJ.Updateorderstatus = true;
        })
        //----
        builder.addCase(UpdatePatient.fulfilled, (state, { payload }) => {
            try {
                state.allordersdata = payload.result;
                state.isOrderpatientupdated = true;
                state.isFetching = false;
                state.isFetchingOBJ.UpdatePatient = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[UpdatePatient.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(UpdatePatient.rejected, (state, { payload }) => {
            try {
                state.allordersdata = {};
                state.isOrderpatientupdated = false;
                state.isFetching = false;
                state.isFetchingOBJ.UpdatePatient = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[UpdatePatient.rejected] try catch error >>', error);
            }
        })
        builder.addCase(UpdatePatient.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.UpdatePatient = true;
        })
        //----
        builder.addCase(PrintDetail.fulfilled, (state, { payload }) => {
            try {
                state.PrintDetaildata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.PrintDetail = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[PrintDetail.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(PrintDetail.rejected, (state, { payload }) => {
            try {
                state.PrintDetaildata = {};
                state.isFetching = false;
                state.isFetchingOBJ.PrintDetail = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[PrintDetail.rejected] try catch error >>', error);
            }
        })
        builder.addCase(PrintDetail.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.PrintDetail = true;
        })
        //----
        builder.addCase(PrintAllDetail.fulfilled, (state, { payload }) => {
            try {
                state.PrintAllDetaildata = payload.result;
                localStorage.setItem('printAllAndConfirm', true);
                state.isFetching = false;
                state.isFetchingOBJ.PrintAllDetail = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[PrintAllDetail.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(PrintAllDetail.rejected, (state, { payload }) => {
            try {
                state.PrintAllDetaildata = [];
                localStorage.setItem('printAllAndConfirm', false);
                state.isFetching = false;
                state.isFetchingOBJ.PrintAllDetail = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[PrintAllDetail.rejected] try catch error >>', error);
            }
        })
        builder.addCase(PrintAllDetail.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.PrintAllDetail = true;
            localStorage.setItem('printAllAndConfirm', false);
        })
        //----
        builder.addCase(ReprintedLabelClinic.fulfilled, (state, { payload }) => {
            try {
                state.ReprintedLabelClinicdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.ReprintedLabelClinic = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[ReprintedLabelClinic.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(ReprintedLabelClinic.rejected, (state, { payload }) => {
            try {
                state.ReprintedLabelClinicdata = {};
                state.isFetching = false;
                state.isFetchingOBJ.ReprintedLabelClinic = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[ReprintedLabelClinic.rejected] try catch error >>', error);
            }
        })
        builder.addCase(ReprintedLabelClinic.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.ReprintedLabelClinic = true;
        })
        //----
        builder.addCase(CheckPayment.fulfilled, (state, { payload }) => {
            try {
                state.CheckPaymentdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.CheckPayment = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[CheckPayment.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(CheckPayment.rejected, (state, { payload }) => {
            try {
                state.CheckPaymentdata = null;
                state.isFetching = false;
                state.isFetchingOBJ.CheckPayment = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[CheckPayment.rejected] try catch error >>', error);
            }
        })
        builder.addCase(CheckPayment.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.CheckPayment = true;
        })
        //----
        builder.addCase(SendPaymentPxCreate.fulfilled, (state, { payload }) => {
            try {
                state.SendPaymentPxCreatedata = payload.result;
                state.isSendPaymentPxCreate = true;
                state.isFetching = false;
                state.isFetchingOBJ.SendPaymentPxCreate = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[SendPaymentPxCreate.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(SendPaymentPxCreate.rejected, (state, { payload }) => {
            try {
                state.SendPaymentPxCreatedata = {};
                state.isSendPaymentPxCreate = false;
                state.isFetching = false;
                state.isFetchingOBJ.SendPaymentPxCreate = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[SendPaymentPxCreate.rejected] try catch error >>', error);
            }
        })
        builder.addCase(SendPaymentPxCreate.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.SendPaymentPxCreate = true;
        })
        //----
        builder.addCase(TransaferToPaymentScreen.fulfilled, (state, { payload }) => {
            try {
                state.TransaferToPaymentScreendata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.TransaferToPaymentScreen = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[TransaferToPaymentScreen.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(TransaferToPaymentScreen.rejected, (state, { payload }) => {
            try {
                state.TransaferToPaymentScreendata = {};
                state.isFetching = false;
                state.isFetchingOBJ.TransaferToPaymentScreen = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[TransaferToPaymentScreen.rejected] try catch error >>', error);
            }
        })
        builder.addCase(TransaferToPaymentScreen.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.TransaferToPaymentScreen = true;
        })
        //----
        builder.addCase(GetProductPhotosByOrderId.fulfilled, (state, { payload }) => {
            try {
                state.Productphotodata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.GetProductPhotosByOrderId = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetProductPhotosByOrderId.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetProductPhotosByOrderId.rejected, (state, { payload }) => {
            try {
                state.Productphotodata = [];
                state.isFetching = false;
                state.isFetchingOBJ.GetProductPhotosByOrderId = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetProductPhotosByOrderId.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetProductPhotosByOrderId.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetProductPhotosByOrderId = true;
        })
        //----
        builder.addCase(ReceivedOrder.fulfilled, (state) => {
            try {
                state.isOrderreceive = true;
                state.isFetching = false;
                state.isFetchingOBJ.ReceivedOrder = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[ReceivedOrder.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(ReceivedOrder.rejected, (state, { payload }) => {
            try {
                state.isOrderreceive = false;
                state.isFetching = false;
                state.isFetchingOBJ.ReceivedOrder = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[ReceivedOrder.rejected] try catch error >>', error);
            }
        })
        builder.addCase(ReceivedOrder.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.ReceivedOrder = true;
        })
        //----
        builder.addCase(RePrintHorderHisto.fulfilled, (state, { payload }) => {
            try {
                state.RePrintHorderHistodata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.RePrintHorderHisto = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[RePrintHorderHisto.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(RePrintHorderHisto.rejected, (state, { payload }) => {
            try {
                state.RePrintHorderHistodata = {};
                state.isFetching = false;
                state.isFetchingOBJ.RePrintHorderHisto = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[RePrintHorderHisto.rejected] try catch error >>', error);
            }
        })
        builder.addCase(RePrintHorderHisto.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.RePrintHorderHisto = true;
        })
        //----
        builder.addCase(ClinicPatientListByOrder.fulfilled, (state, { payload }) => {
            try {
                state.ClinicPatientListByOrderdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.ClinicPatientListByOrder = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[ClinicPatientListByOrder.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(ClinicPatientListByOrder.rejected, (state, { payload }) => {
            try {
                state.ClinicPatientListByOrderdata = [];
                state.isFetching = false;
                state.isFetchingOBJ.ClinicPatientListByOrder = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[ClinicPatientListByOrder.rejected] try catch error >>', error);
            }
        })
        builder.addCase(ClinicPatientListByOrder.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.ClinicPatientListByOrder = true;
        })
        //----
        builder.addCase(createClinicPatient.fulfilled, (state, { payload }) => {
            try {
                let updated = payload.result;
                let exist = [...state.ClinicPatientListByOrderdata];
                let newdata = [...exist, updated];
                state.ClinicPatientListByOrderdata = newdata;
                state.ispatientorderadded = true;
                state.isFetching = false;
                state.isFetchingOBJ.createClinicPatient = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[createClinicPatient.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(createClinicPatient.rejected, (state, { payload }) => {
            try {
                state.ispatientorderadded = false;
                state.isFetching = false;
                state.isFetchingOBJ.createClinicPatient = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[createClinicPatient.rejected] try catch error >>', error);
            }
        })
        builder.addCase(createClinicPatient.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.createClinicPatient = true;
        })
        //----
        builder.addCase(QAQCOrderbatchList.fulfilled, (state, { payload }) => {
            try {
                state.QAQCbyBatchdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.QAQCOrderbatchList = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[QAQCOrderbatchList.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(QAQCOrderbatchList.rejected, (state, { payload }) => {
            try {
                state.QAQCbyBatchdata = [];
                state.isFetching = false;
                state.isFetchingOBJ.QAQCOrderbatchList = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[QAQCOrderbatchList.rejected] try catch error >>', error);
            }
        })
        builder.addCase(QAQCOrderbatchList.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.QAQCOrderbatchList = true;
        })
        //----
        builder.addCase(ViewQaQCByOrderbatchId.fulfilled, (state, { payload }) => {
            try {
                state.ViewQaQCByOrderbatchIddata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.ViewQaQCByOrderbatchId = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[ViewQaQCByOrderbatchId.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(ViewQaQCByOrderbatchId.rejected, (state, { payload }) => {
            try {
                state.ViewQaQCByOrderbatchIddata = {};
                state.isFetching = false;
                state.isFetchingOBJ.ViewQaQCByOrderbatchId = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[ViewQaQCByOrderbatchId.rejected] try catch error >>', error);
            }
        })
        builder.addCase(ViewQaQCByOrderbatchId.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.ViewQaQCByOrderbatchId = true;
        })
        //----
        builder.addCase(AddQAQCbyBatch.fulfilled, (state, { payload }) => {
            try {
                let updated = payload.result;
                let exist = [...state.QAQCbyBatchdata];
                let newdata = [...exist, updated];
                state.QAQCbyBatchdata = newdata;
                state.isQAQCbyBatchAdded = true;
                state.isFetching = false;
                state.isFetchingOBJ.AddQAQCbyBatch = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[AddQAQCbyBatch.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(AddQAQCbyBatch.rejected, (state, { payload }) => {
            try {
                state.isQAQCbyBatchAdded = false;
                state.isFetching = false;
                state.isFetchingOBJ.AddQAQCbyBatch = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[AddQAQCbyBatch.rejected] try catch error >>', error);
            }
        })
        builder.addCase(AddQAQCbyBatch.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.AddQAQCbyBatch = true;
        })
        //----
        builder.addCase(DeleteQAQCbyBatch.fulfilled, (state, { payload }) => {
            try {
                let exist = [...state.QAQCbyBatchdata];
                let newExist = exist?.filter((e) => {
                    if (e.ID !== payload.ID) {
                        return e;
                    }
                    return false;
                });
                exist = newExist;
                state.QAQCbyBatchdata = exist;
                state.isFetching = false;
                state.isFetchingOBJ.DeleteQAQCbyBatch = false;
                state.isError = false;
                state.isQAQCbyBatchDelete = true;
            } catch (error) {
                console.error('[DeleteQAQCbyBatch.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(DeleteQAQCbyBatch.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.DeleteQAQCbyBatch = false;
                state.isError = true;
                state.isQAQCbyBatchDelete = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[DeleteQAQCbyBatch.rejected] try catch error >>', error);
            }
        })
        builder.addCase(DeleteQAQCbyBatch.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.DeleteQAQCbyBatch = true;
        })
        //----
        builder.addCase(UpdateBulkOrderStatus.fulfilled, (state, { payload }) => {
            try {
                state.UpdateBulkOrderStatusdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.UpdateBulkOrderStatus = false;
                state.isError = false;
                state.isBulkstatusupdated = true;
            } catch (error) {
                console.error('[UpdateBulkOrderStatus.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(UpdateBulkOrderStatus.rejected, (state, { payload }) => {
            try {
                state.UpdateBulkOrderStatusdata = [];
                state.isFetching = false;
                state.isFetchingOBJ.UpdateBulkOrderStatus = false;
                state.isError = true;
                state.isBulkstatusupdated = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[UpdateBulkOrderStatus.rejected] try catch error >>', error);
            }
        })
        builder.addCase(UpdateBulkOrderStatus.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.UpdateBulkOrderStatus = true;
        })
        //----
        builder.addCase(VoidTransaction.fulfilled, (state) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.VoidTransaction = false;
                state.isError = false;
                state.isVoidpayment = true;
            } catch (error) {
                console.error('[VoidTransaction.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(VoidTransaction.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.VoidTransaction = false;
                state.isError = true;
                state.isVoidpayment = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[VoidTransaction.rejected] try catch error >>', error);
            }
        })
        builder.addCase(VoidTransaction.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.VoidTransaction = true;
        })
        //----
        builder.addCase(RefundTransaction.fulfilled, (state) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.RefundTransaction = false;
                state.isError = false;
                state.isRefundpayment = true;
            } catch (error) {
                console.error('[RefundTransaction.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(RefundTransaction.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.RefundTransaction = false;
                state.isError = true;
                state.isRefundpayment = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[RefundTransaction.rejected] try catch error >>', error);
            }
        })
        builder.addCase(RefundTransaction.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.RefundTransaction = true;
        })
        //----
        builder.addCase(SOrderPrescription.fulfilled, (state, { payload }) => {
            try {
                state.SOrderPrescriptiondata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.SOrderPrescription = false;
                state.isError = false;
            } catch (error) {
                console.error('[SOrderPrescription.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(SOrderPrescription.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.SOrderPrescription = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[SOrderPrescription.rejected] try catch error >>', error);
            }
        })
        builder.addCase(SOrderPrescription.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.SOrderPrescription = true;
        })
        //----
        builder.addCase(ExternalSOrderPrescription.fulfilled, (state, { payload }) => {
            try {
                state.ExternalSOrderPrescriptiondata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.ExternalSOrderPrescription = false;
                state.isError = false;
            } catch (error) {
                console.error('[ExternalSOrderPrescription.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(ExternalSOrderPrescription.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.ExternalSOrderPrescription = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[ExternalSOrderPrescription.rejected] try catch error >>', error);
            }
        })
        builder.addCase(ExternalSOrderPrescription.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.ExternalSOrderPrescription = true;
        })
        //----
        builder.addCase(SSManualPush.fulfilled, (state, { payload }) => {
            try {
                state.Ssmanualpushmessage = payload.result;
                state.isSsmanualpush = true;
                state.isFetching = false;
                state.isFetchingOBJ.SSManualPush = false;
                state.isError = false;
            } catch (error) {
                console.error('[SSManualPush.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(SSManualPush.rejected, (state, { payload }) => {
            try {
                state.isSsmanualpush = false;
                state.isFetching = false;
                state.isFetchingOBJ.SSManualPush = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[SSManualPush.rejected] try catch error >>', error);
            }
        })
        builder.addCase(SSManualPush.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.SSManualPush = true;
        })
        //----
        builder.addCase(UpdateShipping.fulfilled, (state, { payload }) => {
            try {
                state.allordersdata = payload.result;
                state.isUpdateShipping = true;
                state.isFetchingOBJ.UpdateShipping = false;
                state.isError = false;
            } catch (error) {
                console.error('[UpdateShipping.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(UpdateShipping.rejected, (state, { payload }) => {
            try {
                state.isUpdateShipping = false;
                state.isFetchingOBJ.UpdateShipping = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[UpdateShipping.rejected] try catch error >>', error);
            }
        })
        builder.addCase(UpdateShipping.pending, (state) => {
            state.isFetchingOBJ.UpdateShipping = true;
        })
    }
});
export const { clearState, updateState } = orderSlice.actions;
export const orderSelector = (state) => state.ncc.order;