import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../axios';
import CONFIG from '../../Utils/config';
import Service from '../../Utils/service';
const extractErrorMessage = (error) =>
    error?.response?.data?.Message ||
    error?.response?.data ||
    error?.response ||
    error?.message;
const extractErrorMessageRejected = (payload) =>
    payload?.response?.data?.Message?.toString() ||
    payload?.response?.data?.toString() ||
    payload?.response?.toString() ||
    payload?.err?.toString() ||
    payload?.data?.Message?.toString() ||
    payload?.data?.message?.toString() ||
    payload?.data?.toString() ||
    payload?.result?.toString() ||
    payload?.Message?.toString() ||
    payload?.message?.toString() ||
    payload?.error?.toString() ||
    "API Response Invalid.";
//---
export const GetCurrentNCCSterilePricelist = createAsyncThunk(
    'ClinicOrder/GetCurrentNCCSterilePricelist',
    async (_userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}ClinicOrder/CurrentNCCSterilePricelist`, headers: authHeader });
            // console.log('GetCurrentNCCSterilePricelist result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetShippingOptions = createAsyncThunk(
    'ClinicOrder/GetShippingOptions',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}ClinicOrder/ShippingOptions`, params: userdata, data: userdata, headers: authHeader });
            // console.log('GetShippingOptions result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const ClinicOrderCreate = createAsyncThunk(
    'ClinicOrder/ClinicOrderCreate',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader('multipart/form-data');
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}ClinicOrder/ClinicOrderCreate`, data: userdata, headers: authHeader });
            // console.log('ClinicOrderCreate result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const ClinicSlice = createSlice({
    name: 'Clinic',
    initialState: {
        Currentpricelistdata: null,
        ShippingOptionsdata: [],
        Clinicorderdata: {},
        isClinicordercreated: false,
        isFetchingOBJ: {},
        isFetching: false,
        isError: false,
        errorMessage: '',
    },
    reducers: {
        updateState: (state, { payload }) => {
            if (payload.Currentpricelistdata !== undefined) { state.Currentpricelistdata = payload.Currentpricelistdata }
            if (payload.isClinicordercreated !== undefined) { state.isClinicordercreated = payload.isClinicordercreated }
            if (payload.isError !== undefined) { state.isError = payload.isError }
            if (payload.errorMessage !== undefined) { state.errorMessage = payload.errorMessage }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(GetCurrentNCCSterilePricelist.fulfilled, (state, { payload }) => {
            try {
                state.Currentpricelistdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.GetCurrentNCCSterilePricelist = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetCurrentNCCSterilePricelist.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetCurrentNCCSterilePricelist.rejected, (state, { payload }) => {
            try {
                state.Currentpricelistdata = [];
                state.isFetching = false;
                state.isFetchingOBJ.GetCurrentNCCSterilePricelist = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetCurrentNCCSterilePricelist.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetCurrentNCCSterilePricelist.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetCurrentNCCSterilePricelist = true;
        })
        //----
        builder.addCase(GetShippingOptions.fulfilled, (state, { payload }) => {
            try {
                state.ShippingOptionsdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.GetShippingOptions = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetShippingOptions.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetShippingOptions.rejected, (state, { payload }) => {
            try {
                state.ShippingOptionsdata = [];
                state.isFetching = false;
                state.isFetchingOBJ.GetShippingOptions = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetShippingOptions.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetShippingOptions.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetShippingOptions = true;
        })
        //----
        builder.addCase(ClinicOrderCreate.fulfilled, (state, { payload }) => {
            try {
                state.Clinicorderdata = payload;
                state.isClinicordercreated = true;
                state.isFetching = false;
                state.isFetchingOBJ.ClinicOrderCreate = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[ClinicOrderCreate.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(ClinicOrderCreate.rejected, (state, { payload }) => {
            try {
                state.Clinicorderdata = [];
                state.isClinicordercreated = false;
                state.isFetching = false;
                state.isFetchingOBJ.ClinicOrderCreate = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[ClinicOrderCreate.rejected] try catch error >>', error);
            }
        })
        builder.addCase(ClinicOrderCreate.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.ClinicOrderCreate = true;
        })
    }
});
export const { updateState } = ClinicSlice.actions;
export const ClinicSelector = (state) => state.ncc.Clinic;
