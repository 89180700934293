import CONFIG from "./config";
const AuthHeader = async (contenttype) => {
    try {
        let token = await localStorage.getItem('ncc_Token');
        const headers = { 'Authorization': `${token}`, 'Content-Type': contenttype ? `${contenttype}` : 'application/json' }
        return headers;
    } catch (error) {
        console.error('try catch [service.AuthHeader] error.message :', error.message);
    }
}
const Getlogin = async () => {
    try {
        let istoken = await localStorage.getItem('ncc_Token');
        if (istoken) {
            //--- token expire check
            //--[token in store user-id and user-role and tokenData]
            let decodedJwt = JSON.parse(window.atob(istoken.split('.')[1]));
            if (decodedJwt.exp * 1000 < Date.now()) {
                Logout();
            }
        }
        return !!istoken;
    } catch (error) {
        console.error('try catch [service.Getlogin] error.message :', error.message);
    }
}
const Logout = async (type) => {
    try {
        //await localStorage.clear();
        await localStorage.removeItem('ncc_Token');
        await localStorage.removeItem('NCC_theme');
        if (type == "patient") {
            window.location.href = "/patientlogin";
        } else {
            window.location.href = "/login";
        }
        //return false;
    } catch (error) {
        console.error('try catch [service.Logout] error.message :', error.message);
    }
}
const getTheme = async () => {
    try {
        const lang = await localStorage.getItem('NCC_theme');
        CONFIG.SELECTEDTHEME = (lang == null) ? CONFIG.DEFAULTTHEME : lang;
        return lang;
    } catch (error) {
        console.error('try catch [service.AuthHeader] error.message :', error.message);
    }
}
const setTheme = (lang) => {
    try {
        localStorage.setItem('NCC_theme', lang);
        CONFIG.SELECTEDTHEME = lang;
    } catch (error) {
        console.error('try catch [service.AuthHeader] error.message :', error.message);
    }
}
const HtmltoText = (htmlsting) => {
    try {
        if (htmlsting) {
            return <span dangerouslySetInnerHTML={{ __html: `${htmlsting.replace(/<[^>]+>/g, '')}` }} />;
        }
    } catch (error) {
        console.error('try catch [service.HtmltoText] error.message :', error.message);
    }
}
const TextTohtml = (htmlsting) => {
    try {
        if (htmlsting) {
            return <div dangerouslySetInnerHTML={{ __html: `${htmlsting}` }} />;
        }
    } catch (error) {
        console.error('try catch [service.TextTohtml] error.message :', error.message);
    }
}
const HtmltagRemove = (htmlsting) => {
    try {
        if (htmlsting) {
            const regexhtmltag = /(<([^>]+)>)/ig;
            let tagRemove = htmlsting.replace(regexhtmltag, '');
            let tagremove2 = tagRemove.replace(/\\&nbsp;/g, '');
            return (tagremove2);
        }
    } catch (error) {
        console.error('try catch [service.TextTohtml] error.message :', error.message);
    }
}
const getOrderStatusText = (status="-") => {
    try {
        return status?.toLowerCase() === "confirmed" ? "Received" : status;
    } catch (error) {
        console.error('try catch [service.getOrderStatusText] error.message :', error.message);
        return status;
    }
}
const generateArrayOfYears = () => {
    try {
        let max = new Date().getFullYear()
        let min = max - 9
        let years = []
        for (let i = max; i >= min; i--) {
            years.push(i)
        }
        return years
    } catch (error) {
        console.error('try catch [service.generateArrayOfYears] error.message :', error.message);
    }
}
const base64ToBlobToFile = ({ base64String, filename, contentType }, callback) => {
    try {
        if (base64String && filename && contentType) {
            const byteCharacters = atob(base64String);
            const byteArrays = [];
            for (let i = 0; i < byteCharacters.length; i++) {
                byteArrays.push(byteCharacters.charCodeAt(i));
            }
            const byteArray = new Uint8Array(byteArrays);
            const newBlob = new Blob([byteArray], { type: contentType });
            const newFile = new File([newBlob], filename, { type: contentType });
            callback({ result: newFile })
        } else {
            callback({ error: "filecontents and filename and filetype required" })
        }
    } catch (error) {
        console.error('try catch [service.base64ToBlobToFile] error.message :', error.message);
        callback({ error: error.message })
    }
}
const Service = { Getlogin, Logout, HtmltoText, TextTohtml, AuthHeader, HtmltagRemove, getTheme, setTheme,getOrderStatusText, generateArrayOfYears, base64ToBlobToFile };
export default Service;