// import { Suspense } from 'react';
// import { hydrate,render} from 'react-dom';
// import ReactDOM from 'react-dom/client';
// import { Skeleton } from 'antd';
import { hydrateRoot } from 'react-dom/client';
import { loadableReady } from '@loadable/component';
import store from "./store/index";
import { Provider } from "react-redux";
import reportWebVitals from './reportWebVitals';
//----
import './index.css';
import 'antd/dist/antd.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assest/scss/main.scss';
//----
import App from './App';
//----
loadableReady(() => {
  const rootContainer = document.getElementById('root');
  hydrateRoot(
    rootContainer,
    <Provider store={store}>
      <App />
    </Provider>
  );
});
//-------
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <Suspense fallback={<Skeleton active  paragraph={{rows:10}} style={{position:'fixed',left:0,top:0}} />}>
//     <Provider store={store}>
//       <App />
//     </Provider>
//   </Suspense>
// );
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
