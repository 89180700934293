import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../axios';
import CONFIG from '../../Utils/config';
import Service from '../../Utils/service';
//---
const extractErrorMessage = (error) =>
    error?.response?.data?.Message ||
    error?.response?.data ||
    error?.response ||
    error?.message;
const extractErrorMessageRejected = (payload) =>
    payload?.response?.data?.Message?.toString() ||
    payload?.response?.data?.toString() ||
    payload?.response?.toString() ||
    payload?.err?.toString() ||
    payload?.data?.Message?.toString() ||
    payload?.data?.message?.toString() ||
    payload?.data?.toString() ||
    payload?.result?.toString() ||
    payload?.Message?.toString() ||
    payload?.message?.toString() ||
    payload?.error?.toString() ||
    "API Response Invalid.";
//---
export const GetOrderbyStatus = createAsyncThunk(
    'AllReports/GetOrderbyStatus',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}AllReports/OrderbyStatus`, params: userdata, data: userdata, headers: authHeader });
            // console.log('GetOrderbyStatus result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const ExportOrderbyStatus = createAsyncThunk(
    'AllReports/ExportOrderbyStatus',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}AllReports/ExportOrderbyStatus`, params: userdata, data: userdata, headers: authHeader });
            // console.log('ExportOrderbyStatus result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetOrderbyType = createAsyncThunk(
    'AllReports/GetOrderbyType',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}AllReports/OrderbyType`, params: userdata, data: userdata, headers: authHeader });
            // console.log('GetOrderbyType result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const ExportOrderbyType = createAsyncThunk(
    'AllReports/ExportOrderbyType',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}AllReports/ExportOrderbyType`, params: userdata, data: userdata, headers: authHeader });
            // console.log('ExportOrderbyType result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetOrderbyOrderNumber = createAsyncThunk(
    'AllReports/GetOrderbyOrderNumber',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}AllReports/OrderbyOrderNumber`, params: userdata, data: userdata, headers: authHeader });
            // console.log('GetOrderbyOrderNumber result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const ExportOrderbyOrderNumber = createAsyncThunk(
    'AllReports/ExportOrderbyOrderNumber',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}AllReports/ExportOrderbyOrderNumber`, params: userdata, data: userdata, headers: authHeader });
            // console.log('ExportOrderbyOrderNumber result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetOrderbyMonth = createAsyncThunk(
    'AllReports/GetOrderbyMonth',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}AllReports/OrderbyMonth`, params: userdata, data: userdata, headers: authHeader });
            // console.log('GetOrderbyMonth result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const ExportOrderbyMonth = createAsyncThunk(
    'AllReports/ExportOrderbyMonth',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}AllReports/ExportOrderbyMonth`, params: userdata, data: userdata, headers: authHeader });
            // console.log('ExportOrderbyMonth result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetOrderbyMonthlySales = createAsyncThunk(
    'AllReports/GetOrderbyMonthlySales',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}AllReports/OrderbyMonthlySales`, params: userdata, data: userdata, headers: authHeader });
            // console.log('GetOrderbyMonthlySales result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const ExportOrderbyMonthlySales = createAsyncThunk(
    'AllReports/ExportOrderbyMonthlySales',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}AllReports/ExportOrderbyMonthlySales`, params: userdata, data: userdata, headers: authHeader });
            // console.log('ExportOrderbyMonthlySales result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetOrderbyPreviousWeekSales = createAsyncThunk(
    'AllReports/GetOrderbyPreviousWeekSales',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}AllReports/OrderbyPreviousWeekSales`, params: userdata, data: userdata, headers: authHeader });
            // console.log('GetOrderbyPreviousWeekSales result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetOutstandingorders = createAsyncThunk(
    'AllReports/OutstandingOrders',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}AllReports/OutstandingOrders`, params: userdata, data: userdata, headers: authHeader });
            // console.log('GetOutstandingorders result.data >>', result.data);
            if (result.data.success) {
                return result.data.result;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const ExportOrderbyPreviousWeekSales = createAsyncThunk(
    'AllReports/ExportOrderbyPreviousWeekSales',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}AllReports/ExportOrderbyPreviousWeekSales`, params: userdata, data: userdata, headers: authHeader });
            // console.log('ExportOrderbyPreviousWeekSales result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetTransactions = createAsyncThunk(
    'Transactions/GetTransactions',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Transactions`, data: userdata, headers: authHeader });
            // console.log('GetTransactions result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const DeleteTransaction = createAsyncThunk(
    'Transactions/DeleteTransaction',
    async (param, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'DELETE', url: `${CONFIG.API_ENDPOINT}Transactions/ID`, params: param, headers: authHeader });
            // console.log('DeleteTransaction result.data >>', result.data);
            if (result.data.success) {
                result.postdata = param;
                return result.postdata;
            } else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetQAQCReport = createAsyncThunk(
    'QAQCReport/GetQAQCReport',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}QAQCReport`, data: userdata, headers: authHeader });
            // console.log('GetQAQCReport result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const ExportMonthlyQAQC = createAsyncThunk(
    'QAQCReport/ExportMonthlyQAQC',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}QAQCReport/ExportMonthlyQAQC`, params: userdata, data: userdata, headers: authHeader });
            // console.log('ExportMonthlyQAQC result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetUserWiseOrderStatus = createAsyncThunk(
    'AllReports/GetUserWiseOrderStatus',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}AllReports/UserWiseOrderStatus`, params: userdata, data: userdata, headers: authHeader });
            // console.log('GetUserWiseOrderStatus result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const ExportUserWiseOrderStatus = createAsyncThunk(
    'AllReports/ExportUserWiseOrderStatus',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}AllReports/ExportUserWiseOrderStatus`, params: userdata, data: userdata, headers: authHeader });
            // console.log('ExportUserWiseOrderStatus result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const PrintDetail = createAsyncThunk(
    'Transactions/PrintDetail',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Transactions/PrintDetail`, params: userdata, data: userdata, headers: authHeader });
            // console.log('PrintDetail result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const EmailDetail = createAsyncThunk(
    'Transactions/EmailDetail',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Transactions/EmailDetail`, params: userdata, data: userdata, headers: authHeader });
            // console.log('EmailDetail result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const NileReport = createAsyncThunk(
    'NileReport/NileReport',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}NileReport/NileReport`, params: userdata, data: userdata, headers: authHeader });
            // console.log('NileReport result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const ReportSlice = createSlice({
    name: 'Report',
    initialState: {
        OrderbyStatusdata: [],
        exportOrderbyStatusdata: null,
        OrderbyTypedata: [],
        ExportOrderbyTypedata: null,
        OrderbyOrderNumberdata: [],
        ExportOrderbyOrderNumberdata: null,
        OrderbyMonthdata: [],
        ExportOrderbyMonthdata: null,
        OrderbyMonthlySalesdata: [],
        ExportOrderbyMonthlySalesdata: null,
        listOutstandingOrder: [],
        OrderbyPreviousWeekSalesdata: [],
        ExportOrderbyPreviousWeekSalesdata: null,
        Transactiondata: [],
        QAQCReportdata: [],
        ExportMonthlyQAQCdata: null,
        UserWiseOrderStatusdata: {},
        ExportUserWiseOrderStatusdata: null,
        PrintDetaildata: null,
        EmailDetaildata: null,
        NileReportdata: [],
        isTransactiondeleted: false,
        isEmailtransaction: false,
        isFetchingOBJ: {},
        isFetching: false,
        isError: false,
        errorMessage: '',
    },
    reducers: {
        updateState: (state, { payload }) => {
            state.exportOrderbyStatusdata = payload.exportOrderbyStatusdata !== undefined ? payload.exportOrderbyStatusdata : state.exportOrderbyStatusdata;
            state.ExportOrderbyTypedata = payload.ExportOrderbyTypedata !== undefined ? payload.ExportOrderbyTypedata : state.ExportOrderbyTypedata;
            state.ExportOrderbyOrderNumberdata = payload.ExportOrderbyOrderNumberdata !== undefined ? payload.ExportOrderbyOrderNumberdata : state.ExportOrderbyOrderNumberdata;
            state.ExportOrderbyMonthdata = payload.ExportOrderbyMonthdata !== undefined ? payload.ExportOrderbyMonthdata : state.ExportOrderbyMonthdata;
            state.ExportOrderbyMonthlySalesdata = payload.ExportOrderbyMonthlySalesdata !== undefined ? payload.ExportOrderbyMonthlySalesdata : state.ExportOrderbyMonthlySalesdata;
            state.ExportOrderbyPreviousWeekSalesdata = payload.ExportOrderbyPreviousWeekSalesdata !== undefined ? payload.ExportOrderbyPreviousWeekSalesdata : state.ExportOrderbyPreviousWeekSalesdata;
            state.ExportMonthlyQAQCdata = payload.ExportMonthlyQAQCdata !== undefined ? payload.ExportMonthlyQAQCdata : state.ExportMonthlyQAQCdata;
            state.ExportUserWiseOrderStatusdata = payload.ExportUserWiseOrderStatusdata !== undefined ? payload.ExportUserWiseOrderStatusdata : state.ExportUserWiseOrderStatusdata;
            state.isEmailtransaction = payload.isEmailtransaction !== undefined ? payload.isEmailtransaction : state.isEmailtransaction;
            state.isTransactiondeleted = payload.isTransactiondeleted !== undefined ? payload.isTransactiondeleted : state.isTransactiondeleted;
            state.isError = payload.isError !== undefined ? payload.isError : state.isError;
            state.errorMessage = payload.errorMessage !== undefined ? payload.errorMessage : state.errorMessage;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(GetOrderbyStatus.fulfilled, (state, { payload }) => {
            try {
                state.OrderbyStatusdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.GetOrderbyStatus = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetOrderbyStatus.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetOrderbyStatus.rejected, (state, { payload }) => {
            try {
                state.OrderbyStatusdata = [];
                state.isFetching = false;
                state.isFetchingOBJ.GetOrderbyStatus = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetOrderbyStatus.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetOrderbyStatus.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetOrderbyStatus = true;
        })
        //----
        builder.addCase(ExportOrderbyStatus.fulfilled, (state, { payload }) => {
            try {
                state.exportOrderbyStatusdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.ExportOrderbyStatus = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[ExportOrderbyStatus.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(ExportOrderbyStatus.rejected, (state, { payload }) => {
            try {
                state.exportOrderbyStatusdata = null;
                state.isFetching = false;
                state.isFetchingOBJ.ExportOrderbyStatus = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[ExportOrderbyStatus.rejected] try catch error >>', error);
            }
        })
        builder.addCase(ExportOrderbyStatus.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.ExportOrderbyStatus = true;
        })
        //----
        builder.addCase(GetOrderbyType.fulfilled, (state, { payload }) => {
            try {
                state.OrderbyTypedata = payload;
                state.isFetching = false;
                state.isFetchingOBJ.GetOrderbyType = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetOrderbyType.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetOrderbyType.rejected, (state, { payload }) => {
            try {
                state.OrderbyTypedata = [];
                state.isFetching = false;
                state.isFetchingOBJ.GetOrderbyType = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetOrderbyType.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetOrderbyType.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetOrderbyType = true;
        })
        //----
        builder.addCase(ExportOrderbyType.fulfilled, (state, { payload }) => {
            try {
                state.ExportOrderbyTypedata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.ExportOrderbyType = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[ExportOrderbyType.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(ExportOrderbyType.rejected, (state, { payload }) => {
            try {
                state.ExportOrderbyTypedata = null;
                state.isFetching = false;
                state.isFetchingOBJ.ExportOrderbyType = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[ExportOrderbyType.rejected] try catch error >>', error);
            }
        })
        builder.addCase(ExportOrderbyType.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.ExportOrderbyType = true;
        })
        //----
        builder.addCase(GetOrderbyOrderNumber.fulfilled, (state, { payload }) => {
            try {
                state.OrderbyOrderNumberdata = payload;
                state.isFetching = false;
                state.isFetchingOBJ.GetOrderbyOrderNumber = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetOrderbyOrderNumber.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetOrderbyOrderNumber.rejected, (state, { payload }) => {
            try {
                state.OrderbyOrderNumberdata = [];
                state.isFetching = false;
                state.isFetchingOBJ.GetOrderbyOrderNumber = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetOrderbyOrderNumber.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetOrderbyOrderNumber.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetOrderbyOrderNumber = true;
        })
        //----
        builder.addCase(ExportOrderbyOrderNumber.fulfilled, (state, { payload }) => {
            try {
                state.ExportOrderbyOrderNumberdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.ExportOrderbyOrderNumber = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[ExportOrderbyOrderNumber.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(ExportOrderbyOrderNumber.rejected, (state, { payload }) => {
            try {
                state.ExportOrderbyOrderNumberdata = null;
                state.isFetching = false;
                state.isFetchingOBJ.ExportOrderbyOrderNumber = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[ExportOrderbyOrderNumber.rejected] try catch error >>', error);
            }
        })
        builder.addCase(ExportOrderbyOrderNumber.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.ExportOrderbyOrderNumber = true;
        })
        //----
        builder.addCase(GetOrderbyMonth.fulfilled, (state, { payload }) => {
            try {
                state.OrderbyMonthdata = payload;
                state.isFetching = false;
                state.isFetchingOBJ.GetOrderbyMonth = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetOrderbyMonth.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetOrderbyMonth.rejected, (state, { payload }) => {
            try {
                state.OrderbyMonthdata = [];
                state.isFetching = false;
                state.isFetchingOBJ.GetOrderbyMonth = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetOrderbyMonth.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetOrderbyMonth.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetOrderbyMonth = true;
        })
        //----
        builder.addCase(ExportOrderbyMonth.fulfilled, (state, { payload }) => {
            try {
                state.ExportOrderbyMonthdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.ExportOrderbyMonth = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[ExportOrderbyMonth.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(ExportOrderbyMonth.rejected, (state, { payload }) => {
            try {
                state.ExportOrderbyMonthdata = null;
                state.isFetching = false;
                state.isFetchingOBJ.ExportOrderbyMonth = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[ExportOrderbyMonth.rejected] try catch error >>', error);
            }
        })
        builder.addCase(ExportOrderbyMonth.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.ExportOrderbyMonth = true;
        })
        //----
        builder.addCase(GetOrderbyMonthlySales.fulfilled, (state, { payload }) => {
            try {
                state.OrderbyMonthlySalesdata = payload;
                state.isFetching = false;
                state.isFetchingOBJ.GetOrderbyMonthlySales = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetOrderbyMonthlySales.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetOrderbyMonthlySales.rejected, (state, { payload }) => {
            try {
                state.OrderbyMonthlySalesdata = [];
                state.isFetching = false;
                state.isFetchingOBJ.GetOrderbyMonthlySales = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetOrderbyMonthlySales.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetOrderbyMonthlySales.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetOrderbyMonthlySales = true;
        })
        //----
        builder.addCase(ExportOrderbyMonthlySales.fulfilled, (state, { payload }) => {
            try {
                state.ExportOrderbyMonthlySalesdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.ExportOrderbyMonthlySales = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[ExportOrderbyMonthlySales.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(ExportOrderbyMonthlySales.rejected, (state, { payload }) => {
            try {
                state.ExportOrderbyMonthlySalesdata = null;
                state.isFetching = false;
                state.isFetchingOBJ.ExportOrderbyMonthlySales = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[ExportOrderbyMonthlySales.rejected] try catch error >>', error);
            }
        })
        builder.addCase(ExportOrderbyMonthlySales.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.ExportOrderbyMonthlySales = true;
        })
        //----
        builder.addCase(GetOrderbyPreviousWeekSales.fulfilled, (state, { payload }) => {
            try {
                state.OrderbyPreviousWeekSalesdata = payload;
                state.isFetching = false;
                state.isFetchingOBJ.GetOrderbyPreviousWeekSales = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetOrderbyPreviousWeekSales.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetOrderbyPreviousWeekSales.rejected, (state, { payload }) => {
            try {
                state.OrderbyPreviousWeekSalesdata = [];
                state.isFetching = false;
                state.isFetchingOBJ.GetOrderbyPreviousWeekSales = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetOrderbyPreviousWeekSales.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetOrderbyPreviousWeekSales.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetOrderbyPreviousWeekSales = true;
        })
        //----
        builder.addCase(GetOutstandingorders.fulfilled, (state, { payload }) => {
            try {
                state.listOutstandingOrder = payload;
                state.isFetching = false;
                state.isFetchingOBJ.GetOutstandingorders = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetOutstandingorders.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetOutstandingorders.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.GetOutstandingorders = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetOutstandingorders.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetOutstandingorders.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetOutstandingorders = true;
        })
        //----
        builder.addCase(ExportOrderbyPreviousWeekSales.fulfilled, (state, { payload }) => {
            try {
                state.ExportOrderbyPreviousWeekSalesdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.ExportOrderbyPreviousWeekSales = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[ExportOrderbyPreviousWeekSales.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(ExportOrderbyPreviousWeekSales.rejected, (state, { payload }) => {
            try {
                state.ExportOrderbyPreviousWeekSalesdata = null;
                state.isFetching = false;
                state.isFetchingOBJ.ExportOrderbyPreviousWeekSales = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[ExportOrderbyPreviousWeekSales.rejected] try catch error >>', error);
            }
        })
        builder.addCase(ExportOrderbyPreviousWeekSales.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.ExportOrderbyPreviousWeekSales = true;
        })
        //----
        builder.addCase(GetTransactions.fulfilled, (state, { payload }) => {
            try {
                state.Transactiondata = payload;
                state.isFetching = false;
                state.isFetchingOBJ.GetTransactions = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetTransactions.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetTransactions.rejected, (state, { payload }) => {
            try {
                state.Transactiondata = [];
                state.isFetching = false;
                state.isFetchingOBJ.GetTransactions = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetTransactions.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetTransactions.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetTransactions = true;
        })
        //----
        builder.addCase(DeleteTransaction.fulfilled, (state, { payload }) => {
            try {
                let exist = [...state.Transactiondata.data];
                let newExist = exist?.filter((e) => {
                    if (e.PayID !== payload.ID) {
                        return e;
                    }
                    return false;
                });
                exist = newExist;
                state.Transactiondata.data = exist;
                state.isFetching = false;
                state.isFetchingOBJ.DeleteTransaction = false;
                state.isError = false;
                state.isTransactiondeleted = true;
            } catch (error) {
                console.error('[DeleteTransaction.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(DeleteTransaction.rejected, (state, { payload }) => {
            try {
                state.isFetching = false;
                state.isFetchingOBJ.DeleteTransaction = false;
                state.isError = true;
                state.isTransactiondeleted = false;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[DeleteTransaction.rejected] try catch error >>', error);
            }
        })
        builder.addCase(DeleteTransaction.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.DeleteTransaction = true;
        })
        //----
        builder.addCase(GetQAQCReport.fulfilled, (state, { payload }) => {
            try {
                state.QAQCReportdata = payload;
                state.isFetching = false;
                state.isFetchingOBJ.GetQAQCReport = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetQAQCReport.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetQAQCReport.rejected, (state, { payload }) => {
            try {
                state.QAQCReportdata = [];
                state.isFetching = false;
                state.isFetchingOBJ.GetQAQCReport = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetQAQCReport.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetQAQCReport.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetQAQCReport = true;
        })
        //----
        builder.addCase(ExportMonthlyQAQC.fulfilled, (state, { payload }) => {
            try {
                state.ExportMonthlyQAQCdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.ExportMonthlyQAQC = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[ExportMonthlyQAQC.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(ExportMonthlyQAQC.rejected, (state, { payload }) => {
            try {
                state.ExportMonthlyQAQCdata = null;
                state.isFetching = false;
                state.isFetchingOBJ.ExportMonthlyQAQC = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[ExportMonthlyQAQC.rejected] try catch error >>', error);
            }
        })
        builder.addCase(ExportMonthlyQAQC.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.ExportMonthlyQAQC = true;
        })
        //----
        builder.addCase(GetUserWiseOrderStatus.fulfilled, (state, { payload }) => {
            try {
                state.UserWiseOrderStatusdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.GetUserWiseOrderStatus = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetUserWiseOrderStatus.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetUserWiseOrderStatus.rejected, (state, { payload }) => {
            try {
                state.UserWiseOrderStatusdata = {};
                state.isFetching = false;
                state.isFetchingOBJ.GetUserWiseOrderStatus = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetUserWiseOrderStatus.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetUserWiseOrderStatus.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetUserWiseOrderStatus = true;
        })
        //----
        builder.addCase(ExportUserWiseOrderStatus.fulfilled, (state, { payload }) => {
            try {
                state.ExportUserWiseOrderStatusdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.ExportUserWiseOrderStatus = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[ExportUserWiseOrderStatus.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(ExportUserWiseOrderStatus.rejected, (state, { payload }) => {
            try {
                state.ExportUserWiseOrderStatusdata = null;
                state.isFetching = false;
                state.isFetchingOBJ.ExportUserWiseOrderStatus = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[ExportUserWiseOrderStatus.rejected] try catch error >>', error);
            }
        })
        builder.addCase(ExportUserWiseOrderStatus.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.ExportUserWiseOrderStatus = true;
        })
        //----
        builder.addCase(PrintDetail.fulfilled, (state, { payload }) => {
            try {
                state.PrintDetaildata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.PrintDetail = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[PrintDetail.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(PrintDetail.rejected, (state, { payload }) => {
            try {
                state.PrintDetaildata = null;
                state.isFetching = false;
                state.isFetchingOBJ.PrintDetail = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[PrintDetail.rejected] try catch error >>', error);
            }
        })
        builder.addCase(PrintDetail.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.PrintDetail = true;
        })
        //----
        builder.addCase(EmailDetail.fulfilled, (state, { payload }) => {
            try {
                state.EmailDetaildata = payload.result;
                state.isEmailtransaction = true;
                state.isFetching = false;
                state.isFetchingOBJ.EmailDetail = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[EmailDetail.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(EmailDetail.rejected, (state, { payload }) => {
            try {
                state.EmailDetaildata = null;
                state.isEmailtransaction = false;
                state.isFetching = false;
                state.isFetchingOBJ.EmailDetail = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[EmailDetail.rejected] try catch error >>', error);
            }
        })
        builder.addCase(EmailDetail.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.EmailDetail = true;
        })
        //----
        builder.addCase(NileReport.fulfilled, (state, { payload }) => {
            try {
                state.NileReportdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.NileReport = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[NileReport.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(NileReport.rejected, (state, { payload }) => {
            try {
                state.NileReportdata = [];
                state.isFetching = false;
                state.isFetchingOBJ.NileReport = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[NileReport.rejected] try catch error >>', error);
            }
        })
        builder.addCase(NileReport.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.NileReport = true;
        })
    }
});
export const { updateState } = ReportSlice.actions;
export const ReportSelector = (state) => state.ncc.Report;