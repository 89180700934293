import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../axios';
import CONFIG from '../../Utils/config';
import Service from '../../Utils/service';
//---
const extractErrorMessage = (error) =>
    error?.response?.data?.Message ||
    error?.response?.data ||
    error?.response ||
    error?.message;
const extractErrorMessageRejected = (payload) =>
    payload?.response?.data?.Message?.toString() ||
    payload?.response?.data?.toString() ||
    payload?.response?.toString() ||
    payload?.err?.toString() ||
    payload?.data?.Message?.toString() ||
    payload?.data?.message?.toString() ||
    payload?.data?.toString() ||
    payload?.result?.toString() ||
    payload?.Message?.toString() ||
    payload?.message?.toString() ||
    payload?.error?.toString() ||
    "API Response Invalid.";
//---
export const SearchMedicationqithDBCode = createAsyncThunk(
    'Quote/SearchMedicationqithDBCode',
    async (userdata, thunkAPI) => {
        try {
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Quote/SearchMedicationqithDBCode`, params: userdata, data: userdata });
            // console.log('SearchMedicationqithDBCode result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const SelectQuoteByIdwithDBCode = createAsyncThunk(
    'Quote/SelectQuoteByIdwithDBCode',
    async (userdata, thunkAPI) => {
        try {
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Quote/SelectQuoteByIdwithDBCode`, params: userdata, data: userdata });
            // console.log('SelectQuoteByIdwithDBCode result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const CreateCompoundingOrder = createAsyncThunk(
    'InternalOrder/CreateCompoundingOrder',
    async (userdata, thunkAPI) => {
        try {
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}InternalOrder/CreateCompoundingOrder`, data: userdata });
            // console.log('CreateCompoundingOrder result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const createQuote = createAsyncThunk(
    'Quote/createQuote',
    async (userdata, thunkAPI) => {
        try {
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}Quote/create`, data: userdata });
            // console.log('createQuote result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetMedicineWithDBCode = createAsyncThunk(
    'MedicineFile/GetMedicineWithDBCode',
    async (userdata, thunkAPI) => {
        try {
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}MedicineFile/GetMedicineWithDBCode`, data: userdata });
            // console.log('GetMedicineWithDBCode result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const GetProductWithDBCode = createAsyncThunk(
    'PriceList/GetProductWithDBCode',
    async (userdata, thunkAPI) => {
        try {
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}PriceList/GetProductWithDBCode`, data: userdata });
            // console.log('GetProductWithDBCode result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const createPractitionerReg = createAsyncThunk(
    'ExternalUsers/createPractitionerReg',
    async (userdata, thunkAPI) => {
        try {
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}ExternalUsers/createPractitionerReg`, data: userdata.data, params: userdata.param });
            // console.log('createPractitionerReg result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const createNewpartnersignup = createAsyncThunk(
    'ExternalUsers/createNewpartnersignup',
    async (userdata, thunkAPI) => {
        try {
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}ExternalUsers/createNewpartnersignup`, data: userdata.data, params: userdata.param });
            // console.log('createNewpartnersignup result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const DowonloadFile = createAsyncThunk(
    'Patient/DowonloadFile',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'GET', url: `${CONFIG.API_ENDPOINT}Patient/DowonloadFile`, params: userdata, headers: authHeader });
            // console.log('DowonloadFile result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const OptOut = createAsyncThunk(
    'login/OptOut',
    async (userdata, thunkAPI) => {
        try {
            let result = await axios({ method: 'POST', url: `${CONFIG.API_ENDPOINT}login/OptOut`, params: userdata, data: userdata });
            // console.log('OptOut result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const RxOneScriptData = createAsyncThunk(
    'Patient/RxOneScriptData',
    async (userdata, thunkAPI) => {
        try {
            let authHeader = await Service.AuthHeader();
            let result = await axios({ method: 'GET', url: `${CONFIG.API_ENDPOINT}Patient/RxOneScriptData`, params: userdata, headers: authHeader });
            // console.log('RxOneScriptData result.data >>', result.data);
            if (result.data.success) {
                return result.data;
            }
            else {
                return thunkAPI.rejectWithValue(result.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: extractErrorMessage(error) });
        }
    }
);
export const CommonpageSlice = createSlice({
    name: 'Commonpage',
    initialState: {
        SearchMedicationdata: [],
        SelectQuoteByIddata: null,
        CreateCompoundingOrderdata: {},
        createQuotedata: {},
        GetMedicinedata: {},
        GetProductdata: {},
        createPractitionerRegdata: {},
        createNewpartnersignupdata: {},
        DowonloadFiledata: {},
        PrintscriptData: {},
        isCustomquotecreated: false,
        isCustomordercreated: false,
        iscreatePractitionerReg: false,
        iscreateNewpartnersignup: false,
        isOptOut: false,
        isOptOutmessage: null,
        isFetchingOBJ: {},
        isFetching: false,
        isError: false,
        errorMessage: '',
    },
    reducers: {
        updateState: (state, { payload }) => {
            if (payload.DowonloadFiledata !== undefined) { state.DowonloadFiledata = payload.DowonloadFiledata }
            if (payload.isCustomquotecreated !== undefined) { state.isCustomquotecreated = payload.isCustomquotecreated }
            if (payload.isCustomordercreated !== undefined) { state.isCustomordercreated = payload.isCustomordercreated }
            if (payload.isOptOut !== undefined) { state.isOptOut = payload.isOptOut }
            if (payload.isOptOutmessage !== undefined) { state.isOptOutmessage = payload.isOptOutmessage }
            if (payload.iscreateNewpartnersignup !== undefined) { state.iscreateNewpartnersignup = payload.iscreateNewpartnersignup }
            if (payload.iscreatePractitionerReg !== undefined) { state.iscreatePractitionerReg = payload.iscreatePractitionerReg }
            if (payload.isError !== undefined) { state.isError = payload.isError }
            if (payload.errorMessage !== undefined) { state.errorMessage = payload.errorMessage }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(SearchMedicationqithDBCode.fulfilled, (state, { payload }) => {
            try {
                state.SearchMedicationdata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.SearchMedicationqithDBCode = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[SearchMedicationqithDBCode.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(SearchMedicationqithDBCode.rejected, (state, { payload }) => {
            try {
                state.SearchMedicationdata = [];
                state.isFetching = false;
                state.isFetchingOBJ.SearchMedicationqithDBCode = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[SearchMedicationqithDBCode.rejected] try catch error >>', error);
            }
        })
        builder.addCase(SearchMedicationqithDBCode.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.SearchMedicationqithDBCode = true;
        })
        //----
        builder.addCase(SelectQuoteByIdwithDBCode.fulfilled, (state, { payload }) => {
            try {
                state.SelectQuoteByIddata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.SelectQuoteByIdwithDBCode = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[SelectQuoteByIdwithDBCode.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(SelectQuoteByIdwithDBCode.rejected, (state, { payload }) => {
            try {
                state.SelectQuoteByIddata = [];
                state.isFetching = false;
                state.isFetchingOBJ.SelectQuoteByIdwithDBCode = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[SelectQuoteByIdwithDBCode.rejected] try catch error >>', error);
            }
        })
        builder.addCase(SelectQuoteByIdwithDBCode.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.SelectQuoteByIdwithDBCode = true;
        })
        //----
        builder.addCase(CreateCompoundingOrder.fulfilled, (state, { payload }) => {
            try {
                state.CreateCompoundingOrderdata = payload.result;
                state.isCustomordercreated = true;
                state.isFetching = false;
                state.isFetchingOBJ.CreateCompoundingOrder = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[CreateCompoundingOrder.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(CreateCompoundingOrder.rejected, (state, { payload }) => {
            try {
                state.CreateCompoundingOrderdata = {};
                state.isCustomordercreated = false;
                state.isFetching = false;
                state.isFetchingOBJ.CreateCompoundingOrder = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[CreateCompoundingOrder.rejected] try catch error >>', error);
            }
        })
        builder.addCase(CreateCompoundingOrder.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.CreateCompoundingOrder = true;
        })
        //----
        builder.addCase(createQuote.fulfilled, (state, { payload }) => {
            try {
                state.createQuotedata = payload.result;
                state.isCustomquotecreated = true;
                state.isFetching = false;
                state.isFetchingOBJ.createQuote = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[createQuote.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(createQuote.rejected, (state, { payload }) => {
            try {
                state.createQuotedata = {};
                state.isCustomquotecreated = false;
                state.isFetching = false;
                state.isFetchingOBJ.createQuote = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[createQuote.rejected] try catch error >>', error);
            }
        })
        builder.addCase(createQuote.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.createQuote = true;
        })
        //----
        builder.addCase(GetMedicineWithDBCode.fulfilled, (state, { payload }) => {
            try {
                state.GetMedicinedata = payload;
                state.isFetching = false;
                state.isFetchingOBJ.GetMedicineWithDBCode = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetMedicineWithDBCode.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetMedicineWithDBCode.rejected, (state, { payload }) => {
            try {
                state.GetMedicinedata = {};
                state.isFetching = false;
                state.isFetchingOBJ.GetMedicineWithDBCode = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetMedicineWithDBCode.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetMedicineWithDBCode.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetMedicineWithDBCode = true;
        })
        //----
        builder.addCase(GetProductWithDBCode.fulfilled, (state, { payload }) => {
            try {
                state.GetProductdata = payload;
                state.isFetching = false;
                state.isFetchingOBJ.GetProductWithDBCode = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[GetProductWithDBCode.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(GetProductWithDBCode.rejected, (state, { payload }) => {
            try {
                state.GetProductdata = {};
                state.isFetching = false;
                state.isFetchingOBJ.GetProductWithDBCode = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[GetProductWithDBCode.rejected] try catch error >>', error);
            }
        })
        builder.addCase(GetProductWithDBCode.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.GetProductWithDBCode = true;
        })
        //----
        builder.addCase(createPractitionerReg.fulfilled, (state, { payload }) => {
            try {
                state.createPractitionerRegdata = payload;
                state.iscreatePractitionerReg = true;
                state.isFetching = false;
                state.isFetchingOBJ.createPractitionerReg = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[createPractitionerReg.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(createPractitionerReg.rejected, (state, { payload }) => {
            try {
                state.createPractitionerRegdata = {};
                state.iscreatePractitionerReg = false;
                state.isFetching = false;
                state.isFetchingOBJ.createPractitionerReg = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[createPractitionerReg.rejected] try catch error >>', error);
            }
        })
        builder.addCase(createPractitionerReg.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.createPractitionerReg = true;
        })
        //----
        builder.addCase(createNewpartnersignup.fulfilled, (state, { payload }) => {
            try {
                state.createNewpartnersignupdata = payload;
                state.iscreateNewpartnersignup = true;
                state.isFetching = false;
                state.isFetchingOBJ.createNewpartnersignup = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[createNewpartnersignup.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(createNewpartnersignup.rejected, (state, { payload }) => {
            try {
                state.createNewpartnersignupdata = {};
                state.iscreateNewpartnersignup = false;
                state.isFetching = false;
                state.isFetchingOBJ.createNewpartnersignup = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[createNewpartnersignup.rejected] try catch error >>', error);
            }
        })
        builder.addCase(createNewpartnersignup.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.createNewpartnersignup = true;
        })
        //----
        builder.addCase(DowonloadFile.fulfilled, (state, { payload }) => {
            try {
                state.DowonloadFiledata = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.DowonloadFile = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[DowonloadFile.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(DowonloadFile.rejected, (state, { payload }) => {
            try {
                state.DowonloadFiledata = {};
                state.isFetching = false;
                state.isFetchingOBJ.DowonloadFile = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[DowonloadFile.rejected] try catch error >>', error);
            }
        })
        builder.addCase(DowonloadFile.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.DowonloadFile = true;
        })
        //----
        builder.addCase(OptOut.fulfilled, (state, { payload }) => {
            try {
                state.isOptOutmessage = payload.result;
                state.isOptOut = true;
                state.isFetching = false;
                state.isFetchingOBJ.OptOut = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[OptOut.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(OptOut.rejected, (state, { payload }) => {
            try {
                state.isOptOutmessage = null;
                state.isOptOut = false;
                state.isFetching = false;
                state.isFetchingOBJ.OptOut = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[OptOut.rejected] try catch error >>', error);
            }
        })
        builder.addCase(OptOut.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.OptOut = true;
        })
        //----
        builder.addCase(RxOneScriptData.fulfilled, (state, { payload }) => {
            try {
                state.PrintscriptData = payload.result;
                state.isFetching = false;
                state.isFetchingOBJ.RxOneScriptData = false;
                state.isError = false;
                state.errorMessage = '';
            } catch (error) {
                console.error('[RxOneScriptData.fulfilled] try catch error >>', error);
            }
        })
        builder.addCase(RxOneScriptData.rejected, (state, { payload }) => {
            try {
                state.PrintscriptData = null;
                state.isFetching = false;
                state.isFetchingOBJ.RxOneScriptData = false;
                state.isError = true;
                state.errorMessage = extractErrorMessageRejected(payload);
            } catch (error) {
                console.error('[RxOneScriptData.rejected] try catch error >>', error);
            }
        })
        builder.addCase(RxOneScriptData.pending, (state) => {
            state.isFetching = true;
            state.isFetchingOBJ.RxOneScriptData = true;
        })
    }
});
export const { updateState } = CommonpageSlice.actions;
export const CommonpageSelector = (state) => state.ncc.Commonpage;